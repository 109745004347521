<mat-card *ngIf="cView === 'allTemplates'">
  <mat-card-header>
    <span class="small w-50">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Choose {{currentIntegration.title}} Credential:</mat-label>
        <mat-select [multiple]="false" placeholder="Choose credential" [(ngModel)]="currentCredential"
                    name="currentCred" (ngModelChange)="getDefaultTemplates(); getActionParams()">
          <mat-option *ngFor="let item of integrationsData" [value]="item._id"> {{item.name}} </mat-option>
        </mat-select>
      </mat-form-field>
    </span>
  </mat-card-header>
  <mat-card-content>
    <app-s-table aria-label="Notification Rule" role="table" [sTableOptions]="notiTableOptions"
        (actionCallback)="notiactionCall($event)" (pageCallback)="notipageCall($event)" (sortCallback)="sortCall($event)"
        (refreshCallback)="getDefaultTemplates()" (addCallback)="addDefaultTemplate()"
        (timerCallback)="getDefaultTemplates()"></app-s-table>
  </mat-card-content>
</mat-card>
<mat-card *ngIf="cView === 'addEditTemplate'">
  <button class="position-absolute r-1p mt--10" aria-label="close modal icon" matTooltip="Close"
          (click)="cView = 'allTemplates'; getDefaultTemplates();" mat-icon-button="">
    <mat-icon>close</mat-icon>
  </button>
  <mat-card-header>
    <mat-card-title> Notification Rule</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <mat-horizontal-stepper #stepper linear="'false'">
      <mat-step>
        <ng-template matStepLabel>Rule Name</ng-template>
        <form #rule="ngForm">
          <mat-form-field floatPlaceholder="auto" class="mb-2 w-100" appearance="outline">
            <mat-label>Rule Name</mat-label>
            <input matInput [(ngModel)]="currentTemplate.name" autocomplete="off" name="ruleName" id="NotificationRuleName" type="text" required placeholder="Enter rule name">
          </mat-form-field>
          <mat-form-field appearance="outline" class="form-element w-100">
            <mat-select #companySelect [multiple]="false" class="" matInput name="company" id="company" required placeholder="Select company"
                        (openedChange)="closeCurrentCompany($event)" [(ngModel)]="currentTemplate.companyRef" 
                        [formControl]="companyCtrl" >
              <mat-option>
                <ngx-mat-select-search [formControl]="companyFilterCtrl"  [placeholderLabel]="'Search Company'"
                  [noEntriesFoundLabel]="'No matching company found'" [searching]="searching"></ngx-mat-select-search>
              </mat-option>
              <mat-option matTooltip="All Companies" value="*">All Companies</mat-option>
              <mat-option [matTooltip]="company.name" *ngFor="let company of filteredCompanies | async" [value]="company._id">
                {{company.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div mat-dialog-actions class="mt- float-right ">
            <button class="mat-raised-button" mat-button color="primary" [disabled]="!rule.form.valid" (click)="stepperNext(1)">Next</button>
          </div>
        </form>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>Select Event</ng-template>
        <form #addevent="ngForm">
          <ng-container *ngFor="let item of notificationRule">
            <mat-accordion>
              <mat-expansion-panel class="mb-3" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                  <mat-panel-title class="tprimary">
                    <span class="mt5 ml-2">{{item.name|camelToHuman:true}}</span></mat-panel-title>
                </mat-expansion-panel-header>
                <ng-container *ngFor="let it of item.alerts; let i = index">
                  <ng-container *ngFor="let event of it.events">
                    <mat-list class="w-100" *ngIf="!event.isclose || event.isclose.toLowerCase() !== 'yes'">
                      <mat-list-item class="bb-1">
                        <span class="mr-2">
                          <mat-checkbox required [(ngModel)]="event.selected" color="primary" [id]="event.name"
                            [name]="event.id"></mat-checkbox>
                        </span>
                        <span class="mb-2 ml-2" [matTooltip]="it.name">{{event.name}}</span>
                      </mat-list-item>
                    </mat-list>
                  </ng-container>
                  <ng-container *ngFor="let event of it.eventGroups">
                    <mat-radio-group [(ngModel)]="event.value" *ngIf="event.name" [name]="event.name">
                      <ng-container *ngFor="let eve of event.events">
                        <mat-list class="w-100" *ngIf="!eve.isclose || eve.isclose.toLowerCase() !== 'yes'">
                          <mat-list-item class="bb-1">
                            <span class="mr-2">
                                  <mat-radio-button color="primary" [value]="eve.id" [id]="eve.name">
                                  </mat-radio-button>
                            </span>
                            <span class="mb-2 ml-2" [matTooltip]="it.name">{{eve.name}}</span>
                          </mat-list-item>
                        </mat-list>
                      </ng-container>
                    </mat-radio-group>
                  </ng-container>
                </ng-container>
              </mat-expansion-panel>
            </mat-accordion>
          </ng-container>
          <div mat-dialog-actions class="mt-2 float-right">
            <button class="mat-raised-button mr-3" mat-button matStepperPrevious>Back</button>
            <button mat-raised-button color="primary" (click)="stepperNext(2)">Next</button>
          </div>
        </form>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>Integration Parameters</ng-template>
        <form #addinte="ngForm">
          <mat-form-field floatPlaceholder="auto" class="mt-4 col-lg-6 pl-0" appearance="outline">
            <mat-label>Choose Credential</mat-label>
            <mat-select name="credD" [(ngModel)]="currentIntegration.credentialId" required
                        placeholder="Choose Credential" (ngModelChange)="processFields(currentIntegration.actionResult)">
              <mat-option [value]="cred._id" *ngFor="let cred of integrationsData">{{cred.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <app-dynamic-form class="col-lg-8" [isSaveBtn]="false" [isCloseBtn]="false"
                            *ngIf="currentIntegration.actionData
                             && currentIntegration.formElements && currentIntegration.formElements.length"
                            (valueUpdate)="actionChange($event)" [listOfFormElements]="currentIntegration.formElements"
                            [Valuesoutput]="currentIntegration.actionData">
          </app-dynamic-form>
          <div mat-dialog-actions class="mt- float-right ">
            <button class="mat-raised-button mr-3" mat-button matStepperPrevious>Back</button>
            <button class="mat-raised-button" mat-button color="primary" [disabled]="isLoading || !addinte.form.valid" (click)="stepperNext(3)">Save</button>
          </div>
        </form>
      </mat-step>
    </mat-horizontal-stepper>
  </mat-card-content>
</mat-card>
<mat-card *ngIf="cView === 'addEditTemplate' && false">
  <button type="button" class="float-right" mat-icon-button aria-label="close modal icon" matTooltip="Close"
          (click)="closeTemplate();">
    <mat-icon>close</mat-icon>
  </button>
  <mat-card-header>
    <mat-card-title>Set Default Template</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <mat-form-field floatPlaceholder="auto" class="mt-4 col-lg-6 pl-0" appearance="outline">
      <mat-label>Choose Credential</mat-label>
      <mat-select name="credD" [(ngModel)]="currentIntegration.credentialId"
                  placeholder="Choose Credential" (ngModelChange)="processFields(currentIntegration.actionResult)">
        <mat-option [value]="cred._id" *ngFor="let cred of integrationsData">{{cred.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <app-dynamic-form class="col-lg-8" [isSaveBtn]="false" [isCloseBtn]="false"
                      *ngIf="currentIntegration.actionData
                       && currentIntegration.formElements && currentIntegration.formElements.length"
                      (valueUpdate)="actionChange($event)" [listOfFormElements]="currentIntegration.formElements"
                      [Valuesoutput]="currentIntegration.actionData">
    </app-dynamic-form>
  </mat-card-content>
  <mat-card-actions align="start">
    <button mat-raised-button class="mr-2" (click)="closeTemplate()">Cancel</button>
    <button mat-raised-button color="primary" (click)="saveDefaultTemplate()">
      <span *ngIf="!this.currentTemplate?._id">Save</span>  
      <span *ngIf="this.currentTemplate?._id">Update</span>  
    </button>
  </mat-card-actions>
</mat-card>
