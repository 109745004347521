import {Component, OnInit, EventEmitter, Input, Output, AfterViewInit ,ViewChild ,ElementRef} from '@angular/core';
import {BaseRequestService} from '../../../_services/base.service';
import {ConfirmDialogService} from 'src/app/_services/confirmdialog.service';
import {CommonService} from 'src/app/_services/common.services';
import {HttpClient} from '@angular/common/http';
import {ModalService} from 'src/app/_services/modal.service';
import {LoaderService} from 'src/app/_services/loader.service';
import {MyToastrService} from 'src/app/_services/toastr.service';
import {DiscoverySettings} from '../../../api/models/discovery-settings';
import {DiscoverySettingsService} from '../../../api/services/discovery-settings.service';
import {AgentService} from '../../../api/services/agent.service';
import {AuthenticationService} from '../../../_services/authentication.service';
import {MatChipInputEvent} from '@angular/material/chips';
import {COMMA, ENTER} from '@angular/cdk/keycodes';

@Component({
  selector: 'app-exclude-ip',
  templateUrl: './exclude-ip.component.html',
  styleUrls: ['./exclude-ip.component.scss']
})
export class ExcludeIpComponent implements OnInit, AfterViewInit{
  @ViewChild("newIp",{static : false}) newIp:any=ElementRef;
  @Input() currentCompany: any;
  @Input() currentAgent: any;
  @Input() initAdd: any;
  @Input() isExternal: any;
  @Output() iprangeList = new EventEmitter();
  @Output() saveCallBack = new EventEmitter();

  ipPattern =  '(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)';
  addIprangeWidth = 600;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  currentAsset: any;
  iprange: any = {};
  isLoading = false;
  addOnBlur = true;
  removable = true;
  selectable = true;
  ipraTableOptions: any;
  rangcurrentPage = 0;
  rangfilterQuery: any;
  colFilterQuery: any;
  colFilterCols: any = [];
  discoveryList: any = [
    {value: 'cidr', text: 'CIDR'},
    {value: 'fromto', text: 'IP Range'},
    {value: 'static', text: 'Static IP'},
    {value: 'staticDomain', text: 'Domain Name'}
  ];
  extDiscoveryList: any = [
    {value: 'fromto', text: 'IP Range'},
    {value: 'static', text: 'Static IP'},
    {value: 'staticDomain', text: 'Domain Name'}
  ];
  netmaskList: any = [];
  isEndIp = false;
  staticDomain = false;
  isNetMask = true;
  csvUpload: any = {};
  agentHash: any = {};
  agentList: any = [];
  assetTags: any = [];
  Objectkeys = Object.keys;
  constructor(public baseService: BaseRequestService, public modalService: ModalService,
              public toast: MyToastrService, private loaderService: LoaderService,
              private httpClient: HttpClient,
              public confirmDialog: ConfirmDialogService, public commonService: CommonService,
              public agentService: AgentService, private aS: AuthenticationService,
              public discoverySettingsService: DiscoverySettingsService) {
    for (let index = 1; index <= 128; index++) {
      this.netmaskList.push({
        value: '/' + index,
        text: '/' + index
      });
    }
    this.ipraTableOptions = {
      columns: [
        {
        header: 'Name',
        columnDef: 'name',
        filter: '',
        cell: '(element: any) => `${element.name}`',
        order: 0,
        visible: true,
        isToolTip: false,
        isToolTipCol: '',
        hasMultiData: false,
        class: '',
        color: '',
        isProgressCntrl: false,
        isColoredCntrl: false,
        colList: [],
        isfaicon: false,
        isAddingText: false,
        addingText: '',
        img: false,
        imgPath: '',
        isSort: true,
        iscolumnSearch: false
      }, {
        header: 'Start',
        columnDef: 'ip_start',
        filter: '',
        cell: '(element: any) => `${element.ip_start}`',
        order: 1,
        visible: true,
        isToolTip: false,
        isToolTipCol: '',
        hasMultiData: false,
        class: '',
        color: '',
        isProgressCntrl: false,
        isColoredCntrl: false,
        colList: [],
        isfaicon: false,
        isAddingText: false,
        addingText: '',
        img: false,
        imgPath: '',
        isSort: false,
        iscolumnSearch: false
      }, {
        header: 'End',
        columnDef: 'ip_end',
        filter: '',
        cell: '(element: any) => `${element.ip_end}`',
        order: 2,
        visible: true,
        isToolTip: false,
        isToolTipCol: '',
        hasMultiData: false,
        class: '',
        color: '',
        isProgressCntrl: false,
        isColoredCntrl: false,
        colList: [],
        isfaicon: false,
        isAddingText: false,
        addingText: '',
        img: false,
        imgPath: '',
        isSort: false,
        iscolumnSearch: false
      }, {
        header: 'Subnet Mask',
        columnDef: 'subnet_mask',
        filter: '',
        cell: '(element: any) => `${element.subnet_mask}`',
        order: 3,
        visible: true,
        isToolTip: false,
        isToolTipCol: '',
        hasMultiData: false,
        class: '',
        color: '',
        isProgressCntrl: false,
        isColoredCntrl: false,
        colList: [],
        isfaicon: false,
        isAddingText: false,
        addingText: '',
        img: false,
        imgPath: '',
        isSort: false,
        iscolumnSearch: false
      }, {
        header: 'Discovery Type',
        columnDef: 'discovery_type',
        filter: '',
        cell: '(element: any) => `${element.discovery_type}`',
        order: 4,
        visible: true,
        isToolTip: false,
        isToolTipCol: '',
        hasMultiData: false,
        class: '',
        color: '',
        isProgressCntrl: false,
        isColoredCntrl: false,
        colList: [],
        isfaicon: false,
        isAddingText: false,
        addingText: '',
        img: false,
        imgPath: '',
        isSort: false,
        iscolumnSearch: false
      }, {
        header: 'Is Excluded',
        columnDef: 'isExcluded',
        filter: '',
        cell: '(element: any) => `${element.isExcluded}`',
        order: 5,
        visible: true,
        isToolTip: false,
        isToolTipCol: '',
        hasMultiData: false,
        class: '',
        color: '',
        isProgressCntrl: false,
        isColoredCntrl: false,
        colList: [],
        isfaicon: false,
        isAddingText: false,
        addingText: '',
        img: false,
        imgPath: '',
        isSort: true,
        iscolumnSearch: false,
        statusicon: true,
        success: true, failure: false,
        successIconPath: 'assets/images/devices/active.svg', successToolTip: 'True',
        failureIconPath: 'assets/images/devices/noimg.png', failureToolTip: 'False',
      }, {
        header: 'Tags',
        columnDef: 'tags',
        filter: '',
        cell: '(element: any) => `${element.tags}`',
        order: 6,
        visible: true,
        isToolTip: false,
        isToolTipCol: '',
        hasMultiData: false,
        class: '',
        color: '',
        isProgressCntrl: false,
        isColoredCntrl: false,
        colList: [],
        isfaicon: false,
        isAddingText: false,
        addingText: '',
        img: false,
        imgPath: '',
        isSort: false,
        iscolumnSearch: false
      },{
        header: 'Created',
        columnDef: 'c',
        filter: 'utcToLocale',
        cell: '(element: any) => `${element.c}`',
        order: 7,
        visible: false,
        isToolTip: false,
        isToolTipCol: '',
        hasMultiData: false,
        class: '',
        color: '',
        isProgressCntrl: false,
        isColoredCntrl: false,
        colList: [],
        isfaicon: false,
        isAddingText: false,
        addingText: '',
        img: false,
        imgPath: '',
        isSort: true,
        iscolumnSearch: false
      }, {
        header: 'Updated',
        columnDef: 'u',
        filter: 'utcToLocale',
        cell: '(element: any) => `${element.u}`',
        order: 8,
        visible: false,
        isToolTip: false,
        isToolTipCol: '',
        hasMultiData: false,
        class: '',
        color: '',
        isProgressCntrl: false,
        isColoredCntrl: false,
        colList: [],
        isfaicon: false,
        isAddingText: false,
        addingText: '',
        img: false,
        imgPath: '',
        isSort: true,
        iscolumnSearch: false
      }],
      sortOptions: {active: 'name', direction: 'asc'},
      _pageData: [],
      faClass: 'DiscoverySettings',
      tableOptions: {
        title: 'Exclude IP',
        id: 'ipraTableOptions',
        isServerSide: true,
        selectText: 'range(s)',
        loading: true,
        floatingFilter: true,
        rowSelection: true,
        showAction: true,
        actionMenuItems: [{
          text: 'Edit',
          icon: 'edit',
          callback: 'editFn',
          isGlobal: false
        }, {text: 'Delete', icon: 'delete', callback: 'deleteFn', isGlobal: true}],
        pagination: true,
        pageOptions: [5, 10, 25, 100],
        pageSize: 10,
        search: true,
        showhideList: true,
        refreshData: true,
        exportExcel: true,
        add: (this.aS.hasPermission('discoverysettings', 'create')),
        columnSearch: false,
        compareData: false,
        filterDownload: false,
        service: 'discoverySettingsService',
        collection: 'DiscoverySettings'
      }
    };
  }

  changeAgent(agent: string): void {
    this.iprange.agentRef.name = this.agentHash.get(agent);
  }

  changeDiscoveryType(iprange: string): void {
    this.iprange.subnet_mask = '';
    this.staticDomain = false;
    if (iprange === 'cidr') {
      this.isEndIp = false;
      this.isNetMask = true;
      this.iprange.end = '';
      this.iprange.ip_end = '';
      this.iprange.subnet_mask = '/24';
    } else if (iprange === 'fromto') {
      this.isEndIp = true;
      this.isNetMask = false;
    } else if (iprange === 'static') {
      this.isEndIp = false;
      this.isNetMask = false;
      this.iprange.end = '';
      this.iprange.ip_end = '';
    } else if (iprange === 'staticDomain') {
      this.isEndIp = false;
      this.isNetMask = false;
      this.iprange.end = '';
      this.iprange.ip_end = '';
      this.staticDomain = true;
    }
  }

  parentCallBack(event: string): void {
    this.isLoading = false;
    if (event === 'uploadBulkIpCsv') {
      this.isLoading = false;
      this.modalService.open('uploadBulkIP');
      this.newIp.nativeElement.value = "";
    }
  }

  uploadFile(event: any, key: any): void {
    if (event.target.files.length > 0) {
      this.csvUpload[key] = event.target.files[0];
      this.csvUpload.companyid = this.currentCompany._id;
    }
  }

  uploadBulkIPCSV(): void {
    const formData = new FormData();
    this.csvUpload.agentid = (this.currentAgent) ? this.currentAgent._id : this.agentList[0]._id;
    this.Objectkeys(this.csvUpload).forEach(obj => {
      formData.append(obj, this.csvUpload[obj]);
    });
    if (this.isExternal) {
      formData.append('externalScan', 'true');
    }
    this.loaderService.Modeldisplay(true, 'Uploading...');
    this.httpClient.post<any>('/api/discoverysettings/discoverysettingscsvupload',
      formData).subscribe((result: any) => {
        if (result[0]) {
          if (result[1].length === 0) {
            this.toast.sToast('success', 'Successfully uploaded');
          } else {
            this.showUploadError(result[1]);
          }
          setTimeout(()=>{
            this.loaderService.Modeldisplay(false);
            this.getIPRanges();
          },5000);
          this.newIp.nativeElement.value ="";
          this.modalService.close('uploadBulkIP');
        } else {
          this.loaderService.Modeldisplay(false);
          this.toast.sToast('error', result[1]);
        }
    });
  }

  showUploadError(error: any): void {
    let errorStr = '';
    error.forEach((err: any) => {
      errorStr += `${err.Name} ${err.Subnet} - ${err.Reason}` + '\n';
    });
    const titleName = 'Upload Error';
    const message =  errorStr;
    const cancelText = 'Cancel';
    const acceptText = 'OK';
    this.confirmDialog.confirmDialog(titleName, message, cancelText, acceptText);
  }

  updateIprange(iprange: any): void {
    if (this.iprange.agentRef.name === 'ExternalScanAgent' && this.commonService.isPrivateIP(this.iprange.ip_start)) {
      this.toast.sToast('error', 'Reserved IP ranges not allowed in external scan');
      return;
    }
    if (this.iprange.discovery_type === 'cidr' && this.commonService.isZeroOctetIP(this.iprange.ip_start)) {
      this.toast.sToast('error', 'Please enter valid IP address, IP address should not start with 0');
      return;
    }
    this.isLoading = true;
    const tags = this.assetTags.map((x: any) => x.name);
    iprange.tags = (tags) ? tags : [];
    this.loaderService.Modeldisplay(true);
    this.discoverySettingsService.updateApiDiscoverysettingsPut({body: iprange}).subscribe((result: any) => {
      this.loaderService.Modeldisplay(false);
      if(result.name === null){
        this.isLoading = false;
        this.toast.sToast('error', 'Provided Discovery Settings is already available');
       } else if (result._id){
        this.toast.sToast('success', 'Updated successfully');
        setTimeout(() => { this.getIPRanges(); }, 2000);
        this.modalService.close('addExcludeIprange');
       }else {
         this.toast.sToast('error', JSON.stringify(result));
         this.isLoading = false;
       }
    });
  }

  saveIprange(): void {
    if (this.iprange.agentRef.name === 'ExternalScanAgent' && this.commonService.isPrivateIP(this.iprange.ip_start)) {
      this.toast.sToast('error', 'Reserved IP ranges not allowed in external scan');
      return;
    }
    if (this.iprange.discovery_type === 'cidr' && this.commonService.isZeroOctetIP(this.iprange.ip_start)) {
      this.toast.sToast('error', 'Please enter valid IP address, IP address should not start with 0');
      return;
    }
    this.isLoading = true;
    this.loaderService.Modeldisplay(true);
    const tags = this.assetTags.map((x: any) => x.name);
    this.iprange.isExcludedForAd = true;
    this.iprange.tags = (tags) ? tags : [];
    this.iprange.companyRef = {id: this.currentCompany._id, name: this.currentCompany.name};
    this.discoverySettingsService.createApiDiscoverysettingsPost({body: this.iprange})
      .subscribe((result: DiscoverySettings) => {
        this.loaderService.Modeldisplay(false);
        this.isLoading = true;
        if (result.name === null ) {
          this.isLoading = false;
          this.toast.sToast('error', 'Provided Discovery Settings is already available');
        } else if (result._id){
          this.modalService.close('addExcludeIprange');
          this.toast.sToast('success', 'IP added successfully!');
          this.saveCallBack.next({});
          setTimeout(() => {
            this.getIPRanges();
          }, 2000);
        }else {
          this.toast.sToast('error', JSON.stringify(result));
          this.isLoading = false;
        }

      });
  }

  deleteRange(iprange: any): void {
    const titleName = 'Confirmation';
    const message = 'Are you sure you want to delete this IP range ' + iprange.name + ' ?';
    const cancelText = 'No';
    const acceptText = 'Yes';
    this.confirmDialog.confirmDialog(titleName, message, cancelText, acceptText);
    this.confirmDialog.dialogResult.subscribe(res => {
      if (res) {
        this.discoverySettingsService.deleteApiDiscoverysettingsIdDelete({id: iprange._id}).subscribe((result: any) => {
          this.toast.sToast('success', 'Removed successfully');
          setTimeout(() => {
            this.getIPRanges();
          }, 2000);
        });
      }
    });
  }

  ngOnInit(): any {
    this.ipraTableOptions.pageData = [];
    this.getAgents();
    if (this.initAdd) {
     // setTimeout(() => this.rangaddTableData(), 2000);
    }
  }

  ngAfterViewInit(): void {
    this.getIPRanges();
  }

  rangshowHideLoading(status: any): void {
    const data = Object.assign({}, this.ipraTableOptions);
    this.ipraTableOptions = {};
    this.ipraTableOptions = data;
    this.ipraTableOptions.tableOptions.loading = status;
  }

  rangglobalActionCall(idata: any): void {
    console.log(idata);
    if (idata.action.text === 'Delete') {
      this.deleteIPRanges(idata);
    }
  }

  deleteIPRanges(idata: any): void {
    const titleName = 'Confirmation';
    const message = 'Are you sure you want to remove the selected ip entries ?';
    const cancelText = 'No';
    const acceptText = 'Yes';
    this.confirmDialog.confirmDialog(titleName, message, cancelText, acceptText);
    this.confirmDialog.dialogResult.subscribe(res => {
      if (res) {
        this.loaderService.display(true, 'Deletion in progress...');
        idata.row.forEach((obj: any, index: number) => {
          this.discoverySettingsService.deleteApiDiscoverysettingsIdDelete({id: obj._id}).subscribe((result: any) => {
            if (index === idata.row.length - 1) {
              this.loaderService.display(false);
              this.toast.sToast('success', 'Removed successfully');
              setTimeout(() => { this.getIPRanges(); }, 2000);
            }
          });
        });
      }
    });
  }

  iprasortCall(idata: any): void {
    this.ipraTableOptions.sortOptions = idata;
    this.getIPRanges();
  }

  rangeditFn(idata: any): void {
    console.log(idata);
    this.assetTags = [];
    if (idata.tags && idata.tags.length) {
      idata.tags.forEach((obj: string) => { this.assetTags.push({name: obj}); });
    }
    this.iprange = Object.assign({}, idata);
    this.changeDiscoveryType(this.iprange.discovery_type);
    this.modalService.open('addExcludeIprange');
  }

  rangdeleteFn(idata: any): void {
    this.deleteRange(idata);
  }

  rangactionCall(idata: any): void {
    if (idata.action.text === 'Edit') {
      const dataRow = idata.row;
      this.isLoading = false;
      this.rangeditFn(dataRow);
    }
    if (idata.action.text === 'Delete') {
      const dataRow = idata.row;
      this.rangdeleteFn(dataRow);
    }
  }

  rangfilterCall(idata: any): void {
    const fields: any[] = [];
    this.ipraTableOptions.columns.forEach((obj: { columnDef: any; }) => {
      if (obj.columnDef !== 'isExcluded' &&obj.columnDef !== 'c' && obj.columnDef !== 'u') {  fields.push(obj.columnDef); }
    });
    this.rangfilterQuery = (idata && idata.length > 0) ? {
      multi_match: {
        query: idata,
        type: 'phrase_prefix',
        fields
      }
    } : undefined;
    this.getIPRanges();
  }

  rangpageCall(event: any): void {
    this.ipraTableOptions.tableOptions.pageSize = event.pageSize;
    this.rangcurrentPage = event.pageIndex;
    this.getIPRanges();
  }

  rangaddTableData(): void {
    this.isLoading = false;
    this.iprange = {
      name: 'Set1', ip_start: '192.168.0.1', ip_end: '', subnet_mask: '/24',
      discovery_type: 'cidr', isExcluded: true,agentRef: {id: '', name: ''}
    };
    if(!this.currentAgent._id){
      this.toast.sToast('error', 'Agent not found');
      return;
    }
    if (this.initAdd) { this.iprange.discovery_type = 'static'; }
    if (this.currentAgent && this.currentAgent.ip) {
      const ipArr = this.currentAgent.ip.split('.'); ipArr[3] = '1'; this.iprange.ip_start = ipArr.join('.');
    }
    if(this.currentAgent?.agent_type === 'External Scan Agent' || this.currentAgent?.agent_type === 'Lightweight Agent Installed' || this.currentAgent?.agent_type === 'Lightweight Agent') { this.iprange.ip_start = ''};
    this.iprange.agentRef.id = (this.currentAgent) ? this.currentAgent._id : this.agentList[0]._id;
    this.iprange.agentRef.name = (this.currentAgent) ? this.currentAgent.name : this.agentList[0].name;
    this.changeDiscoveryType(this.iprange.discovery_type);
    this.modalService.open('addExcludeIprange');
  }

  rangtimerCallData(idata: any): void {
    this.getIPRanges();
  }

  getAgents(): void {
    if (this.currentCompany && this.currentCompany._id) {
      this.commonService.getAgents().then((value: any) => {
        const externalAgent = value.data[0];
        this.commonService.getAgents(this.currentCompany._id).then((v2: any) => {
          this.commonService.sortFn(v2.data, 'name').then((val: any) => {
            this.agentList = val;
            this.agentList.unshift(externalAgent);
            this.agentHash = new Map(this.agentList.map((i: any) => [i._id, i.name]));
          });
        });
        console.log(this.agentList);
      });
    } else {
      setTimeout(() => { this.getAgents(); }, 1000);
    }
  }

  getIPRanges(): void {
    this.loaderService.display(true, 'IP Ranges...');
    this.rangshowHideLoading(true);
    this.ipraTableOptions.serverSide = {
      service: 'discoverySettingsService', fn: 'getAllApiDiscoverysettingsGet', q: {
        query: {
          bool: {
            must: [
              {match: {'companyRef.id.keyword': this.currentCompany._id + ''}},
              {match: {'isExcludedForAd': true}},
              {exists: {field: 'discovery_type'}}
            ]
          }
        }
      }
    };
    let params;
    params = {
      query: {
        bool: {
          must: [{match: {'companyRef.id.keyword': this.currentCompany._id + ''}},
          {match: {'isExcludedForAd': true}},
          {exists: {field: 'discovery_type'}}]
        }
      }
    };
    if (this.currentAgent) { // @ts-ignore
      params.query.bool.must.push({match: {'agentRef.id.keyword': this.currentAgent._id + ''}});
      this.ipraTableOptions.serverSide.q.query.bool.must.push({match: {'agentRef.id.keyword': this.currentAgent._id + ''}});
    }
    if (this.rangfilterQuery && this.rangfilterQuery.multi_match) {
      // @ts-ignore
      params.query.bool.must.push(this.rangfilterQuery);
    }
    let sort: any = [{}];
    if (this.ipraTableOptions.sortOptions) {
      const orderArr = ['name'];
      if (orderArr.indexOf(this.ipraTableOptions.sortOptions.active) !== -1) {
        sort[0][this.ipraTableOptions.sortOptions.active + '.keyword'] = {order: this.ipraTableOptions.sortOptions.direction};
      } else { 
       sort[0][this.ipraTableOptions.sortOptions.active] = {order: this.ipraTableOptions.sortOptions.direction};
    }
  }
    const q = JSON.stringify(params);
    const skip = this.rangcurrentPage;
    sort = JSON.stringify(sort);
    const limit = this.ipraTableOptions.tableOptions.pageSize;
    this.discoverySettingsService.getAllApiDiscoverysettingsGet({q, skip, limit,sort})
      .subscribe((result: any) => {
        this.loaderService.display(false);
        if (result.data.length) {
          this.ipraTableOptions.pageData = result.data;
          this.ipraTableOptions.tableOptions.pageTotal = result.total;
          this.iprangeList.emit(result.total);
          this.rangshowHideLoading(false);
        } else {
          this.ipraTableOptions.pageData = [];
          this.ipraTableOptions.tableOptions.pageTotal = 0;
          this.rangshowHideLoading(false);
        }
        if (!this.ipraTableOptions.tableOptions.pageTotal) {
          setTimeout(() => { this.rangaddTableData(); }, 3000);
        }
      });
  }
  addTag(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim() && !this.assetTags.filter((x: any) => x.name === value.trim()).length ) {
      this.assetTags.push({name: value.trim()});
    }
    if (input) {
      input.value = '';
    }
  }

  removeTags(tag: any): void {
    const index = this.assetTags.indexOf(tag);
    if (index >= 0) {
      this.assetTags.splice(index, 1);
    }
  }

  downloadSampleCSV(): void {
    if (this.isExternal) {
      window.open('/assets/bulk_ip_upload_external_scan_sample.csv', '_blank');
    } else {
      window.open('/assets/bulk_ip_upload_sample.csv', '_blank');
    }
  }
}
