<app-s-table aria-label="IPRanges" role="table" [sTableOptions]="ipraTableOptions" (sortCallback)="iprasortCall($event)"
             (filterCallback)="rangfilterCall($event)" (actionCallback)="rangactionCall($event)"
             (parentCallback)="parentCallBack($event)"
             (pageCallback)="rangpageCall($event)" (refreshCallback)="getIPRanges()"
             (globalActionCallback)="rangglobalActionCall($event)" (addCallback)="rangaddTableData()"
             (timerCallback)="rangtimerCallData($event)"></app-s-table>
<s-modal id="addIprange">
  <form #addI="ngForm">
    <mat-card class="d-flex flex-row is-align-items-flex-start p-0">
      <div class="d-flex flex-row align-items-center list-right list-right w-25">
        <div class="col-lg-12 text-center">
          <img src="/assets/images/dk/023-drawkit-folder-man-colour.svg" alt="Add IP Range" width="200px">
          <p class="mat-body-1">Add an IP Range <strong>goes</strong> here</p>
        </div>
      </div>
      <mat-card class="modal mat-elevation-z0 pr-0" [style.width.px]="addIprangeWidth">
        <button class="float-right" mat-icon-button aria-label="close modal icon" matTooltip="Close" type="button"
                (click)="modalService.close('addIprange');">
          <mat-icon>close</mat-icon>
        </button>
        <div class="mat-title">IP Ranges</div>
        <mat-card-content class="fcard-content" *ngIf="iprange && iprange.agentRef">
          <mat-form-field class="w-75" appearance="outline">
            <mat-label >Name</mat-label>
            <input matInput id="IPRangesName" placeholder="Enter network name" type="text" trim="blur" required name="name"
                   [(ngModel)]="iprange.name" autocomplete="off">
            <mat-hint>Ex. Set 1</mat-hint>
          </mat-form-field>
          <mat-form-field class="w-75" appearance="outline">
            <mat-label >Discovery Type</mat-label>
            <mat-select placeholder="Choose Discovery Type" id="IPRangesDiscoveryType" name="discovery_type"
                        *ngIf="currentAgent.agent_type !== 'External Scan Agent'"
                        (selectionChange)="changeDiscoveryType(iprange.discovery_type)"
                        [(ngModel)]="iprange.discovery_type">
              <mat-option *ngFor="let item of discoveryList" [value]="item.value" [id]="item.text">{{item.text}}</mat-option>
            </mat-select>
            <mat-select placeholder="Choose Discovery Type" name="discovery_type"
                        *ngIf="currentAgent.agent_type === 'External Scan Agent'"
                        (selectionChange)="changeDiscoveryType(iprange.discovery_type)"
                        [(ngModel)]="iprange.discovery_type">
              <mat-option *ngFor="let item of extDiscoveryList" [value]="item.value">{{item.text}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="w-75" appearance="outline" *ngIf="iprange.discovery_type === 'static'
           || iprange.discovery_type === 'cidr' || iprange.discovery_type === 'fromto'">
            <mat-label >Start IP</mat-label>
            <input matInput id="DiscoveryStartIP" placeholder="Enter Start IP" type="text" trim="blur" required name="start_ip"
                   [pattern]="ipPattern"
                   [(ngModel)]="iprange.ip_start" autocomplete="off">
          </mat-form-field>
          <mat-form-field class="w-75" appearance="outline" *ngIf="iprange.discovery_type === 'staticDomain'">
            <mat-label>Domain Name</mat-label>
            <input matInput placeholder="Enter Start IP" type="text" trim="blur" required name="start_ip"
                   [(ngModel)]="iprange.ip_start" autocomplete="off">
          </mat-form-field>
          <mat-form-field class="w-75" appearance="outline" *ngIf="isEndIp">
            <mat-label >End IP</mat-label>
            <input matInput id="DiscoveryEndIP" placeholder="Enter End IP" type="text" trim="blur" required name="end_ip"
                   [pattern]="ipPattern"
                   [(ngModel)]="iprange.ip_end" autocomplete="off">
          </mat-form-field>
          <mat-form-field class="w-75" appearance="outline" *ngIf="isNetMask">
            <mat-label >Netmask</mat-label>
            <mat-select [multiple]="false" id="DiscoveryNetmask" placeholder="Choose Netmask" name="netmask" [(ngModel)]="iprange.subnet_mask">
              <mat-option *ngFor="let item of netmaskList" [value]="item.value" [id]="item.text"> {{item.text}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="w-75" appearance="outline" *ngIf="!currentAgent">
            <mat-label>Choose Probe/Agent</mat-label>
            <mat-select placeholder="Choose Probe/Agent" name="agentRef" required
                        (selectionChange)="changeAgent(iprange.agentRef.id)"
                        [(ngModel)]="iprange.agentRef.id">
              <mat-option *ngFor="let item of agentList" [value]="item._id">{{item.name}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="w-75" appearance="outline">
            <mat-label>Enter Tags</mat-label>
            <mat-chip-list #chipList aria-label="Tag selection">
              <mat-chip *ngFor="let tag of assetTags" [selectable]="selectable" [removable]="removable"
                        (removed)="removeTags(tag)">
                {{tag.name}}
                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
              </mat-chip>
              <input placeholder="Enter a tag and press comma or enter to add." [matChipInputFor]="chipList"
                     [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
                     (matChipInputTokenEnd)="addTag($event)">
            </mat-chip-list>
          </mat-form-field>
          <mat-checkbox class="mr20" id="IPExcludeScan"  name="isExclude" color="primary"
                        [(ngModel)]="iprange.isExcluded">Exclude from scanning</mat-checkbox>
        </mat-card-content>
        <mat-card-footer class="mat-card-footer text-right pt-3 pb-3 toolbar-top">
          <button mat-raised-button class="mat-primary mr-2" *ngIf="!iprange._id" type="button"
                  (click)="saveIprange()" [disabled]="!addI.form.valid || isLoading"
                  id="saveip">Save</button>
          <button mat-raised-button class="mat-primary mr-2" *ngIf="iprange._id" type="button"
                  (click)="updateIprange(iprange)" [disabled]="!addI.form.valid || isLoading"
                  id="updateip">Update</button>
          <button mat-stroked-button class="mat-warn" (click)="modalService.close('addIprange')" type="button"
                  id="cancelip">Cancel</button>
        </mat-card-footer>
      </mat-card>
    </mat-card>
  </form>
</s-modal>
<s-modal id="uploadBulkIP">
  <form #ipUpload="ngForm">
    <mat-card class="d-flex flex-row is-align-items-flex-start py-0" [style.width.px]="addIprangeWidth">
      <div class="d-flex flex-row align-items-center list-right list-right">
        <div class="col-lg-12 text-center">
          <img src="/assets/images/releasenotes.png" alt="Release Notes" width="200px">
          <p class="mat-body-1">Upload Bulk IP CSV File</p>
        </div>
      </div>
      <mat-card class="modal mat-elevation-z0 pr-0 w-100">
        <button class="float-right" mat-icon-button aria-label="close modal icon" matTooltip="Close"
                (click)="modalService.close('uploadBulkIP');">
          <mat-icon>close</mat-icon>
        </button>
        <div class="mat-title">Upload Bulk IP CSV File</div>
        <mat-card-content class="">
          <p>Download sample csv file <a href="javascript:" (click)="downloadSampleCSV()">here</a>.</p>
          <input #newIp type="file" class="w-100 mb10" accept=".csv" name="file"
                                       (change)="uploadFile($event, 'uploadfile')"/>
        </mat-card-content>
        <mat-card-footer class="mat-card-footer text-right pt-3 pb-3 pr-3 toolbar-top">
          <button mat-raised-button class="mat-primary mr-2"
                  (click)="uploadBulkIPCSV()" [disabled]="!ipUpload.form.valid || isLoading"
                  id="save11">Upload
          </button>
          <button mat-stroked-button class="mat-warn" (click)="modalService.close('uploadBulkIP'); isLoading = false;"
                  id="cancel11">Cancel
          </button>
        </mat-card-footer>
      </mat-card>
    </mat-card>
  </form>
</s-modal>
