import {Component, OnInit, Input} from '@angular/core';
import {CompanySharedService} from '../../_services/company-shared.service';
import {LoaderService} from '../../_services/loader.service';
import {MyToastrService} from '../../_services/toastr.service';
import {ConfirmDialogService} from '../../_services/confirmdialog.service';
import {CompanyService} from '../../api/services/company.service';
import { BaseRequestService } from 'src/app/_services/base.service';

@Component({
  selector: 'app-discovery-settings',
  templateUrl: './discovery-settings.component.html',
  styleUrls: ['./discovery-settings.component.scss']
})
export class DiscoverySettingsComponent {
  @Input() currentCompany: any;
  @Input() currentAgent: any;
  scanTypeList = ['', 'Full Scan', 'Asset Scan', 'Vulnerability Scan', 'External Scan', 'Port Scan'];
  scanType: any;
  st = '';
  notes: any = 'This tab allows you to allocate the required number of processes to be executed at a time, when a scan is triggered.\n' +
  '\n' +
  'This is based on the system configuration where the agent is installed.\n' + 
  '\n' +
  '(Ex - 4 Core Processor - 16 Processes)'
  maxThreadCount = null;
  constructor(private cs: CompanySharedService, private baseService: BaseRequestService,
              private confirmDialog: ConfirmDialogService, private companyService: CompanyService,
              private loaderService: LoaderService, private toast: MyToastrService) {
    cs.companyScanEVE.subscribe((value: any) => {
      this.st = value.st;
      this.scanType = this.scanTypeList[value.st];
      this.confirmScan();
    });
  }

  updateProcessCount(): void {
    if (!this.maxThreadCount || this.maxThreadCount === 0) { 
      return this.toast.sToast('error', 'Please enter valid count.');
    }
    this.loaderService.display(true);
    this.baseService.doRequest(`/api/agent/`,
      'put', {_id: this.currentAgent?._id, maxThreadCount: this.maxThreadCount}).subscribe((res: any) => {
        this.loaderService.display(false);
        if(res){
          this.maxThreadCount = res?.maxThreadCount;
          this.toast.sToast('success', 'Count Updated Sucessfully!');
        } else {
          this.toast.sToast('error', res);
        }
      });
          
  }
  ngOnInit(): void {
    this.maxThreadCount = this.currentAgent?.maxThreadCount;
  }

  confirmScan(): void {
    this.loaderService.display(true, `Initiating a ${this.scanType}`);
    this.companyService.companyscanApiCompanyIdScanPost({
      id: this.currentCompany._id, body: {
        companyid: this.currentCompany._id,
        scantype: +this.st
      }
    }).subscribe(result => {
      this.loaderService.display(false);
      if (result[0]) {
        this.toast.sToast('success', result[1]);
      } else {
        this.toast.sToast('success', result[1]);
      }
    });
  }
}
