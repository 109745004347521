import {Component, OnInit, Output, EventEmitter, Input} from '@angular/core';
import {LoaderService} from '../../_services/loader.service';
import {BaseRequestService} from '../../_services/base.service';
import {MyToastrService} from '../../_services/toastr.service';
import {CompanySharedService} from '../../_services/company-shared.service';
import {Router} from '@angular/router';
import { CommonService } from 'src/app/_services/common.services';

@Component({
  selector: 'app-insights',
  templateUrl: './insights.component.html',
  styleUrls: ['./insights.component.scss']
})
export class InsightsComponent implements OnInit {
  @Output() closeEmitter = new EventEmitter();
  masterInsights: any;
  insightsData: any;
  showUpper = true;
  currentCompany: any;
  insightsCols: any = ['companyName', 'totalAssets', 'assets', 'external', 'firewalls'];
  insightsCustomCols: any = [
    {col: 'companyName', hyperLinkCall: {noFilter: true}},
    {col: 'totalAssets', hyperLinkCall: {noFilter: true}}
  ];
  constructor(private loaderService: LoaderService, private baseService: BaseRequestService, public commonService: CommonService,
              private toast: MyToastrService, private router: Router, private cs: CompanySharedService) {
  }

  ngOnInit(): void {
    this.getPartnerInsights();
  }

  getPartnerInsights(): void {
    this.loaderService.display(true, 'Getting Partner Insights...');
    this.baseService.doRequest(`/api/cyberutils/imaws/getPartnerInsightsData`,
      'post', {companyid: '', getReport: '', isAssessment: this.baseService.showAssessment}).subscribe((result: any) => {
      this.loaderService.display(false);
      if (result[0]) {
        this.masterInsights = result[1];
        this.insightsData = result[1].companyOverview;
        this.insightsData.sort((a: any, b: any) => {
          const c = a.totalAssets;
          const d = b.totalAssets;
          if (c < d) {
            return -1;
          } else if (c > d) {
            return 1;
          } else {
            return 0;
          }
        });
      } else {
        this.toast.sToast('error', result[1]);
      }
    }, (err: any) => {
      this.loaderService.display(false);
      this.toast.sToast('error', 'Error receiving data.');
      this.masterInsights = {
        totalCompanies: 0,
        totalAssets: 0,
        companyOverview: [ ]
      };
      this.insightsData = this.masterInsights.companyOverview;
    });
  }

  showDetails($event: any): void {
    if ($event.col === 'companyName') {
      localStorage.setItem('cmp', $event.row.companyName);
      this.cs.setCurrentCompany($event.row.companyName);
      this.router.navigateByUrl('companies/company');
      this.closeEmitter.emit({});
    } else if ($event.col === 'totalAssets') {
      this.currentCompany = $event.row;
    }
  }

  downloadReport(companyid: string, format: string): void {
    this.loaderService.display(true, 'Getting Report Path...');
    this.baseService.doRequest(`/api/cyberutils/imaws/getPartnerInsightsData`,
        'post', {companyid, getReport: format}).subscribe( (result: any) => {
        this.loaderService.display(false);
        if (result[0]) {
            window.open(result[1], '_blank');
        } else {
            this.toast.sToast('error', result[1]);
        }
    });
  }
}
