import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LoaderService } from '../../_services/loader.service';
import { IntegrationsService } from '../../api/services/integrations.service';
import {
  fadeInOnEnterAnimation,
  fadeOutOnLeaveAnimation,
  zoomInAnimation,
  zoomInOnEnterAnimation,
  zoomOutAnimation,
  zoomOutOnLeaveAnimation,
} from 'angular-animations';
import { MyToastrService } from '../../_services/toastr.service';
import { ConfirmDialogService } from '../../_services/confirmdialog.service';
import { BaseRequestService } from '../../_services/base.service';
import { IntegrationActionsService } from '../../_services/integration-actions.service';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-integrations',
  templateUrl: './integrations.component.html',
  styleUrls: ['./integrations.component.scss'],
  animations: [
    zoomInOnEnterAnimation(),
    zoomOutOnLeaveAnimation(),
    zoomOutAnimation(),
    zoomInAnimation(),
    fadeInOnEnterAnimation(),
    fadeOutOnLeaveAnimation(),
  ],
})
export class IntegrationsComponent implements OnInit {
  @Input() onboarding: any;
  @Input() currentCompany: any;
  @Input() filterType: any;
  @Input() mode: any;
  @Output() importFinished = new EventEmitter();
  defaultActionSettings = false;
  integrationsData: any[];
  integrations: any[];
  categoryList: any[];
  defaultActionSetHash: any = {};
  formElements: any = [];
  currentIntegration: any;
  defaultActionData: any = {};
  defaultActionIntegration: any;
  actionData: any;
  integrationsCredentialsData: any[];
  integrationSearch = '';
  authenticateParamsData: any[];
  inputActionData: any = {};
  credData: any = {};
  configuratedIntegration: any = {
    companyMapping: {},
    credentails: []
  };
  compLevelIntegration: any = ['Draytek', 'Fortigate', 'pfSense', 'Sonicwall', 'Sophos', 'Untangle', 'Watchguard' ];
  newCred = false;
  typeHash: any = { string: 'text', number: 'number' };
  integrationFormValid = false;
  currentParams: any;
  currentParamHash: any = {};
  actionParams: any;
  Objectkeys = Object.keys;

  constructor(
    private integrationsService: IntegrationsService,
    private toast: MyToastrService,
    private baseService: BaseRequestService,
    private integrationActionService: IntegrationActionsService,
    private confirmDialog: ConfirmDialogService,
    private loaderService: LoaderService
  ) {
    integrationActionService.integrationOauthEvent.subscribe((value: any) => {
      console.log(value.code);
      this.validateOAuthCode(value.code);
    });
  }

  ngOnInit(): void {
    this.getAllIntegrations();
    this.getConfiguratedIntegration();
  }

  getConfiguratedIntegration(): void {
    const reqData = {
      companyId: (this.mode === 'company') ? this.currentCompany._id : ''
    };
    this.baseService
      .doRequest(`/api/integrations/configured_integation`, 'post', reqData)
      .subscribe((result: any) => {
        if (result[0]){
        this.configuratedIntegration = result[1];
        }
      });
  }

  getAllIntegrations(): void {
    this.loaderService.display(true);
    this.baseService
      .doRequest(`/api/integrations/`, 'get')
      .subscribe((result: any) => {
        this.loaderService.display(false);
        if (this.onboarding && this.filterType) {
          result = result.filter(
            (x: any) => x.integrationtype === this.filterType
          );
        }
        result.sort((a: any, b: any) => {
          const c = a.name.toLowerCase();
          const d = b.name.toLowerCase();
          if (c < d) {
            return -1;
          } else if (c > d) {
            return 1;
          } else {
            return 0;
          }
        });

        this.integrations = (this.mode === 'company')
          ? result.filter((inte: any) => this.compLevelIntegration.includes(inte.name)) : result;
        this.categoryList = [...new Set(this.integrations.map((x: any) => x.integrationtype))].sort();
      });
  }

  getIntegrationsData(): void {
    this.loaderService.display(true);
    const url = (this.mode === 'company') ? `/api/integrations/companyfirewalls?integrationName=${this.currentIntegration.name}&companyId=${this.currentCompany._id}` : `/api/integrations/getcredentials?integrationName=${this.currentIntegration.name}`;
    this.baseService
      .doRequest(url, 'get')
      .subscribe((result: any) => {
        this.loaderService.display(false);
        if (result.data && result.data.length) {
          this.integrationsData = result.data;
          this.credData = result.data[0];
          this.defaultActionSetHash[this.credData._id] = false;
          this.getDefaultActionSettings();
          this.newCred = false;
        } else {
          this.integrationsData = [];
          this.newCred = true;
          this.currentIntegration.parameters.forEach((obj: any) => {

            this.credData[obj.name] =
              obj.schema.type === 'checkbox' ? false : '';
            this.formElements.forEach((frm: any) => {
              if (frm.key === 'name') {
                frm.readonly = false;
              }
              if (frm.readonly && frm.key !== 'name') {
                this.credData[obj.name] = this.currentParamHash[obj.name];
              }
            });
          });
        }
      });
  }

  setIntegration(product: any): void {
    this.currentIntegration = product;
    this.getAuthenticateParamsData(product.name);
  }

  getAuthenticateParamsData(integration: string): void {
    this.loaderService.display(true, 'Getting integration data...');
    this.formElements = [];
    this.baseService
      .doRequest(
        `/api/integrations/params?integrationName=${integration}`,
        'get'
      )
      .subscribe((result: any) => {
        this.loaderService.display(false);
        this.currentParams = result;
        const tmpFE: {
          label: any;
          key: any;
          required: any;
          example?: any;
          type: any;
        }[] = [];
        result.parameters.forEach((obj: any) => {
          this.credData[obj.name] = obj.schema.type === 'checkbox' ? false : '';
          if (obj.readonly) {
            this.currentParamHash[obj.name] = obj.value;
          }
          const ff: any = {
            label: obj.description,
            key: obj.name,
            required: obj.required,
            example: obj.example,
            type: obj.schema.type,
            allcompany: (obj.allcompany) ? obj.allcompany : false
          };
          ff.readonly = obj.readonly;
          if (obj.schema.type === 'dropdown') {
            ff.options = obj.options;
          }
          tmpFE.push(ff);
        });
        this.formElements = tmpFE.slice();
        this.currentIntegration.parameters = result.parameters;
        this.currentIntegration.isoauth = result.isoauth;
        this.getIntegrationsData();
      });
  }

  validateOAuthCode(code: any): void {
    let ncsp: any;
    try {
      ncsp = JSON.parse(localStorage.getItem('ncsp') as string);
      console.log(ncsp);
    } catch (e) {
      console.log('Error parsing AD config');
    }
    this.loaderService.display(true);
    this.baseService
      .doRequest(`/api/integrations/validateAuthcode`, 'post', {
        code,
        isCsp: ncsp.isCsp,
      })
      .subscribe((result: any) => {
        this.loaderService.display(false);
        if (result.status) {
          console.log(result.data);
          const cred: any = { credential: { ...ncsp, ...result.data } };
          const data: any = { ...cred };
          data.integrationName = this.currentIntegration.name;
          this.saveAAD(data);
          localStorage.removeItem('oAuthC');
          localStorage.removeItem('ncsp');
        } else {
          this.toast.sToast('error', result.data);
        }
      });
  }

  oAuthCredValidation(event: any): void {
    let clientId: any = '';
    let url: any = '';
    this.currentParams.parameters.forEach((obj: any) => {
      if (obj.name === 'client_id') {
        clientId = obj.value;
      }
      if (event.isCsp) {
        if (obj.name === 'CSP') {
          url = obj.value;
        }
      } else {
        if (obj.name === 'NonCSP') {
          url = obj.value;
        }
      }
    });
    const rToken = uuidv4();
    const stateVar = btoa(
      `${window.location.host}@${rToken}@${clientId}`
    ).replace(/=/g, '');
    const urlCSP = `${url}&state=${stateVar}`;
    localStorage.removeItem('oAuthC');
    localStorage.setItem('ncsp', JSON.stringify(event));
    this.baseService.o365window = window.open(
      urlCSP,
      'popup',
      'width=600,height=600'
    );
    this.checkIsOAuthCode();
  }

  checkIsOAuthCode(): void {
    const oAuthC = localStorage.getItem('oAuthC');
    if (oAuthC) {
      let ncsp: any;
      try {
        ncsp = JSON.parse(localStorage.getItem('ncsp') as string);
        console.log(ncsp);
      } catch (e) {
        console.log('Error parsing AD config');
      }
      const cred: any = { credential: { ...ncsp } };
      cred.credential.code = oAuthC;
      const data: any = { ...cred };
      data.integrationName = this.currentIntegration.name;
      this.saveAAD(data);
      localStorage.removeItem('oAuthC');
      localStorage.removeItem('ncsp');
      return;
    }
    setTimeout(() => {
      this.checkIsOAuthCode();
    }, 1000);
  }

  save(event: any): void {
    if (this.currentIntegration.name === 'AzureAD') {
      this.oAuthCredValidation(event);
      return;
    }
    event.companyId = (event.companyId) ? event.companyId : (this.currentCompany) ? this.currentCompany?._id : '*';
    event.credentialId = this.credData._id;
    this.loaderService.display(true, 'Saving credential...');
    this.baseService
      .doRequest(`/api/integrations/storecredentials`, 'post', {
        integrationName: `${this.currentIntegration.name}`,
        credential: event,
      })
      .subscribe((result: any) => {
        this.loaderService.display(false);
        if (result.status) {
          const msg = this.credData._id
            ? 'Successfully updated'
            : 'Successfully created';
          this.toast.sToast('success', msg);
          this.newCred = false;
          setTimeout(() => {
            this.setIntegration(this.currentIntegration);
            // this.getIntegrationsData();
          }, 2000);
        } else {
          this.toast.sToast('error', result.errmsg);
        }
      });
  }

  saveAAD(event: any): void {
    if (this.currentIntegration.isoauth && !event.credential) {
      this.oAuthCredValidation(event);
      return;
    }
    let data: any;
    data = {
      integrationName: `${this.currentIntegration.name}`,
      credential: event.credential,
    };
    if (
      this.currentIntegration.name === 'AzureAD' &&
      event.credential.access_token
    ) {
      data = event;
    }
    this.loaderService.display(true, 'Saving credential...');
    this.baseService
      .doRequest(`/api/integrations/storecredentials`, 'post', data)
      .subscribe((result: any) => {
        this.loaderService.display(false);
        if (result.status) {
          const msg = this.credData._id
            ? 'Successfully updated'
            : 'Successfully created';
          this.toast.sToast('success', msg);
          this.newCred = false;
          setTimeout(() => this.setIntegration(this.currentIntegration), 2000);
        } else {
          this.toast.sToast('error', result.errmsg);
        }
      });
  }

  cancel(): void {
    this.closeDefaultAction();
    if (this.newCred) {
      this.newCred = false;
      if (this.integrationsData[0]) {
        this.credData = this.integrationsData[0];
      }
      this.currentIntegration.parameters.forEach((obj: any) => {
        this.formElements.forEach((frm: any) => {
          if (frm.key === 'name') {
            frm.readonly = false;
          }
        });
      });
      this.setIntegration(this.currentIntegration);
    } else {
      this.currentIntegration = undefined;
      this.formElements = [];
    }
  }

  setCred(cred: any): void {
    this.credData = cred;
    this.getDefaultActionSettings();
  }

  addCred(): void {
    this.newCred = true;
    this.credData = {};
    this.currentIntegration.parameters.forEach((obj: any) => {
      this.credData[obj.name] = obj.schema.type === 'checkbox' ? false : '';
      this.formElements.forEach((frm: any) => {
        if (frm.key === 'name') {
          frm.readonly = false;
        }
        if (frm.readonly && frm.key !== 'name') {
          this.credData[obj.name] = this.currentParamHash[obj.name];
        }
      });
    });
  }

  delCred(cred: any): void {
    const titleName = 'Confirmation';
    const message =
      'Are you sure you want to delete credential ' + cred.name + ' ?';
    const cancelText = 'No';
    const acceptText = 'Yes';
    this.confirmDialog.confirmDialog(
      titleName,
      message,
      cancelText,
      acceptText
    );
    this.confirmDialog.dialogResult.subscribe((res) => {
      if (res) {
        this.loaderService.display(true, `Removing ${cred.name} credential`);
        this.baseService
          .doRequest(
            `/api/integrations/deletecredential?integrationName=${this.currentIntegration.name}&integrationId=${cred._id}`,
            'post'
          )
          .subscribe((result: any) => {
            if (result) {
              this.toast.sToast('success', 'Removed successfully');
            }
            setTimeout(() => this.getIntegrationsData(), 2000);
          });
      }
    });
  }

  /* Default Settings for the integration */

  showDefault(): void {
    this.defaultActionSettings = true;
    this.defaultActionIntegration = Object.assign({}, this.currentIntegration);
    this.getActionParams();
  }

  closeDefaultAction(): void {
    this.defaultActionSettings = false;
    this.defaultActionIntegration = undefined;
  }

  saveDefaultAction(requestparams: any): void {
    this.loaderService.display(true, 'Saving default action params...');
    const params = {
      integrationName: this.currentIntegration.name,
      credentialid: this.credData._id,
      actionName: this.currentIntegration.notificationfunction,
      params: {
        action: {
          name: this.actionData.name,
          destination: this.actionData.destination,
          verb: this.actionData.verb,
        },
        params: { requestparams },
      },
    };
    this.baseService
      .doRequest(`/api/integrations/global_action_params`, 'post', params)
      .subscribe((result: any) => {
        this.loaderService.display(false);
        if (result[0]) {
          this.toast.sToast('success', 'Successfully updated');
          this.defaultActionSettings = false;
          this.defaultActionIntegration = undefined;
          setTimeout(() => this.getDefaultActionSettings(), 3000);
        } else {
          this.toast.sToast('error', result[1]);
        }
      });
  }

  getDefaultActionSettings(): void {
    this.loaderService.display(true, 'Getting default action settings...');
    this.baseService
      .doRequest(
        `/api/integrations/global_action_params?integrationName=${this.currentIntegration.name}&credentialid=${this.credData._id}&actionName=${this.currentIntegration.notificationfunction}`,
        'get'
      )
      .subscribe((result: any) => {
        this.loaderService.display(false);
        this.defaultActionIntegration = (!this.defaultActionIntegration)
           ? Object.assign({}, this.currentIntegration) : this.defaultActionIntegration;
        this.defaultActionIntegration.actionData = [];
        this.defaultActionData = {};
        if (result[0]) {
          this.defaultActionData = result[1];
          this.defaultActionSetHash[this.credData._id] = true;
          this.defaultActionIntegration.actionData = result[1].params;
        } else {
          this.defaultActionSetHash[this.credData._id] = false;

          // this.toast.sToast('error', result[1].err);
        }
      });
  }

  getActionParams(): void {
    this.loaderService.display(
      true,
      `Getting ${this.defaultActionIntegration.name}`
    );
    this.baseService
      .doRequest(
        `/api/integrations/action_params?integrationName=${this.defaultActionIntegration.name}&actionName=${this.defaultActionIntegration.notificationfunction}`,
        'get'
      )
      .subscribe((result: any) => {
        this.loaderService.display(false);
        if (result) {
          result.parameters = result.parameters.filter(
            (x: any) => x.notification && x.requiredforglobal !== false
          );
          this.actionData = result;
          this.defaultActionIntegration.actionResult = result;
          this.processFields(this.defaultActionIntegration.actionResult);
        } else {
          this.actionData = {};
          this.defaultActionIntegration.actionResult = {};
        }
      });
  }

  processFields(result: any): void {
    if (!result || !result.parameters) {
      return;
    }
    this.defaultActionIntegration.actionData = this.defaultActionIntegration
      .actionData
      ? this.defaultActionIntegration.actionData
      : [];
    if (this.defaultActionIntegration.actionData.length === 0 && this.defaultActionData.params) {
      this.defaultActionIntegration.actionData = this.defaultActionData.params;
    }
    const tmpAP: {
      label: any;
      key: any;
      required: any;
      example?: any;
      type: any;
    }[] = [];
    result.parameters.forEach(async (obj: any) => {
      const tmpFormField = this.defaultActionIntegration.formElements
        ? this.defaultActionIntegration.formElements.filter(
            (x: any) => x.key === obj.name
          )
        : [];
      const tFF: any = {
        label: obj.description,
        key: obj.name,
        required: obj.required,
        example: obj.example,
        type: obj.schema.type,
        allcompany: (obj.allcompany) ? obj.allcompany : false
      };
      tFF.callback = obj.callback;
      if (obj.source && !obj.depends) {
        if (
          tmpFormField &&
          tmpFormField.length &&
          tmpFormField[0].options &&
          tmpFormField[0].options.length
        ) {
          tFF.options = tmpFormField[0].options;
        } else {
          this.getSubActionParams(obj.source.name).then(async (subObj: any) => {
            const requestParams: any = {};
            obj.subParams = result;
            if (subObj.parameters) {
              subObj.parameters.forEach((pObj: any) => {
                requestParams[pObj.name] = pObj.example;
              });
            }
            this.loaderService.display(true, `Executing ${subObj.name}`);
            this.executeAction(subObj, requestParams).then((subOut: any) => {
              if (subOut.status === 'failed') {
                this.toast.sToast('error', subOut.data);
              } else {
                this.processParams(subOut.data).then((edata: any) => {
                  this.loaderService.display(false);
                  obj.subOut = edata;
                  tFF.options = [];
                  edata.forEach((val: any) => {
                    tFF.options.push({
                      key: val[subObj.DisplayFields[0]],
                      value: val[obj.source.mappedValue] + '',
                    });
                  });
                });
              }
            });
          });
        }
      } else if (
        obj.source &&
        obj.depends &&
        this.defaultActionIntegration.actionData[obj.depends[0]]
      ) {
        this.getSubActionParams(obj.source.name).then(async (subObj: any) => {
          const requestParams: any = {};
          obj.subParams = result;
          const sources: any =
            this.defaultActionIntegration.actionParams.filter(
              (x: any) => x.source && x.source.mappedValue
            );
          if (subObj.parameters) {
            subObj.parameters.forEach((pObj: any) => {
              requestParams[pObj.name] = pObj.example;
              const mValue = sources.filter(
                (x: any) => x.source.mappedValue === pObj.name
              );
              if (mValue.length) {
                requestParams[pObj.name] =
                  this.defaultActionIntegration.actionData[mValue[0].name];
              }
            });
          }
          // requestParams[obj.source.parameter] = this.defaultActionIntegration.actionData[obj.depends[0]];
          this.loaderService.display(true, `Executing ${subObj.name}`);
          this.executeAction(subObj, requestParams).then((subOut: any) => {
            if (subOut.status === 'failed') {
              this.toast.sToast('error', subOut.data);
            } else {
              this.processParams(subOut.data).then((edata: any) => {
                this.loaderService.display(false);
                obj.subOut = edata;
                tFF.options = [];
                edata.forEach((val: any) => {
                  tFF.options.push({
                    key: val[subObj.DisplayFields[0]],
                    value: val[obj.source.mappedValue] + '',
                  });
                });
              });
            }
          });
        });
      }
      tmpAP.push(tFF);
      console.log(this.defaultActionIntegration.actionData[obj.name]);
      if (
        this.inputActionData[obj.name] &&
        !this.defaultActionIntegration.actionData[obj.name]
      ) {
        this.defaultActionIntegration.actionData[obj.name] =
          this.inputActionData[obj.name];
      } else if (
        !this.inputActionData[obj.name] &&
        this.defaultActionIntegration.actionData[obj.name]
      ) {
        this.defaultActionIntegration.actionData[obj.name] =
          this.defaultActionIntegration.actionData[obj.name];
      } else {
        this.defaultActionIntegration.actionData[obj.name] = '';
      }
    });
    this.defaultActionIntegration.formElements = tmpAP.slice();
    this.defaultActionIntegration.actionParams = result.parameters;
  }
  actionChange($event: any): void {
    if ($event && $event !== '') {
      setTimeout(() => {
        this.processFields(this.defaultActionIntegration.actionResult);
      }, 2000);
    }
  }
  getSubActionParams(actionName: string): Promise<any> {
    return this.baseService
      .doRequest(
        // tslint:disable-next-line:max-line-length
        `/api/integrations/action_params?integrationName=${this.defaultActionIntegration.name}&actionName=${actionName}`,
        'get'
      )
      .toPromise();
  }
  executeAction(param: any, requestparams: any): Promise<any> {
    const params: any = {
      integrationName: this.defaultActionIntegration.name,
      integrationId: this.credData._id,
      params: {
        action: {
          name: param.name,
          destination: param.destination,
          verb: param.verb,
        },
        params: { requestparams },
      },
    };
    return this.baseService
      .doRequest(`/api/integrations/executeAction`, 'post', params)
      .toPromise();
  }
  async processParams(data: any): Promise<any> {
    const value: any = [];
    const drow = await data.row_values;
    if (!drow) {
      return value;
    }
    drow.forEach((obj: any) => {
      const obj2: any = {};
      data.column_definitions.forEach((obj1: any, index: number) => {
        const key = this.Objectkeys(obj1)[0];
        obj2[key] = obj[index];
      });
      value.push(obj2);
    });
    return value;
  }

  /* ************************** */

  updateSelectionIndex(index: any): void {
    if (index === 1) {
      // Place holder for tab change event
    }
  }

  isFormValid($event: any): void {
    this.integrationFormValid = $event;
  }

  triggerOnboarding(): void {
    this.importFinished.emit({});
  }

  closeInteg(): void {
    this.defaultActionSettings = false;
    this.currentIntegration = undefined;
    this.credData = {};
    this.formElements = [];
  }
}
