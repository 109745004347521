import { Injectable } from '@angular/core';
import {AdOuService} from '../api/services/ad-ou.service';
import {AdUsersService} from '../api/services/ad-users.service';
import {AdComputersService} from '../api/services/ad-computers.service';
import { AdGpoService } from '../api/services/ad-gpo.service';
import { AdGroupsService } from '../api/services/ad-groups.service';
import { VulnerabilityService } from '../api/services/vulnerability.service';
import { AssetCredentialsService } from '../api/services/asset-credentials.service';
import { ComplianceService } from '../api/services/compliance.service';
import { AssetBestPracticesService } from '../api/services/asset-best-practices.service';
import { AssetFirewallPolicyService } from '../api/services/asset-firewall-policy.service';
import { PortsService } from '../api/services/ports.service';
import { InstalledProgramService } from '../api/services/installed-program.service';
import {ComplianceChecksService} from '../api/services/compliance-checks.service';
import {AlertsService} from '../api/services/alerts.service';
import {EventsService} from '../api/services/events.service';
import {VulnerabilityTimeseriesService} from '../api/services/vulnerability-timeseries.service';
import {AssetService} from '../api/services/asset.service';
import {JobsService} from '../api/services/jobs.service';
import {ApplicationBaselineService} from '../api/services/application-baseline.service';
import {CustomGeneratedReportsService} from '../api/services/custom-generated-reports.service';
import {CustomReportBuilderService} from '../api/services/custom-report-builder.service';
import {AssetServicesService} from '../api/services/asset-services.service';
import {AssetSharesCreate} from '../api/models/asset-shares-create';
import {AssetSharesService} from '../api/services/asset-shares.service';
import {AssetUsersService} from '../api/services/asset-users.service';
import { ADlogsService, ADroleService, ADuserdataService, ADusergroupsService, AzureADcomputersService, AzureadErrorService, CertificateService, NotificationTicketsService, UnquotedserviceService, UserPermissionsService } from '../api/services';
import { PdScanSettingsService } from '../api/services';
import { ExtensionService} from '../api/services';
import { EdrService} from '../api/services';


@Injectable({
  providedIn: 'root'
})

export class MasterService {

  constructor(
    public assetService: AssetService,
    public notificationTicketsService: NotificationTicketsService,
    public assetServicesService: AssetServicesService,
    public jobsService: JobsService,
    public adOuService: AdOuService,
    public adUsersService: AdUsersService,
    public adComputersService: AdComputersService,
    public adGpoService: AdGpoService,
    public adGroupsService: AdGroupsService,
    public vulnerabilityTimeSeriesService: VulnerabilityTimeseriesService,
    public vulnerabilityService: VulnerabilityService,
    public assetCredentialsService: AssetCredentialsService,
    public complianceService: ComplianceService,
    public assetBestPracticesService: AssetBestPracticesService,
    public assetFirewallPolicyService: AssetFirewallPolicyService,
    public portsService: PortsService,
    public assetSharesService: AssetSharesService,
    public assetUsersService: AssetUsersService,
    public unquotedserviceService: UnquotedserviceService,
    public alertsService: AlertsService,
    public userPermissionsService: UserPermissionsService,
    public applicationBaselineService: ApplicationBaselineService,
    public eventsService: EventsService,
    public installedPService: InstalledProgramService,
    public complianceChecksService: ComplianceChecksService,
    public customGeneratedReportsService: CustomGeneratedReportsService,
    public customReportBuilderService: CustomReportBuilderService,
    public extensionService: ExtensionService,
    public aDroleService: ADroleService,
    public aDuserdataService: ADuserdataService,
    public aDusergroupsService: ADusergroupsService,
    public azureADcomputersService: AzureADcomputersService,
    public azureadErrorService: AzureadErrorService,
    public certificateService: CertificateService,
    public aDlogsService: ADlogsService,
    public pdScanSettingsService: PdScanSettingsService,
    public edrService:EdrService,
  ) { }
}