import {Component, ElementRef, Input, OnInit, Renderer2, ViewChild} from '@angular/core';
import {LoaderService} from '../../_services/loader.service';
import {MyToastrService} from '../../_services/toastr.service';
import {CompanyService} from '../../api/services/company.service';
import {CompanySharedService} from '../../_services/company-shared.service';
import { BaseRequestService } from 'src/app/_services/base.service';

@Component({
  selector: 'app-standard-reports',
  templateUrl: './standard-reports.component.html',
  styleUrls: ['./standard-reports.component.scss']
})
export class StandardReportsComponent implements OnInit {
  @ViewChild('search') searchInput: ElementRef;
  @Input() currentCompany: any;
  allReports: any = [];
  removable = true;
  selectable = true;
  noFilters = false;
  masterReports: any = [];
  operators: any = [{
    key: "=",
    title: "equals",
    tag: ""
  },
  {
    key: "!=",
    title: "notEquals",
    tag: ""
  },
  {
    key: ":",
    title: "contains",
    tag: ""
  },
  {
    key: "!:",
    title: "doesNotContain",
    tag: ""
  }
  
  ];
  Objectkeys = Object.keys;
  tagsList: any = ["one", "two"];
  selectedFilter: any = '';
  filteredList: any =  [];
  companyTags: any = {};
  searchFilter = '';
  reportFilters: any = '';
  showSearch = true;
  showList = 0;
  filterRequest: any = {};
  @ViewChild('auto', {static: false}) auto!: ElementRef;
  constructor( private renderer: Renderer2,
    private loaderService: LoaderService, private toast: MyToastrService, public baseService: BaseRequestService,
    private companyService: CompanyService, private cs: CompanySharedService,
  ) {
    cs.reportFilterUpdate.subscribe((value: any) => {
      this.searchFilter = value;
    });
  }

  ngOnInit(): void {
    this.getReportList();
    this.getReportFilters();
  }

  handleChangeFilter(data: any): void {
    if(!data.target?.value.length && this.showList === 0){ this.showList = 1; this.showSearch = false;};
    if(data.target?.value.length === 0 && this.showList === 0) { this.showList = 0; this.showSearch = true;};
  }

  handledFilterKey(item: any): void {
    this.reportFilters = item;
    this.selectedFilter = item;
    this.showList = 2;
  }

  handleChangeOperator(oper: any): void {
    if(oper === ':' || oper === '!:'){
      this.showList = 4;
      setTimeout(() => this.renderer.selectRootElement('#search').focus(), 0);
    } else {
      this.tagsList = this.companyTags[this.reportFilters];
      this.showList = 3;
    }
    this.reportFilters = `${this.reportFilters}${oper}`;
  }

  handleClickValue(val?:any): void {
    if(val){
      this.filteredList.push(`${this.reportFilters}${val}`);
    } else {
      let data = this.reportFilters.indexOf('!:')> -1 ? this.reportFilters.split('!:') : this.reportFilters.split(':');
      if(!data[1] || data[1] === '' || data[1] === ' ' || data[1] === '  '){
        this.toast.sToast('error', 'Please enter a value');
        return;
      }else {
        this.filteredList.push(`${this.reportFilters}`);
      }
      
    }
    
    this.reportFilters = '';
    this.showList = 0;
    this.showSearch = true;
  }

  removeFilter(filter: any): void{
    const index = this.filteredList.indexOf(filter);
    if (index >= 0) {
      this.filteredList.splice(index, 1);
    }
  }

  getReportFilters(): void {
    this.baseService.doRequest('/api/standardreports/getReportFilters', 'post', {companyid: [this.currentCompany._id]}).subscribe((res: any) => {
      if (res ) {
        if(res) { this.companyTags = res;  this.noFilters = false;}
        else {this.noFilters = true;};
      };
    });
  }

  getReportList(): void {
    this.loaderService.display(true, 'Getting reports list');
    this.companyService.companygetReportListApiCompanyIdGetReportListPost({
      id: this.currentCompany._id, body: {}
    }).subscribe((result: any) => {
      this.loaderService.display(false);
      result.sort((a: any, b: any) => {
        const c = a.Section;
        const d = b.Section;
        if (c < d) { return -1; } else if (c > d) { return 1; } else { return 0; }
      });
      result.forEach((obj: any) => {
        obj.Reports.sort((a: any, b: any) => {
          const c = a.title;
          const d = b.title;
          if (c < d) { return -1; } else if (c > d) { return 1; } else { return 0; }
        });
      });
      this.masterReports = result;
      this.allReports = result;
    });
  }

  downloadReport(report: any): void {

    const filteredTags: any = {
      equals: {},
      contains: {},
      notEquals: {},
      doesNotContain: {}
    };
    this.filteredList.map((list:any) => {
      let data : any = [];
      if(list.indexOf('!=') > -1) {
        data = list.split('!=');
        if(!filteredTags.notEquals[data[0]]){ filteredTags.notEquals[data[0]] = [] };
        filteredTags.notEquals[data[0]].push(data[1])
      } else if (list.indexOf('!:') > -1) {
        data = list.split('!:');
        if(!filteredTags.doesNotContain[data[0]]){ filteredTags.doesNotContain[data[0]] = [] };
        filteredTags.doesNotContain[data[0]].push(data[1])
      } else if (list.indexOf('=') > -1) {
        data = list.split('=');
        if(!filteredTags.equals[data[0]]){ filteredTags.equals[data[0]] = [] };
        filteredTags.equals[data[0]].push(data[1])
      } else if (list.indexOf(':') > -1) {
        data = list.split(':');
        if(!filteredTags.contains[data[0]]){ filteredTags.contains[data[0]] = [] };
        filteredTags.contains[data[0]].push(data[1])
      }
    })
    this.loaderService.display(true, 'Generating report... ');

    const resData: any = {};
    resData.companyid = this.currentCompany._id;
    resData.reportid = report.id;
    (this.filteredList.length) ? resData.filters = JSON.stringify(filteredTags) : ""
    this.companyService.companygenerateReportApiCompanyIdGenerateReportPost(
      {
        id: this.currentCompany._id, body: resData
      }).subscribe((result: any) => {
      this.loaderService.display(false);
      if (result[0]){
        window.open(`${result[1]}`, '_blank');
      } else {
        this.toast.sToast('error', result[1]);
      }
    });

    
  }
}
