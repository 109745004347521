<mat-toolbar class="pl-2 position-absolute mat-elevation-z0 app-header-bar toolbar-bottom">
  <img class="logo-img" alt="CyberCNS" [style.height.px]="logoHeight"
       routerLink="/companies" aria-label="CyberCNS" [src]="logo.src" />
  <span class="w-40">&nbsp;</span>
  <!--suppress TypeScriptUnresolvedVariable -->

  <mat-form-field class="w-25 company-selector"
                  *ngIf="(baseService.showCompany || baseService.showAssessment) && authService.hasPermission('company', 'read') ">
    <mat-select #companySelect id="SearchCompanyInput" class="mat-small round-select" placeholder="" (openedChange)="closeCurrentCompany($event)"
                [formControl]="companyCtrl" (selectionChange)="updateCurrentCompany($event.value)">
      <mat-option>
        <ngx-mat-select-search [formControl]="companyFilterCtrl" [placeholderLabel]="searchTxt"
          [noEntriesFoundLabel]="'No matching company found'" [searching]="searching"></ngx-mat-select-search>
      </mat-option> <!--|ellipsis: 60 -->
      <mat-option [matTooltip]="company.name" *ngFor="let company of filteredCompanies | async" [id]="company.name" [value]="company._id"> {{company.name }}
        <span *ngIf="company.score"> - </span>
        <span *ngIf="company.score" matTooltip="Avg. Vulnerability Score"
              class="mat-small scritical-line nbadge float-right"> {{company.score| number:'1.0-0'}}</span>
        <span class="float-right small fw2" *ngIf="showTimeAgo && !company.score"> {{company.c + 'Z' | timeAgo}} </span>
      </mat-option>
    </mat-select>
  </mat-form-field>
  <div class="d-flex align-items-center ml-2" *ngIf="baseService.showCompany && commonService.currentCompany && commonService.currentCompany.customerInfo">
    <div class="d-flex flex-column justify-content-start">
      <span class="mat-small fw2" *ngIf=commonService.currentCompany.customerInfo.name>
        {{commonService.currentCompany.customerInfo.name}}
         <span *ngIf="commonService.currentCompany.customerInfo.uniqueIdentifier" class="text-muted">
        ({{commonService.currentCompany.customerInfo.uniqueIdentifier}})</span>
      </span>
      <span class="mat-small fw2" *ngIf="commonService.currentCompany.customerInfo.address.city && commonService.currentCompany.customerInfo.address.state ">
        {{commonService.currentCompany.customerInfo.address.city}}, {{commonService.currentCompany.customerInfo.address.state}}
      </span>
    </div>
  </div>
  <!--<button *ngIf="showSearch" (click)="toggleSearch()" mat-icon-button="">
    <mat-icon class="mat-24">search</mat-icon></button>
  <button *ngIf="!showSearch" (click)="toggleSearch()" mat-icon-button="">
    <mat-icon class="mat-24">arrow_back</mat-icon>
  </button>
  <mat-form-field class="w-50 px-2 font-size-medium" *ngIf="!showSearch">
    <mat-label>Search</mat-label>
    <input matInput type="text" trim="blur" name="globalSearch" [(ngModel)]="searchGlobal"
           (ngModelChange)="cs.searchFilter.next($event)"
    >
    <button mat-button *ngIf="searchGlobal" matSuffix mat-icon-button aria-label="Clear"
            (click)="searchGlobal=''">
      <mat-icon>close</mat-icon>
    </button>
    <button mat-button *ngIf="!searchGlobal" matSuffix mat-icon-button aria-label="Search">
      <mat-icon>search</mat-icon>
    </button>
  </mat-form-field>-->

  <span class="nav-spacer"></span>
  <!-- <mat-toolbar *ngIf="showAlert && ccveList && ccveList.length" class="w-400px menu-container-view mat-elevation-z0 app-header-bar toolbar-bottom abanner ">
  <div>
    <span>
      <img src="/assets/images/notification/alarm.gif" alt="Alert" class="alarm-icon">
    </span>
    <marquee onmouseover="this.stop();" onmouseout="this.start();" (click)="searchCVE()" class="l-h-1 marq text-white fs1 w-73p">
      A critical vulnerability identified as
      <span *ngFor="let ccve of ccveList;"> {{ccve.title}} ({{ccve.cve}}),</span>
      which is currently being exploited. </marquee>
    <button  mat-icon-button class="alarm-info" (click)="searchCVE()">
      <mat-icon class="text-white">info_outline</mat-icon>
    </button>
    <button  mat-icon-button class="full-size" (click)="showBanner=true;">
      <mat-icon class="text-white">fullscreen</mat-icon>
    </button>
    <button  mat-icon-button (click)="showAlert=false;" class="migBanner mb2p" id="closed" color="primary">
      <mat-icon class="c_btn">close</mat-icon>
    </button>
  </div>
  </mat-toolbar> -->
  <span class="menu-container-view">
    <button class="mr10" [ngClass]="{'active': rlag.isActive}"
            *ngIf="authService.currentUser && authService.currentUser.permissions && (authService.currentUser.permissions.includes === '' || authService.currentUser.permissions.includes === '*') && (authService.currentUser.permissions.excludes === '' || authService.currentUser.permissions.excludes === '*')"
            matTooltip="Global View - A view of consolidated information across all companies; Global level settings"  mat-icon-button>
      <a routerLink="/global" routerLinkActive="sactive" #rlag="routerLinkActive" [ngClass]="{'text-white': rlag.isActive}">
            <mat-icon id="GlobalSettings" class="mat-24 mt-2">language</mat-icon>
      </a>
    </button>
    <button class="mr10" [ngClass]="{'active': rlac.isActive}"
            matTooltip="Company View - Detailed information of a specific company; Company level settings"  mat-icon-button>
      <a routerLink="/companies" routerLinkActive="sactive" #rlac="routerLinkActive" [ngClass]="{'text-white': rlac.isActive}">
            <mat-icon class="mat-24 mt-2">domain</mat-icon></a>
    </button>
    <button class="mr10" [ngClass]="{'active': rlaa.isActive}"
            matTooltip="Assessment View - Detailed information of a specific assessment;"  mat-icon-button>
      <a routerLink="/assessments/assessment-company" routerLinkActive="sactive" #rlaa="routerLinkActive" [ngClass]="{'text-white': rlaa.isActive}">
            <mat-icon class="mat-24 mt-2">assessment</mat-icon></a>
    </button>
    <button class="mr10" [ngClass]="{'active': rlat.isActive}"
    matTooltip="Toolkit - Firewall Scan, External Scan"  mat-icon-button>
      <a routerLink="/toolkit" routerLinkActive="sactive" #rlat="routerLinkActive" [ngClass]="{'text-white': rlat.isActive}">
            <mat-icon class="mat-24 mt-2">handyman</mat-icon></a>
    </button>
  </span>

  <button *ngIf="commonService && authService && authService.isAuthenticated
   && (domain === 'beta.mycybercns.com' || domain === 'try.mycybercns.com')" matTooltip="Create MSP"
          [matMenuTriggerFor]="createMSPMenu" #createMSPMenuTrigger="matMenuTrigger" (click)="mspDomain = '';"
          color="primary" class="mr10" mat-icon-button>
    <mat-icon>domain</mat-icon>
  </button>
  <mat-menu #createMSPMenu="matMenu" [hasBackdrop]="false">
    <mat-card class="filter-card" (click)="$event.stopPropagation();" (keydown)="$event.stopPropagation()">
      <mat-card-header><mat-card-title>Create MSP</mat-card-title></mat-card-header>
      <mat-card-content>
        <form #credsForm="ngForm" role="form">
          <mat-form-field class="w-100 mb-5">
          <mat-label>MSP Domain</mat-label>
          <span class="top-0" matPrefix>beta</span>
          <input matInput placeholder="Enter domain name" color="primary" type="text" minlength="3" trim="blur" required name="mspDomain"
                 [(ngModel)]="mspDomain" autocomplete="off">
          <mat-hint class="lh-15">Ex. In <span class="t-c">https://mspdomain.com</span> enter only <span class="tprime">mspdomain</span></mat-hint>
        </mat-form-field>
          <mat-form-field class="w-100 mb-5">
          <mat-label>MSP User Email</mat-label>
          <input matInput placeholder="Enter emil name" color="primary" type="email" minlength="3" trim="blur" required name="mspEmail"
                 [(ngModel)]="mspEmail" autocomplete="off">
          <mat-hint class="lh-15">Ex. user@mspdomain.com</mat-hint>
        </mat-form-field>
        <button [disabled]="!credsForm.form.valid || isLoading" [ngClass]="{'blink': isLoading}" color="primary" mat-raised-button=""
          (click)="createMSP()"> Create </button>
          <button class="ml-2" (click)="createMSPMenuTrigger.closeMenu()" mat-stroked-button="">Close</button>
        </form>
      </mat-card-content>
    </mat-card>
  </mat-menu>
  <!--<button *ngIf="commonService && authService && authService.isAuthenticated" matTooltip="Trial Period"
          color="primary" class="mr10" mat-icon-button (click)="trialPeriod()">
    <mat-icon>timelapse</mat-icon>
  </button>-->
  <button *ngIf="commonService && authService && authService.isAuthenticated && authService.hasPermission('cyberutils', 'getpartnerinsightsdata')" matTooltip="Insights"
          color="primary" class="mr10" mat-icon-button (click)="partnerOverview()">
    <mat-icon>insights</mat-icon>
  </button>
  <button *ngIf="commonService && authService && authService.isAuthenticated" matTooltip="Getting Started"
          color="primary" class="mr10" mat-icon-button (click)="gettingStartedOpen()">
    <mat-icon>info</mat-icon>
  </button>
  <button *ngIf="commonService && authService && authService.isAuthenticated" matTooltip="API Documentation"
          color="primary" class="mr10" mat-icon-button (click)="apiLink()">
    <mat-icon>integration_instructions</mat-icon>
  </button>
  <button *ngIf="commonService && authService && authService.isAuthenticated" matTooltip="Search CVE from Master database"
          color="primary" class="mr10" mat-icon-button (click)="searchCVE()">
    <mat-icon>policy</mat-icon>
  </button>
  <div class="d-flex align-items-center">
    <div class="mr-1 mt-1 pointer" [matMenuTriggerFor]="profile" matTooltip="View Build Info, Vuls Feed Info">
      <mat-icon  color="primary"  class="mt-1 material-icons mini-avatar">account_circle</mat-icon>
    </div>
    <div class="d-flex flex-column justify-content-start">
      <span class="mat-small fw2" *ngIf="authService && authService.currentUser">Welcome,
        {{authService.currentUser.given_name | camelToHuman:true}}</span>
      <span class="mat-small fw2"  *ngIf="authService && authService.currentUser">
        {{authService.currentUser.email}}
      </span>
    </div>
  </div>
  <mat-menu #profile="matMenu" [overlapTrigger]="false">
    <!--<a mat-menu-item routerLink="/settings" color="primary"> <mat-icon>settings</mat-icon> <span>Settings</span>
    </a>-->
    <!--<span class="mat-menu-item">{{authService.currentUser.role|uppercase}}</span>-->
    <a mat-menu-item (click)="getBuild()" color="primary">
      <mat-icon>info</mat-icon> <span>Build Info</span>
    </a>
    <a mat-menu-item (click)="checkReleaseNotes(true)" color="primary">
      <mat-icon>new_releases</mat-icon> <span>Release Notes</span>
    </a>
    <a matTooltip="Vulnerability Feed Sync Information" color="primary"
       mat-menu-item href="javascript:" (click)="getVulsSyncInfo()">
      <mat-icon>security</mat-icon> <span>Vuls Feed Info</span>
    </a>
    <a *ngIf="baseService.helpLinks && baseService.helpLinks['cyberCNS']" matTooltip="Help Link" color="primary"
       mat-menu-item [href]="baseService.helpLinks['cyberCNS']" target="_blank">
      <mat-icon>help</mat-icon> <span>Documentation</span>
    </a>
    <div mat-menu-item class="visible-sm" (click)="onChangePassword()">
      <mat-icon id="logoutbtn">lock</mat-icon> <span>Change Password</span>
    </div>
    <div class="dmode" mat-menu-item>
      <mat-icon>brightness_medium</mat-icon>
      <span>Darkmode</span>
      <mat-slide-toggle [(ngModel)]="_themingService.changeMode.tableId" (change)="_themingService.changeTheme($event)" color="primary" class="dmode ml-1" size="small">
      </mat-slide-toggle>
    </div>
    <a mat-menu-item class="visible-sm" href="javascript:" (click)="onLogOut()" color="primary">
      <mat-icon id="logoutbtn">exit_to_app</mat-icon> <span>Logout</span>
    </a>
    <!--<a matTooltip="Capture the current screen and report a bug" href="javascript:"
       mat-menu-item (click)="captureCurrentScreen()">
      <mat-icon>add_photo_alternate</mat-icon> <span>Report an Issue</span>
    </a>-->
  </mat-menu>
  <button  mat-icon-button (click)="onLogOut()" id="logoutbtn1" color="primary">
    <mat-icon>exit_to_app</mat-icon>
  </button>
</mat-toolbar>

<mat-drawer-container class="base-container t-48">
  <!--<mat-drawer mode="side" opened disableClose position="start" class="fixed-bar">
    <mat-list dense class="pt-0 fnav-list">
      <mat-list-item class="icon-bar" [ngClass]="{'active': rlac.isActive}" matTooltip="Continuous Scanning">
        <a routerLink="/companies" routerLinkActive="sactive" #rlac="routerLinkActive">
          <mat-icon class="mat-24">policy</mat-icon></a>
      </mat-list-item>
      &lt;!&ndash;<mat-list-item class="icon-bar" [ngClass]="{'active': rlaa.isActive}" matTooltip="Assessment" [disabled]="true">
        <a routerLink="/assessment" routerLinkActive="sactive" #rlaa="routerLinkActive">
          <mat-icon class="mat-24 pointer">fact_check</mat-icon></a>
      </mat-list-item>&ndash;&gt;
      <mat-list-item class="icon-bar" [ngClass]="{'active': rlat.isActive}"
                     matTooltip="Toolkit - Firewall Scan, External Scan">
        <a routerLink="/toolkit" routerLinkActive="sactive" #rlat="routerLinkActive">
          <mat-icon class="mat-24 pointer">handyman</mat-icon></a>
      </mat-list-item>
      <mat-list-item class="icon-bar" [ngClass]="{'active': rlag.isActive}" matTooltip="Global Settings">
        <a routerLink="/global" routerLinkActive="sactive" #rlag="routerLinkActive">
          <mat-icon id="GlobalSettings" class="mat-24 pointer">settings_applications</mat-icon></a>
      </mat-list-item>
    </mat-list>
  </mat-drawer>-->
  <mat-drawer-content class="main-drawer-content">
    <router-outlet>
      <div *ngIf="loading$ | async " class="loader-class custom-class">
        <img src="/assets/images/loading.gif" width="30" alt="">
        <p *ngIf="!contentText || contentText === ''">Loading... </p>
        <p class="m-0">{{contentText}}</p>
      </div>
      <div *ngIf="authService.sessionTimeout$ | async " class="loader-class custom-class p-3">
        <span class="mb-3 mt-3">Session about to expire in {{authService.timeoutSec}}s. Do you want to continue current session?</span>
        <div class="d-flex flex-row align-items-center">
          <span class="mr-2"><button (click)="authService.resetSession()" mat-raised-button color="primary">Yes</button></span>
          <span><button (click)="authService.logout()" mat-stroked-button color="warn">Logout</button></span>
        </div>
      </div>
    </router-outlet>
    <mat-sidenav-container class="cve-container" (backdropClick)="closeSideNav('backdrop')">
      <mat-sidenav class="sideNavCss"
                   [ngClass]="{'trans-none': sideNavView === 'cveSearch', 'gtrans-none': sideNavView === 'gettingStarted'}"
                   position="end" #sidenav (keydown.escape)="closeSideNav('escape')" disableClose>
        <ng-container *ngIf="sideNavView === 'cveSearch'">
          <mat-card class="p-1 mat-elevation-z0">
          <mat-card-header class="bb-1 br-4 px-1 py-2">
            <button class="position-absolute r-1p" mat-icon-button aria-label="close modal icon" matTooltip="Close"
                  (click)="closeSideNav('toggle button');"><mat-icon>close</mat-icon>
            </button>
            <form #cveFrm="ngForm" *ngIf="!ccveList || !ccveList.length">
              <mat-form-field class="px-1" floatLabel="never" matTooltip="Search Common Vulnerabilities and Exposures">
                <span matPrefix>CVE-</span>
                <input autofocus type="text" trim="blur"  [(ngModel)]="cveid" #cveInput required matInput
                       pattern="((1999|2\d{3})-(0\d{2}[1-9]|[1-9]\d{3,}))" placeholder="YEAR-NNNNNN" name="cveid"
                       autocomplete="off">
                <button type="button" [disabled]="!cveFrm.form.valid"  (click)="searchCVEDetail(cveid)"
                        aria-label="Search CVE" color="primary" matSuffix mat-icon-button>
                  <mat-icon>search</mat-icon>
                </button>
                <mat-hint>Ex. 2021-21166</mat-hint>
              </mat-form-field>
              <button (click)="searchCVEDetail(cveid)" [disabled]="!cveFrm.form.valid" mat-stroked-button=""
                      color="primary">Search</button>
            </form>
            <form #cveFrm="ngForm" *ngIf="ccveList && ccveList.length">
              <mat-form-field class="px-1" floatLabel="never" matTooltip="Select Critical Vulnerability CVE">
                <span matPrefix>CVE-</span>
                <mat-select name="cveid" [(ngModel)]="cveid" placeholder="" (ngModelChange)="updateSelectedCVE($event)">
                  <mat-option *ngFor="let c of ccveList;" [value]="c.cve">{{c.cve}}</mat-option>
                </mat-select>
              </mat-form-field>
            </form>
          </mat-card-header>
          <mat-toolbar *ngIf="!showAlert && ccveList.length && ccve" class="pl-2 w-100 app-header-bar toolbar-bottom abanner">
            <div class="w-100">
              <span>
                <img src="/assets/images/notification/alarm.gif" alt="Alert" class="alarm-icon">
              </span>
              <marquee onmouseover="this.stop();" onmouseout="this.start();" (click)="searchCVE()" class="l-h-1 text-white fs1 w-95p">
                A critical vulnerability identified as  {{ccve.title}} ({{ccve.cve}}),
                which is currently being exploited. </marquee>
            </div>
          </mat-toolbar>
        </mat-card>
          <mat-card *ngIf="ccve" class="mb-5">
          <div class="mat-title">
            <a href="https://nvd.nist.gov/vuln/detail/{{ccve.cve}}" target="_blank">
              <span>{{ccve.cve}}</span>
            </a>
          </div>
          <div class="main-section mt-2">
            <p class="pb-2">{{ccve.title}}</p>
            <mat-list dense class="w-100 bor-1">
              <mat-list-item class="bb-1" *ngIf="ccve.epss_score && ccve.epss_score > 0">
                <span class="w-25">EPSS Score:</span>
                <span class="badge px-1 py-1 scritical" *ngIf="ccve.epss_score >= 0.95">{{ccve.epss_score}}</span>
                <span class="badge px-1 py-1 shigh" *ngIf="ccve.epss_score >= 0.90 && ccve.epss_score <= 0.94">{{ccve.epss_score}}</span>
                <span class="badge px-1 py-1 smedium" *ngIf="ccve.epss_score >= 0.85 && ccve.epss_score <= 0.89">{{ccve.epss_score}}</span>
                <span class="badge px-1 py-1 slow" *ngIf="ccve.epss_score >= 0.00 && ccve.epss_score <= 0.84">{{ccve.epss_score}}</span>
              </mat-list-item>
              <mat-list-item class="bb-1">
                <span class="w-25">Severity:</span>
                <span class="badge px-1 py-1 shigh" *ngIf="ccve.severity.toUpperCase()==='HIGH'">{{ccve.severity}}</span>
                <span class="badge px-1 py-1 smedium" *ngIf="ccve.severity.toUpperCase()==='MEDIUM'">{{ccve.severity}}</span>
                <span class="badge px-1 py-1 scritical" *ngIf="ccve.severity.toUpperCase()==='CRITICAL'">{{ccve.severity}}</span>
                <span class="badge px-1 py-1 slow" *ngIf="ccve.severity.toUpperCase()==='LOW'">{{ccve.severity}}</span>
              </mat-list-item>
              <mat-list-item class="bb-1">
                <span class="w-25 float-left">Base Score:</span>
                <span class="badge px-1 py-1 scritical">{{ccve.baseScore}}</span>
              </mat-list-item>
              <mat-list-item class="bb-1">
                <span class="w-25 float-left">Exploitability Score:</span>
                <span class="badge px-1 py-1 scritical">{{ccve.exploitableScore}}</span>
              </mat-list-item>
              <mat-list-item>
                <span class="w-25 float-left">Impact Score:</span>
                <span class="badge px-1 py-1 scritical">{{ccve.impactScore}}</span>
              </mat-list-item>
            </mat-list>
          </div>
          <div class="m-h-400 mt-3 overflow-auto" *ngIf="!baseService.showCompany">
            <table class="infoTable w-100">
              <thead>
              <tr>
                <th>Company Name - {{ccve.companies_count}}</th>
                <th>Affected Asset(s) - {{ccve.assets_count}}</th>
              </tr>
              </thead>
              <tbody>
              <ng-container *ngFor="let key of Objectkeys(ccve.companyRef); let i = index;">
                <tr>
                  <td>{{key}}</td>
                  <td>
                    <span class="badge pointer text-primary px-2 py-1" (click)="ccve.companyRef[key].visible = true;">
                      {{ccve.companyRef[key].assets.length}}
                    </span>
                  </td>
                </tr>
                <tr *ngIf="ccve.companyRef[key].visible">
                  <td colspan="5">
                    <mat-card>
                      <button class="float-right mt--10" mat-icon-button aria-label="close modal icon"
                              matTooltip="Close" (click)="ccve.companyRef[key].visible = !ccve.companyRef[key].visible">
                        <mat-icon>close</mat-icon>
                      </button>
                      <mat-card-header>
                        <mat-card-title class="fs1 fw2">Asset Details</mat-card-title>
                      </mat-card-header>
                      <mat-card-content class="overflow-auto max-height-200">
                        <mat-list dense class="w-100 bor-1">
                          <mat-list-item class="bb-1">
                            <span class="fs1 fw5 w-50">Asset Name</span>
                            <span class="fs1 fw5 w-50">Last Vulnerability Scan Time</span>
                          </mat-list-item>
                          <mat-list-item [ngClass]="{'bb-1': i !== ccve.companyRef[key].assets.length - 1}"
                                         *ngFor="let asset of ccve.companyRef[key].assets; let i = index">
                            <span class="pointer text-primary fs1 fw5 w-50" (click)="navToAsset(key, asset.id)">{{asset.name}}</span>
                            <span class="text-success fs1 fw5 w-50" *ngIf="asset.lastdiscoveredtime">{{asset.lastdiscoveredtime | utcToLocale}}</span>
                            <span class="text-success fs1 fw5 w-50" *ngIf="!asset.lastdiscoveredtime">-</span>
                          </mat-list-item>
                          <mat-list-item *ngIf="!ccve.companyRef[key].assets || !ccve.companyRef[key].assets.length">
                           <i class="fa fa-check-circle text-success fa-lg mr-1"></i>  All good, no assets affected.
                          </mat-list-item>
                        </mat-list>
                      </mat-card-content>
                    </mat-card>
                  </td>
                </tr>
              </ng-container>
              </tbody>
            </table>
          </div>
          <div class="main-section mt-3" *ngIf="baseService.showCompany">
            <h3 class="mb-1">Affected Assets <span *ngIf="ccve.assets && ccve.assets.length">({{ccve.assets.length}})</span></h3>
            <mat-list dense class="w-100 bor-1">
              <mat-list-item class="bb-1">
                <span class="fs1 fw5 w-50">Asset Name</span>
                <span class="fs1 fw5 w-50">Last Vulnerability Scan Time</span>
              </mat-list-item>
              <mat-list-item [ngClass]="{'bb-1': i !== ccve.assets.length - 1}"
                             *ngFor="let asset of ccve.assets; let i = index">
                <span class="pointer text-primary fs1 fw5 w-50" (click)="navToAsset(commonService.currentCompany.name, asset.id)">{{asset.name}}</span>
                <span class="text-success fs1 fw5 w-50" *ngIf="asset.lastdiscoveredtime">{{asset.lastdiscoveredtime | utcToLocale}}</span>
                <span class="text-success fs1 fw5 w-50" *ngIf="!asset.lastdiscoveredtime">-</span>
              </mat-list-item>
              <mat-list-item *ngIf="!ccve.assets || !ccve.assets.length">
               <i class="fa fa-check-circle text-success fa-lg mr-1"></i>  All good, no assets affected.
              </mat-list-item>
            </mat-list>
          </div>
        </mat-card>
        </ng-container>
        <ng-container *ngIf="sideNavView === 'partnerOverview'">
          <mat-card class="mat-elevation-z0">
            <mat-card-header>
              <button class="position-absolute r-1p mt--10" mat-icon-button aria-label="close modal icon" matTooltip="Close"
                      (click)="closeSideNav('toggle button');">
                <mat-icon>close</mat-icon>
              </button>
              <mat-card-title>Insights</mat-card-title>
            </mat-card-header>
            <mat-card-content class="mt-2 pb-6">
              <app-insights (closeEmitter)="closeSideNav('toggle button')"></app-insights>
            </mat-card-content>
          </mat-card>
        </ng-container>
        <ng-container *ngIf="sideNavView === 'gettingStarted'">
          <mat-card class="mat-elevation-z0">
            <mat-card-header>
              <button class="position-absolute r-1p mt--10" mat-icon-button aria-label="close modal icon" matTooltip="Close"
                      (click)="closeSideNav('toggle button');">
                <mat-icon>close</mat-icon>
              </button>
              <mat-card-title>Getting Started</mat-card-title>
              <mat-checkbox class="position-absolute r-10p mt--20" [(ngModel)]="isHidden"
              (change)="gettingStartedtoggle(isHidden)"
              [checked]="isHidden"
              >Do not show</mat-checkbox>
            </mat-card-header>
            <mat-card-content class="mt-2 pb-6">
              <app-getting-started></app-getting-started>
            </mat-card-content>
          </mat-card>
        </ng-container>
      </mat-sidenav>
    </mat-sidenav-container>
  </mat-drawer-content>
</mat-drawer-container>
<mat-toolbar *ngIf="showAlert && showBanner && ccveList && ccveList.length" class="pl-2 position-absolute menu-container-view mat-elevation-z0 app-bottom-bar toolbar-bottom abanner w-60p" [ngClass]="{'banner_width': menuHide}">
  <div class="w-90">
    <span>
      <img src="/assets/images/notification/alarm.gif" alt="Alert" class="alarm-icon">
    </span>
    <marquee onmouseover="this.stop();" onmouseout="this.start();" (click)="searchCVE()" class="l-h-1 marq text-white fs1 w-100">
      A critical vulnerability identified as
      <span *ngFor="let ccve of ccveList;"> {{ccve.title}} ({{ccve.cve}}),</span>
      which is currently being exploited. </marquee>
    <div class="d-flex flex-column align-items-center">
      <button  mat-icon-button class="full-size-exit" (click)="searchCVE()">
        <mat-icon class="text-white">info_outline</mat-icon>
      </button>
      <!-- <button  mat-icon-button class="full-size-exit" (click)="showBanner=false;">
        <mat-icon class="text-white">fullscreen_exit</mat-icon>
      </button> -->
      <button  mat-icon-button (click)="showAlert=false;" class="migBanner mb2p" id="closed" color="primary">
        <mat-icon class="c_btn">close</mat-icon>
      </button>
    </div>
  </div>
</mat-toolbar>
<s-modal id="build">
  <mat-card class="modal w-100">
    <button class="float-right" mat-icon-button aria-label="close modal icon" matTooltip="Close"
      (click)="modalService.close('build');">
      <mat-icon>close</mat-icon>
    </button>
    <span class="mat-title">Build Information</span>
    <div class="card-body p--5">
      <div class="infoccns mb-3 mt-3 w-100">
        <span style="color:red">Note:<br>
        <span style="color:black">Please keep Port 22 open for <a href="https://github.com" target="_blank">https://github.com</a> in your CyberCNS server, for the Backend or UI build to be updated to the latest versions.</span></span>
      </div>
    </div>
    <mat-card-content class="">
      <div class="mt20" fxLayout="row wrap">
        <div fxFlex="100" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100" *ngIf="build" class="mg5" fxLayout="column">
          <h3 class="mb10">Backend
            <span *ngIf="updateStatus && updateStatus.backend"><i class="fa fa-download fa-lg text-primary"></i> Updates available.</span>
            <span *ngIf="updateStatus && !updateStatus.backend"><i class="fa fa-check-circle fa-lg t-g"></i> Up to date.</span>
          </h3>
          <mat-list dense class="mat-small w-100 bor-1" *ngIf="Objectkeys(build.backend).length">
            <mat-list-item class="bb-1" *ngFor="let item of Objectkeys(build.backend)">
              <span class="w-30p t-l ">{{item | camelToHuman:true}}:</span>
              <span class="fw3" *ngIf="item === 'commitTime' || item === 'buildTime'">{{build.backend[item] | epochToDate}}</span>
              <span class="fw3" *ngIf="item !== 'commitTime' && item !== 'buildTime'">{{build.backend[item]}}</span>
            </mat-list-item>
          </mat-list>
          <h3 class="mb10 mt-3">UI -
            <span *ngIf="updateStatus && updateStatus.ui"><i class="fa fa-download fa-lg text-primary"></i> Updates available.</span>
            <span *ngIf="updateStatus && !updateStatus.ui"><i class="fa fa-check-circle fa-lg t-g"></i> Up to date.</span>
          </h3>
          <mat-list dense class="mat-small w-100 bor-1" *ngIf="Objectkeys(build.ui).length">
            <mat-list-item class="bb-1" *ngFor="let item of Objectkeys(build.ui)">
               <span class="w-30p t-l">{{item | camelToHuman:true}}:</span>
                <span class="fw3" *ngIf="item === 'commitTime' || item === 'buildTime'">{{build.ui[item] | epochToDate}}</span>
                <span class="fw3" *ngIf="item !== 'commitTime' && item !== 'buildTime'">{{build.ui[item]}}</span>
            </mat-list-item>
          </mat-list>
          <hr>
          <h3 class="mb10 font-weight-bold">Agent Version:  {{build.agent.agentVersion}}</h3>
          <div class="row" *ngFor="let item of Objectkeys(build.agent)">
            <div class="col-lg-12" *ngIf="item !== 'agentVersion'">
              <p class="mb10 font-weight-bold">{{item| camelToHuman:true}}</p>
              <ng-container *ngFor="let aitem of Objectkeys(build.agent[item])">
                <div class="flex flex-row align-items-center mb-3 w-100">
                  <span class="w-30p">{{aitem | camelToHuman:true}}:</span>
                  <span class="w-70p ml10 overflow-auto">
                    <mat-list dense class="mat-small w-100 bor-1">
                      <mat-list-item class="bb-1" *ngFor="let hash of Objectkeys(build.agent[item][aitem])">
                         <span class="w-30p t-l">{{hash | uppercase}}:</span>
                          <span class="fw3 ofw-anywhere">{{build.agent[item][aitem][hash]}}</span>
                      </mat-list-item>
                    </mat-list>
                  </span>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-lg-12 ">
          <a type="button" mat-stroked-button class="mat-small mr-2" color="primary"
             href="https://cybercns.atlassian.net/wiki/spaces/Verison2/pages/1947861114/CyberCNS+V2+Release+Notes+-2023" target="_blank">View Release Notes</a>
          <button *ngIf="updateStatus && (updateStatus.ui || updateStatus.backend)"
            (click)="installUpdate()" class="mat-small ml-2" mat-raised-button="" color="primary">Install Patch</button>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</s-modal>
<s-modal id="releaseNotes">
  <mat-card class="modal w500">
    <button class="float-right" mat-icon-button aria-label="close modal icon" matTooltip="Close"
      (click)="modalService.close('releaseNotes');">
      <mat-icon>close</mat-icon>
    </button>
    <span class="mat-title text-primary">What's new ?</span>
    <mat-card-content class="">
      <div class="row">
        <div class="col-lg-12 text-center">
          <img src="/assets/images/releasenotes.png" alt="Release Notes" width="200px">
        </div>
      </div>
      <div class="mt20" fxLayout="row wrap" *ngIf="releaseNotes && releaseNotes.release_notes">
        <div fxFlex="100" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100" class="mg5" fxLayout="column">
          <span class="font-small mb5" *ngFor="let item of releaseNotes.release_notes; let i = index;">
            <span class="text-primary fw300 mr10">{{i+1}}.</span>
            <span class="fw300">{{item}}</span>
          </span>
        </div>
      </div>
    </mat-card-content>
    <mat-card-footer class="text-center mb20 pb20">
      <button mat-stroked-button color="primary" (click)="readWhatsNew()">Got it</button>
    </mat-card-footer>
  </mat-card>
</s-modal>
<s-modal id="feedInfo">
  <mat-card class="modal w500">
    <button class="float-right" mat-icon-button aria-label="close modal icon" matTooltip="Close"
      (click)="modalService.close('feedInfo');">
      <mat-icon>close</mat-icon>
    </button>
    <span class="mat-title">Vulnerability Live Feed Sync Information</span>
    <mat-card-content class="">
      <div class="mt20" fxLayout="row wrap">
        <mat-list dense class="w-100 bor-1">
          <mat-list-item class="bb-1" *ngFor="let item of feeds">
            <span class="w-30p t-l" [matTooltip]="item.title">{{item.name}}:</span>
            <span [matTooltip]="item.title" class="fw3">{{item.date | epochToDate}}</span>
          </mat-list-item>
        </mat-list>
      </div>
    </mat-card-content>
  </mat-card>
</s-modal>
<s-modal id="trialPeriod" [@zoomInOnEnter]>
  <form #trialPeriodForm="ngForm">
    <mat-card class="d-flex flex-row is-align-items-flex-start pl-0 py-0" [style.width.px]="trialPeriodWidth">
      <div class="d-flex flex-row align-items-center list-right list-right active">
        <div class="col-lg-12 text-center">
          <circle-progress
                [percent]="100"
                [radius]="100"
                [maxPercent]="100"
                [titleColor]="'#444444'"
                [title]="'14 Days Left'"
                [showTitle]="true"
                [showUnits]="false"
                [showSubtitle]="false"
                [outerStrokeWidth]="16"
                [innerStrokeWidth]="2"
                [backgroundColor]="'#F1F1F1'"
                [outerStrokeColor]="'#32CD32'"
                [innerStrokeColor]="'#FF6347'"
                [animation]="true"
                [animationDuration]="300"
              ></circle-progress>
        </div>
      </div>
      <mat-card class="modal mat-elevation-z0 pr-0 w-100">
        <button class="float-right" mat-icon-button aria-label="close modal icon" matTooltip="Close"
                (click)="modalService.close('trialPeriod');">
          <mat-icon>close</mat-icon>
        </button>
        <div class="mat-title">Trial Information</div>
        <mat-card-content class="" *ngIf="trial && trial.action">
          <mat-radio-group [(ngModel)]="trial.action" (change)="updateTrialForm(trial.action)"
                           name="installationMode" aria-label="Choose the below option to proceed" required>
            <mat-radio-button color="primary" value="buyNow" class="mr-4">Buy Now</mat-radio-button>
            <mat-radio-button color="primary" value="cancelNow">Cancel Now</mat-radio-button>
          </mat-radio-group>
          <br><br>
          <mat-form-field class="w-100 mb-2" appearance="outline">
            <mat-label>Leave a comment</mat-label>
            <textarea matInput name="comment" [(ngModel)]="trial.comment" rows="5"
                      placeholder="Ex. It makes me feel..."></textarea>
          </mat-form-field>
        </mat-card-content>
        <mat-card-footer class="mat-card-footer text-right pt-3 pb-3 pr-3 toolbar-top" *ngIf="trial">
          <button mat-raised-button class=" mr-2"
                  [ngClass]="{'mat-warn': trial.action === 'cancelNow', 'mat-primary': trial.action === 'buyNow'}"
                  (click)="updateTrial()" id="updateTrial">
            <span>{{trial.button}}</span>
          </button>
        </mat-card-footer>
      </mat-card>
    </mat-card>
  </form>
</s-modal>

<s-modal id="releaseNotesModal">
  <mat-card class="modal pl-0 pr-0" style="width: 100%">
  <button class="close__btn float-right position-absolute r-1p" mat-mini-fab aria-label="close modal icon" matTooltip="Close"
    (click)="modalService.close('releaseNotesModal');" id="closeButton">
    <mat-icon class="mat-close-button">close</mat-icon>
  </button>
  <mat-card-content class="mat-dialog-content">
    <div class="lg:p-5 sm:p-0">
      <div class="max-w-full m-auto lg:max-w-4xl bg-gray-100 md:max-w-full relative overflow-hidden">
        <div class="bg-blue-700 lg:h-64 px-8 py-8 md:h-64 text-left sm:h-56">
          <div class="hidden w-80 lg:block overflow-hidden inline-block absolute -right-16 -top-32">
            <div class="h-96 bg-gray-100 rotate-45 transform origin-bottom-left"></div>
          </div>
          <h1 class="text-4xl tracking-tight lg:mt-20 font-extrabold md:mt-16 md:text-6xl text-white sm:text-4xl sm:mt-0">
            <span class="block xl:inline align-text-top">{{patchingDetails.title}}</span>
          </h1>
        </div>
        <div class="p-10">
          <p class="mb-5 text-gray-800 text-lg dsply__flex">
            <span class="flex-none uppercase bg-gray-200 text-white text-xs tracking-wide font-semibold px-2 py-1 mr-2 rounded-full bg-gradient-to-r from-cyan-500 to-blue-500">
              {{patchingDetails.type}}
            </span>
            <span class="d-block">{{patchingDetails.date}}</span>
          </p>
          <div *ngFor="let list of patchingDetails.sections">
            <div *ngIf="list.type === 'p'">
              <h3 class="text-black font-bold text-3xl mb-2 mt-5">{{list.title}}</h3>
              <div *ngFor="let item of list.description">
                <p class="text-base text-gray-800 sm:mb-3 sm:text-lg md:mb-3 md:text-lg lg:mx-0">{{item}}</p>
              </div>
            </div>
            <div *ngIf="list.type === 'list'">
              <p class="text-base text-gray-800 mt-4 sm:mb-3 sm:text-lg font-semibold md:mb-3 md:text-lg lg:mx-0">{{list.title}}:</p>
              <div class="m-5">
                <ul class="w-full rounded-lg mt-2 mb-8 text-blue-800" *ngFor="let data of list.description">
                  <li class="mb-1">
                    <div class="w-fill flex">
                      <svg xmlns="http://www.w3.org/2000/svg" class="flex-none  h-6 w-6 mt-1" fill="#00ba00" viewBox="0 0 24 24" stroke="#ffffff">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                      <span class="text-base ml-2 text-gray-800 sm:text-lg md:text-lg">{{data}}. </span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-lg-12 ">
              <a type="button" mat-stroked-button class="mat-small mr-2" color="primary"
                 href="https://cybercns.atlassian.net/wiki/spaces/Verison2/pages/1947861114/CyberCNS+V2+Release+Notes+-2023" target="_blank">View All Release Notes</a>
            </div>
          </div>
        </div>
        <mat-card-footer class="text-center mb-2 mb20 pb20">
          <button mat-stroked-button color="primary" (click)="modalService.close('releaseNotesModal');">Got it</button>
        </mat-card-footer>
      </div>
    </div>
    </mat-card-content>

    </mat-card>
</s-modal>
<s-modal id="migrationCompletedModal">
  <mat-card class="modal w-100">
    <button class="float-right" mat-icon-button aria-label="close modal icon" matTooltip="Close"
      (click)="modalService.close('migrationCompletedModal');">
      <mat-icon>close</mat-icon>
    </button>
    <span class="mat-title">Migration Completed</span>
    <mat-card-content class="">
      <div class="flex flex-col align-items-center m-h-400">
        <div class="flex flex-row align-items-center p-4">
          <img src="assets/images/migration-completed.png" alt="Migration Completed" class="w-full rounded-lg">
        </div>
        <div>
          <p class="migrationText text-lg mb-4">
            Your instance is now successfully migrated to the new architecture. You can now log in to CyberCNS using your existing username and password.
          </p>
          <button mat-stroked-button color="primary" (click)="redirectMigration()"><span class="migrationText">Access Your Account</span></button>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</s-modal>
<s-modal id="migrationModal">
  <mat-card class="modal pl-0 pr-0" style="width: 100%">
  <button class="close__btn float-right position-absolute r-1p" mat-mini-fab aria-label="close modal icon" matTooltip="Close"
    (click)="modalService.close('migrationModal');" id="closeButton">
    <mat-icon class="mat-close-button">close</mat-icon>
  </button>
  <mat-card-content class="mat-dialog-content">
    <div class="lg:p-5 sm:p-0">
      <div class="max-w-full m-auto lg:max-w-4xl bg-gray-100 md:max-w-full relative overflow-hidden">
        <div class="bg-blue-700 lg:h-64 px-8 py-8 md:h-64 text-left sm:h-56">
          <div class="hidden w-80 lg:block overflow-hidden inline-block absolute -right-16 -top-32">
            <div class="h-96 bg-gray-100 rotate-45 transform origin-bottom-left"></div>
          </div>
          <h1 class="text-4xl tracking-tight lg:mt-20 font-extrabold md:mt-16 text-white text-xl sm:mt-0">
            <span class="block xl:inline align-text-top">Migrate to CyberCNS, Unified Portal Experience
            </span>
          </h1>
        </div>
        <div class="p-10 text-black">
          <p class="mb-2 text-gray-800 text-lg dsply__flex">
            <span class="d-block">Thank you for choosing to migrate</span>
          </p>
          <div class="m-2">
            <ul class="w-full rounded-lg mt-2 mb-3 text-blue-800" *ngFor="let data of migrateDetails">
              <li class="mb-0">
                <div class="w-fill flex">
                  <svg xmlns="http://www.w3.org/2000/svg" class="flex-none  h-6 w-6 mt-1" fill="#00ba00" viewBox="0 0 24 24" stroke="#ffffff">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                  <span class="text-base ml-2 text-gray-800 sm:text-lg md:text-lg">{{data}}. </span>
                </div>
              </li>
            </ul>
          </div>
          <div class="row mt-3">
            <div class="col-lg-12 ">
              <mat-checkbox class="col-lg-12 text-black " [(ngModel)]="migrateAgree" name="agree">I agree</mat-checkbox>
              <button mat-raised-button color="primary" class="mt-4 ml-3"
              (click)="startMigrate()" [disabled]="!migrateAgree">Migrate</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    </mat-card-content>

    </mat-card>
</s-modal>
<s-modal id="changePassword">
  <form #passwordForm="ngForm">
    <mat-card class="d-flex flex-row is-align-items-flex-start py-0" >
      <div class="d-flex flex-row align-items-center list-right list-right">
        <div class="col-lg-12 text-center">
          <img src="/assets/images/password.png" class="img-width" alt="Change Password">
          <p class="ibtn">Update Password here</p>
        </div>
      </div>
      <mat-card class="modal mat-elevation-z0 pr-0 w-100">
        <button class="float-right" mat-icon-button aria-label="close modal icon" matTooltip="Close"
                (click)="modalService.close('changePassword');passwordForm.resetForm()">
          <mat-icon>close</mat-icon>
        </button>
        <div class="mat-title">Update Password</div>
        <mat-card-content class="py-2 px-3">
          <ng-container>
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>Old Password</mat-label>
              <input matInput id="oldPassword" placeholder="Enter old password" #passwordoldField type="password"
                required name="oldPassword" [(ngModel)]="changePasswordData.oldPassword" autocomplete="old-password">
              <button mat-icon-button type="button" color="primary"
                (click)="passwordoldField.type === 'password' ? passwordoldField.type = 'text' : passwordoldField.type = 'password'"
                matSuffix>
                <mat-icon class="icon-size-5" *ngIf="passwordoldField.type === 'password'">visibility
                </mat-icon>
                <mat-icon class="icon-size-5" *ngIf="passwordoldField.type === 'text'">visibility_off
                </mat-icon>
              </button>
              <mat-error>
                Password is required
              </mat-error>
            </mat-form-field>
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>New Password</mat-label>
              <input matInput id="newPassword" placeholder="Enter new password" #passwordNewField type="password"
                required name="newPassword" [(ngModel)]="changePasswordData.password" autocomplete="new-password">
              <button mat-icon-button type="button" color="primary"
                (click)="passwordNewField.type === 'password' ? passwordNewField.type = 'text' : passwordNewField.type = 'password'"
                matSuffix>
                <mat-icon class="icon-size-5" *ngIf="passwordNewField.type === 'password'">visibility
                </mat-icon>
                <mat-icon class="icon-size-5" *ngIf="passwordNewField.type === 'text'">visibility_off
                </mat-icon>
              </button>
              <mat-error>
                Password is required
              </mat-error>
            </mat-form-field>
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>Confirm Password</mat-label>
              <input matInput id="confirmPassword" placeholder="Enter confirm password" #passwordCField type="password"
                required name="confirmPassword" [(ngModel)]="changePasswordData.confirmPassword" autocomplete="new-password">
              <button mat-icon-button type="button" color="primary"
                (click)="passwordCField.type === 'password' ? passwordCField.type = 'text' : passwordCField.type = 'password'"
                matSuffix>
                <mat-icon class="icon-size-5" *ngIf="passwordCField.type === 'password'">visibility
                </mat-icon>
                <mat-icon class="icon-size-5" *ngIf="passwordCField.type === 'text'">visibility_off
                </mat-icon>
              </button>
              <mat-error>
                Confirm Password is required
              </mat-error>
            </mat-form-field>
            <mat-form-field *ngIf="enableMfa" class="w-100" appearance="outline">
              <mat-label>MFA</mat-label>
              <input matInput id="mfa" placeholder="Enter  MFA" type="text"
                required name="mfa" [(ngModel)]="changePasswordData.totp" autocomplete="off">
              <mat-error>
                MFA is required
            </mat-error>
            </mat-form-field>
          </ng-container>
        </mat-card-content>
        <mat-card-footer class="mat-card-footer text-right pt-3 pb-3 pr-3 toolbar-top">
          <button mat-raised-button class="mat-primary mr-2"
            (click)="changeUserPassword(passwordForm)" [disabled]="!passwordForm.form.valid || isLoading" id="ve">Submit
          </button>
          <button mat-stroked-button class="mat-warn" (click)="modalService.close('changePassword');passwordForm.resetForm()" id="cancel11">
            Cancel
          </button>
        </mat-card-footer>
      </mat-card>
    </mat-card>
  </form>
</s-modal>
