<app-s-table *ngIf="agentsView === 'agents'" aria-label="Agents" role="table" [sTableOptions]="agenTableOptions"
             (addCallback)="addAgent()" (refreshCallback)="getAgents()" (actionCallback)="allActioncall($event)"
             (filterCallback)="filterCall($event)" (colFilterCallback)="colFilterCall($event)" (sortCallback)="sortCall($event)"
             (pageCallback)="agentsPagecallback($event)" (globalActionCallback)="globalActionCall($event)">
</app-s-table>
<mat-card *ngIf="agentsView == 'Discovery Settings'">
  <button class="float-right" mat-icon-button aria-label="close icon" matTooltip="Close" (click)="agentsView = 'agents'">
    <mat-icon>close</mat-icon>
  </button>
  <mat-card-header>
    <mat-card-title>{{this.currentAgent.name}} - Discovery Settings</mat-card-title>
    <mat-card-subtitle>{{this.currentAgent.agent_type}} - IP Ranges, SNMP, Active Directory and Master Credentials</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content *ngIf="currentCompany">
    <app-discovery-settings [currentAgent]="currentAgent" [currentCompany]="currentCompany"></app-discovery-settings>
  </mat-card-content>
  <mat-card-actions align="start">
    <button mat-button (click)="agentsView = 'agents'">Close</button>
  </mat-card-actions>
</mat-card>
<s-modal id="addAgent" *ngIf="agentsView == 'agents'">
  <form #addA="ngForm">
    <mat-card class="d-flex flex-row is-align-items-flex-start p-0">
      <div class="d-flex flex-row align-items-center list-right list-right w-25">
        <div class="col-lg-12 text-center">
          <img src="/assets/images/dk/023-drawkit-folder-man-colour.svg" alt="Add Agent" width="200px">
          <p class="mat-body-1">Add an agent text <strong>goes</strong> here</p>
        </div>
      </div>
      <mat-card class="modal mat-elevation-z0 pr-0" [style.width.px]="addAgentWidth">
        <button class="float-right" mat-icon-button aria-label="close modal icon" matTooltip="Close"
                (click)="modalService.close('addAgent');">
          <mat-icon>close</mat-icon>
        </button>
        <div class="mat-title">Add agent</div>
        <mat-card-content class="">
          <mat-form-field class="w-75 mb-2" appearance="outline">
            <mat-label>Name</mat-label>
            <input matInput placeholder="Enter agent name" type="text" trim="blur" required name="agentname"
                   [(ngModel)]="agent.name"
                   autocomplete="off">
            <mat-hint>Ex. Southern avenue agent</mat-hint>
          </mat-form-field>
        </mat-card-content>
        <mat-card-footer class="mat-card-footer text-right pt-3 pb-3 toolbar-top">
          <button mat-raised-button class="mat-primary mr-2"
                  (click)="saveAgent()" [disabled]="!addA.form.valid || isLoading"
                  id="save1">Save
          </button>
          <button mat-stroked-button class="mat-warn" (click)="modalService.close('addAgent')"
                  id="cancel1">Cancel
          </button>
        </mat-card-footer>
      </mat-card>
    </mat-card>
  </form>
</s-modal>
