<mat-card class="mb-2 light-theme ibtn" *ngIf="!vulnerabilityOverviewData || !vulnerabilityOverviewData.length">
  <mat-card-header>
    <h3 class="mb-2 mat-card-title">Vulnerability Overview</h3>
  </mat-card-header>
  <mat-card-content>
    <p  class="ml-2 mt-2"> No Vulnerabilities found!</p>
  </mat-card-content>
</mat-card>
<mat-card class="mb-2 light-theme ibtn" *ngIf="vulnerabilityOverviewData && vulnerabilityOverviewData.length && !currentVStat">
  <mat-card-header>
    <h3 class="mb-2">Vulnerability Overview</h3>
  </mat-card-header>
  <mat-card-content>
    <app-sa-table [showHeaderUpperCase]="showUpper" [title]="'Vulnerabilities by Operating System'"
                  (refreshCallback)="getVulnerabilityOverview()"
                  (hyperlinkCallback)="getVulnerabilityProductOverview($event)"
                  [customCols]="vulnerabilityOverviewCustomCols"
                  [columnsList]="vulnerabilityOverviewCols"
                  [dataList]="vulnerabilityOverviewData"
                  [pageSize]= "pageSize"
                  >
    </app-sa-table>
    <p class="py-2" *ngIf="!vulnerabilityOverviewData || !vulnerabilityOverviewData.length" class="text-center"> No data available. </p>
  </mat-card-content>
</mat-card>
<mat-sidenav-container (backdropClick)="close('backdrop')">
  <mat-sidenav-content>
    <mat-card class="h-100vh" *ngIf="vulnerabilityOverviewCustomCols && vulnerabilityOverviewCustomCols.length && currentVStat">
      <button class="float-right" mat-icon-button aria-label="close modal icon" matTooltip="Close" (click)="closeView();">
        <mat-icon>close</mat-icon> </button>
      <mat-form-field class="position-absolute r-5p mat-small fw3 w-25 float-right mr-2">
        <mat-label>Severity</mat-label>
        <mat-select [multiple]="false" name="severity" (ngModelChange)="updateSeverityData($event)"
          placeholder="Choose Severity" [(ngModel)]="currentVStat.severity">
          <mat-option *ngFor="let item of vulnerabilityOverviewCustomCols;" [value]="item.col"> {{item.col}} </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-card-header>
        <mat-card-title>{{currentVStat.os}}</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="d-flex flex-row">
          <span class="w-30p mt-2">
            <mat-list dense class=" bor-1">
              <mat-list-item class="" [ngClass]="{'bb-1': prodList && prodList.length }">
                <span class="">Product Name</span>
                <span class="spacer"></span>
                <span class="">Vulnerabilities</span>
              </mat-list-item>
              <mat-list-item class="bb-1 pointer " [ngClass]="{'active ': currentProduct === prod}"
                *ngFor="let prod of prodList; let last = last;" (click)="getVulnerabilities(prod)">
                <span>{{prod}}</span>
                <span class="spacer"></span>
                <span class="badge badge-primary fs--8">{{vulnerabilityProductOverviewData[prod]}}</span>
              </mat-list-item>
            </mat-list>
          </span>
          <span class="ml-2 w-70p mt-2" *ngIf="vulnerabilityListData && vulnerabilityListData.length">
            <app-sa-table class="w-100" [showHeaderUpperCase]="showUpper" [title]="'Vulnerabilities'"
                      (refreshCallback)="getVulnerabilities(currentProduct)"
                      (hyperlinkCallback)="getCompanyAssetOverview($event)"
                      [customCols]="vulnerabilityListCustomCols"
                      [columnsList]="vulnerabilityListCols"
                      [dataList]="vulnerabilityListData">
            </app-sa-table>
          </span>
        </div>
      </mat-card-content>
    </mat-card>
  </mat-sidenav-content>
  <mat-sidenav #snav [mode]="'over'" fixedTopGap="56" position="end"  class="w-70p z-index-150">
    <mat-card class="w-100" *ngIf="currentCVE">
      <button class="float-right r-1p mt--10" mat-icon-button aria-label="close modal icon" matTooltip="Close"
              (click)="snav.close();">
        <mat-icon>close</mat-icon>
      </button>
      <mat-card-header>
        <mat-card-title class="fs1 fw2 mr-4"> {{currentCVE.CVEID}} - {{currentProduct}}</mat-card-title>
      </mat-card-header>
      <mat-card-content class="overflow-auto">
        <div class="main-section mt-2">
          <p class="pb-2">{{currentCVE.title}}</p>
          <mat-list dense class="w-100 bor-1">
            <mat-list-item class="bb-1">
              <span class="w-25">Severity:</span>
              <span class="badge px-1 py-1 shigh" *ngIf="currentCVE.severity.toUpperCase()==='HIGH'">{{currentCVE.severity|uppercase}}</span>
              <span class="badge px-1 py-1 smedium" *ngIf="currentCVE.severity.toUpperCase()==='MEDIUM'">{{currentCVE.severity|uppercase}}</span>
              <span class="badge px-1 py-1 scritical" *ngIf="currentCVE.severity.toUpperCase()==='CRITICAL'">{{currentCVE.severity|uppercase}}</span>
              <span class="badge px-1 py-1 slow" *ngIf="currentCVE.severity.toUpperCase()==='LOW'">{{currentCVE.severity|uppercase}}</span>
            </mat-list-item>
            <mat-list-item class="bb-1">
              <span class="w-25 float-left">Base Score:</span>
              <span class="badge px-1 py-1 scritical">{{currentCVE.base_score}}</span>
            </mat-list-item>
            <mat-list-item class="bb-1">
              <span class="w-25 float-left">Exploitability Score:</span>
              <span class="badge px-1 py-1 scritical">{{currentCVE.exploit_score}}</span>
            </mat-list-item>
            <mat-list-item>
              <span class="w-25 float-left">Impact Score:</span>
              <span class="badge px-1 py-1 scritical">{{currentCVE.impact_score}}</span>
            </mat-list-item>
          </mat-list>
        </div>
        <div *ngFor="let d of currentCVE.data" class="mb-3">
          <p class="font-weight-light mt-4 mb-1">{{d.companyRef.name}}'s Affected Asset(s) ({{d.assets.length}})</p>
          <mat-list dense class="w-100 bor-1 small">
            <mat-list-item *ngFor="let asset of d.assets; let last = last;" [ngClass]="{'bb-1': !last}"
                           class="">
              <a href="javascript:" class="pointer to-e mb5 mr5" (click)="redirectToAsset(d.companyRef, asset)">{{asset.name}}</a>
            </mat-list-item>
            <mat-list-item *ngIf="!d.assets || !d.assets.length">
             <i class="fa fa-check-circle text-success fa-lg mr-1"></i>  All good, no assets affected.
            </mat-list-item>
          </mat-list>
        </div>
      </mat-card-content>
    </mat-card>
  </mat-sidenav>
</mat-sidenav-container>

