import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {ModalService} from '../../_services/modal.service';
import {LoaderService} from '../../_services/loader.service';
import {CommonService} from '../../_services/common.services';
import {BaseRequestService} from '../../_services/base.service';
import {IntegrationActionsService} from '../../_services/integration-actions.service';
import {MyToastrService} from '../../_services/toastr.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';

@Component({
  selector: 'app-integration-action',
  templateUrl: './integration-action.component.html',
  styleUrls: ['./integration-action.component.scss']
})

export class IntegrationActionComponent implements OnInit, OnChanges, OnDestroy {
  @Input() inputActionData: any;
  @Input() productData?:any;
  localActionData: any;
  subscribedKeys: any = {};
  integrationActionsWidth: any = 800;
  credListData: any = [];
  sView = 'getIntegrations';
  integration: any = {actionData: {}};
  integrationHash: any = [];
  integrationMappingHash: any = [];
  formElements: any = [];
  actionParams: any;
  currentActions: any;
  Objectkeys = Object.keys;
  actionParamsList: any;
  actionTemplate: any;
  defaultActionData: any;
  disabledSubmit = false;
  constructor(public modalService: ModalService, private loaderService: LoaderService,  private aS: AuthenticationService,
              private integrationActionsService: IntegrationActionsService, private toast: MyToastrService,
              private baseService: BaseRequestService, private commonService: CommonService, ) {
    this.subscribedKeys.integAction = integrationActionsService.integrationActionPopup.subscribe((res: any) => {
      if (res.value) {
        setTimeout(() => {
          this.getAllIntegrations();
        });
      }
    });
    this.subscribedKeys.integValidation = integrationActionsService.integrationValidationEVE.subscribe((res: any) => {
      this.disabledSubmit = res;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    /*this.inputActionData = changes.inputActionData.currentValue;
    if (this.inputActionData) {
      this.inputActionData = this.inputActionData;
    }*/
  }

  ngOnInit(): void {
    this.localActionData = {...this.inputActionData};
  }

  onDismiss(): void {
    this.commonService.rmRefreshEvent.next({});
    this.modalService.close('integrationActions');
    this.integrationActionsService.integrationActionShow = false;
    this.integrationActionsService.integrationAssetActionShow = false;
  }

  getAllIntegrations(): void {
    this.loaderService.Modeldisplay(true);
    this.baseService.doRequest(`/api/integrations/`, 'get').subscribe((result: any) => {
      this.loaderService.Modeldisplay(false);
      result.forEach((obj: any) => {
        this.integrationHash[obj.name] = obj;
      });
      this.getIntegrationMapping(true);
    });
  }

  getIntegrationMapping(isInit?: boolean): void {
    this.loaderService.Modeldisplay(true, 'Getting mapped integrations...');
    const query = {
      query: {bool: {must: [{match: {'sourceCompanyId.keyword': this.commonService.currentCompany._id + ''}}]}}
    };
    const q = JSON.stringify(query); const skip = 0; const limit = 100;
    this.baseService.doRequest(`/api/integrations/companyMapping`,
      'get', null, {q, skip, limit}).subscribe((result: any) => {
      this.loaderService.Modeldisplay(false);
      if (result.total === 0 && isInit) {
        this.toast.sToast('error', 'No integration mapped to this company. ');
        this.modalService.close('integrationActions'); return;
      }
      this.credListData = [];
      result.data.forEach((obj: any) => {
        const integration = (this.integrationHash[obj.integrationName]) ? this.integrationHash[obj.integrationName] : {};
        if (integration.notificationfunction) {
          this.integrationMappingHash[obj.credentialId] = obj;
          this.credListData.push(obj);
        }
      });
      if(!this.credListData || !this.credListData.length) {
        this.toast.sToast('error', 'No integration mapped to this company. ');
        this.modalService.close('integrationActions'); return;
      }else {
        this.integration.credentialId = this.credListData[0]?.credentialId;
        this.getActions(this.credListData[0]?.credentialId)
      }
      this.localActionData = {...this.inputActionData};
      if (isInit) { this.modalService.open('integrationActions'); }
    });
  }

  getActions($event: any): void {
    this.loaderService.Modeldisplay(true);
    this.baseService.doRequest(
      `/api/integrations/actions?integrationName=${this.integrationMappingHash[$event].integrationName}`,
      'get').subscribe((result: any) => {
      this.loaderService.Modeldisplay(false);
      this.currentActions = result.actions;
      this.getDefaultActionSettings();
    });
  }

  getAction(): void {
    if(this.actionTemplate === ''){ this.integration.actionData = {}};
    this.getActionParams();
  }

  getDefaultActionSettings(): void {
    this.loaderService.Modeldisplay(true, 'Getting default action settings...');
    this.baseService.doRequest(`/api/integrations/global_action_params?integrationName=${this.integrationMappingHash[this.integration.credentialId].integrationName}&credentialid=${this.integration.credentialId}&actionName=${this.currentActions[0]}`, 'get', ).subscribe((result: any) => {
      this.loaderService.Modeldisplay(false);
      if (result[0]) {
        this.defaultActionData = result[1];
        this.actionTemplate = 'default';
      } else {
        this.defaultActionData = undefined;
        this.actionTemplate = undefined;
        // this.toast.sToast('error', result[1].err);
      }
    });
  }

  getActionParams(): void {
    this.loaderService.Modeldisplay(true);
    this.baseService.doRequest(
      `/api/integrations/action_params?integrationName=${this.integrationMappingHash[this.integration.credentialId].integrationName}&actionName=${this.integration.action}`,
      'get').subscribe((result: any) => {
      this.loaderService.Modeldisplay(false);
      if (this.actionTemplate && this.defaultActionData && this.defaultActionData.params
         && this.Objectkeys(this.defaultActionData.params).length > 0) {
        this.Objectkeys(this.defaultActionData.params).forEach((key: any) => {
          result.parameters.forEach((obj: any) => {
            if (obj.name === key) {
              // result.parameters.splice(result.parameters.indexOf(obj), 1);
            }
          });
        });
      }
      this.actionParams = result;
      this.processFields(result);
      this.sView = 'actionParams';
    });
  }

  processFields(result: any): void {
    // this.formElements = [];
    this.integration.actionData = (this.integration.actionData) ? this.integration.actionData : [];
    const tmpAP: { label: any; key: any; required: any; example?: any; type: any; }[] = [];
    result.parameters.forEach(async (obj: any) => {
      const tmpFormField = (this.formElements) ? this.formElements.filter((x: any) => x.key === obj.name) : [];
      const tFF: any = {label: obj.description, key: obj.name, required: obj.required, example: obj.example, type: obj.schema.type, allcompany: (obj.allcompany) ? obj.allcompany : false};
      tFF.callback = (obj.callback);
      if (obj.source && !obj.depends) {
        if (tmpFormField && tmpFormField.length && tmpFormField[0].options && tmpFormField[0].options.length) {
          tFF.options = tmpFormField[0].options;
        } else {
          this.getSubActionParams(obj.source.name).then(async (subObj: any) => {
            const requestParams: any = {};
            obj.subParams = result;
            if (subObj.parameters) {
              subObj.parameters.forEach((pObj: any) => {
                requestParams[pObj.name] = pObj.example;
              });
            }
            this.loaderService.Modeldisplay(true, `Executing ${subObj.name}`);
            this.executeAction(subObj, requestParams).then((subOut: any) => {
              if (subOut.status === 'failed' && obj.required) {
                this.toast.sToast('error', subOut.data);
              } else {
                this.processParams(subOut.data).then((edata: any) => {
                  this.loaderService.Modeldisplay(false);
                  obj.subOut = edata;
                  tFF.options = [];
                  edata.forEach((val: any) => {
                    tFF.options.push({key: val[subObj.DisplayFields[0]], value: val[obj.source.mappedValue]});
                  });
                });
              }
            });
          });
        }
      } else if (obj.source && obj.depends && this.integration.actionData[obj.depends[0]]) {
        this.getSubActionParams(obj.source.name).then(async (subObj: any) => {
          const requestParams: any = {};
          obj.subParams = result;
          const sources: any = this.integration.actionParams.filter((x: any) => x.source && x.source.mappedValue);
          if (subObj.parameters) {
            subObj.parameters.forEach((pObj: any) => {
              requestParams[pObj.name] = pObj.example;
              // requestParams[pObj.name] = this.integration.actionData[obj.depends[0]]; // ticketId not getting here directly im getting that ticketId
              const mValue = sources.filter((x: any) => x.source.mappedValue === pObj.name);
              if (mValue.length) {
                requestParams[pObj.name] = this.integration.actionData[mValue[0].name];
              }
            });
          }
          // requestParams[obj.source.parameter] = this.integration.actionData[obj.depends[0]];
          this.loaderService.Modeldisplay(true, `Executing ${subObj.name}`);
          this.executeAction(subObj, requestParams).then((subOut: any) => {
            
            if (subOut.status === 'failed' && obj.required) {
                this.toast.sToast('error', subOut.data);
            } 
              this.processParams(subOut.data).then((edata: any) => {
                this.loaderService.Modeldisplay(false);
                obj.subOut = edata;
                tFF.options = [];
                edata.forEach((val: any) => {
                  tFF.options.push({key: val[subObj.DisplayFields[0]], value: val[obj.source.mappedValue]});
                });
              });
            
          });
        });
      }
      tmpAP.push(tFF);
      if (obj.maptokey) {
        this.integration.actionData[obj.name] = this.integrationMappingHash[this.integration.credentialId][obj.maptokey];
      }
      if (this.inputActionData[obj.name] && !this.integration.actionData[obj.name]) {
        this.integration.actionData[obj.name] = this.inputActionData[obj.name];
      } else if (!this.inputActionData[obj.name] && this.integration.actionData[obj.name]) {
        this.integration.actionData[obj.name] = this.integration.actionData[obj.name];
      } else {
        this.integration.actionData[obj.name] = this.localActionData[obj.name];
      }
      if(this.actionTemplate === 'default' && this.actionTemplate !== ""){
        if (this.defaultActionData.params[obj.name]) {
          (!isNaN(this.defaultActionData.params[obj.name])) ? this.integration.actionData[obj.name] = parseInt(this.defaultActionData.params[obj.name]) : null;
        }
      }
      if(obj.name === 'notesType' && this.actionTemplate !== 'default'){
        this.integration.actionData['notesType'] = 1;
      }
      // else {
      //   this.integration.actionData[obj.name] = this.localActionData[obj.name];
      // }
    });
    
    // this.formElements = [];
    // setTimeout(() => { this.formElements = tmpAP.slice(); });
    this.formElements = tmpAP.slice();
    this.integration.actionParams = result.parameters;
  }

  actionChange($event: any): void {
    if ($event && $event !== '') {
      this.processFields(this.actionParams);
    }
  }

  getSubActionParams(actionName: string): Promise<any> {
    return this.baseService.doRequest(
      `/api/integrations/action_params?integrationName=${this.integrationMappingHash[this.integration.credentialId].integrationName}&actionName=${actionName}`, 'get').toPromise();
  }

  executeAction(param: any, requestparams: any, newapi?:any): Promise<any> {
    const params: any = {
      integrationName: this.integrationMappingHash[this.integration.credentialId].integrationName,
      integrationId: this.integration.credentialId,
      params: { action: {name: param.name, destination: param.destination, verb: param.verb}, params: {requestparams} }
    };
    (this.productData && newapi) ? params.product = this.productData : null;
    if (params.integrationName === "Kaseya" && param.name === "getLocations") {
      params.params.params.requestparams["accountId"] = this.integration.actionData.company
    } 
    return (this.productData && newapi) ? this.baseService.doRequest(`/api/remediation/integrationaction`, 'post', params).toPromise() : this.baseService.doRequest(`/api/integrations/executeAction`, 'post', params).toPromise();
  }

  async processParams(data: any): Promise<any> {
    const value: any = [];
    if (data.row_values){
      await data.row_values.forEach((obj: any) => {
        const obj2: any = {};
        data.column_definitions.forEach((obj1: any, index: number) => {
          const key = this.Objectkeys(obj1)[0];
          obj2[key] = obj[index];
        });
        value.push(obj2);
      });
    }
    return value;
  }

  save($event: any): void {

  }

  cancel(): void {

  }

  ngOnDestroy(): void {
    this.Objectkeys(this.subscribedKeys).forEach((obj: string) => {
      this.subscribedKeys[obj].unsubscribe();
    });
  }

  saveAction(): void {
    this.loaderService.Modeldisplay(true);
    const integration = Object.assign(this.integration, {});
    const actionParams = Object.assign(this.actionParams, {});
    // integration.actionData = (this.actionTemplate === 'default')
    //   ? { ...integration.actionData, ...this.defaultActionData.params } : integration.actionData;
    if (this.integrationHash[this.integrationMappingHash[this.integration.credentialId].integrationName].nl2br) {
      integration.actionData.initialDescription = this.commonService.nl2br(integration.actionData.initialDescription);
    }
    this.executeAction(actionParams, integration.actionData, true).then((actionOut: any) => {
      this.loaderService.Modeldisplay(false);
      if (actionOut.status === 'failed') {
        this.toast.sToast('error', actionOut.data);
      } else {
        this.toast.sToast('success', (actionOut.data && actionOut.data.id) ? `Successfully executed. ${actionOut.data.id}. ` : 'Successfully executed.');
        this.onDismiss();
      }
    });
  }
}
