<div *ngIf="!currentIntegration" [@zoomInOnEnter] [ngClass]="{'integration-height': onboarding}">
  <div class="d-flex align-items-center">
    <div class="mat-title fw2" *ngIf="mode !=='company'">Integrations</div>
    <div class="mat-title fw2" *ngIf="mode ==='company'">Firewalls</div>
    <div class="spacer"></div>
    <mat-form-field class="search-tool fs1" floatLabel="never" *ngIf="mode !=='company'">
      <input matInput name="integsearch" [(ngModel)]="integrationSearch" placeholder="Search" autocomplete="off">
      <button mat-button *ngIf="!integrationSearch || integrationSearch === ''" aria-label="Search" matSuffix
        mat-icon-button>
        <mat-icon>search</mat-icon>
      </button>
      <button mat-button *ngIf="integrationSearch" matSuffix mat-icon-button aria-label="Clear"
        (click)="integrationSearch='';">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </div>
  <div class="px-2" *ngFor="let integrationtype of categoryList;">
    <h2>{{integrationtype | camelToHuman: true | uppercase}}</h2>
    <div class="row">
      <ng-container *ngFor="let product of integrations | search: integrationSearch">
        <div *ngIf="product.integrationtype === integrationtype"
             class="col-lg-2 mb-4" [ngClass]="{'col-lg-3': !onboarding, 'col-lg-4': onboarding}">
          <mat-card (click)="setIntegration(product)"
            class=" text-center pull-up pointer mat-elevation-z4 flex-column align-items-center justify-content-center">
            <div class="position-absolute flex flex-row gap-2 mr-1 r-8p"
                 *ngIf="configuratedIntegration?.credentails && configuratedIntegration?.credentails?.indexOf(product.name) !== -1">
                <span matTooltip="configured"><i class="fas fa-check-circle t-g fa-lg"></i></span>
                <span class="ml-2 badge badge-primary badge-round w-20p" matTooltip="No of company mapping">
                  <span
                    *ngIf="configuratedIntegration?.companyMapping[product.name]">{{configuratedIntegration?.companyMapping[product.name]}}</span>
                </span>
            </div>
            <img class="provider-img" src="/assets/images/providers/provider-{{ product.img }}.png">
            <div class="my-3 fs1-2 fw3" id={{product.name}}>{{product.title}}</div>
          </mat-card>
        </div>
      </ng-container>
    </div>
  </div>
</div>
<div *ngIf="currentIntegration" [@zoomInOnEnter]>
  <mat-card>
    <mat-card-header>
      <mat-card-title class="d-flex align-items-center">
        <img class="selected-img" src="/assets/images/providers/provider-{{ currentIntegration.img }}.png">
        <span class="fw2">{{currentIntegration.title}}</span>
        <span class="spacer"></span>
        <button class="position-absolute r-1p" mat-icon-button aria-label="close modal icon" matTooltip="Close"
          (click)="closeInteg();getConfiguratedIntegration();"><mat-icon>close</mat-icon>
        </button>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content class="pt-2">
      <mat-tab-group (selectedIndexChange)="updateSelectionIndex($event)">
        <mat-tab label="Credentials">
          <ng-template mat-tab-label>
            <mat-icon class="mat-24 mr-2">vpn_key</mat-icon>
            <span id="intCredentials"> Credentials </span>
          </ng-template>
          <ng-template matTabContent>
            <span>&nbsp;</span>
            <div class="d-flex w-100 pl-2 align-items-center">
              <span class="fs1 fw2 text-uppercase">Credentials</span>
              <span>
                <button [disabled]="newCred" id="AddIntCredentials" (click)="addCred()" color="primary"
                  mat-icon-button="">
                  <mat-icon class="mat-24">add_circle_outline</mat-icon>
                </button>
              </span>
              <span class="spacer"></span>
              <span class="mr-2 pr-4">
                <button id="defaultSettings" (click)="showDefault()" color="primary" mat-raised-button=""
                  *ngIf="!defaultActionSettings && this.credData._id" matTooltip="Setup default template settings">
                  Default Template
                </button>
                <button id="closeDefaultAction" (click)="closeDefaultAction()" color="primary" mat-icon-button=""
                  *ngIf="defaultActionSettings && this.credData._id" matTooltip="Close default template settings">
                  <mat-icon class="mat-24">close</mat-icon>
                </button>
              </span>
            </div>
            <div class="d-flex align-items-center w-100" *ngIf="newCred">
              <app-dynamic-form class="col-lg-8" (cancelCallBack)="cancel()" (saveCallBack)="save($event)" [mode]="mode"
                [currentCompany]="currentCompany" *ngIf="credData && formElements && formElements.length"
                [listOfFormElements]="formElements" [Valuesoutput]="credData">
              </app-dynamic-form>
            </div>
            <div class="d-flex align-items-top w-100" *ngIf="!newCred">
              <div class="w-25 px-2 light-right">
                <mat-list dense class="bor-1 br-5 w-100">
                  <mat-list-item class="bb-1" [ngClass]="{' active ': cred._id === this.credData._id}"
                    *ngFor="let cred of integrationsData; let i = index;">
                    <span class="pointer" [matTooltip]="cred.name" (click)="setCred(cred)">{{cred.name | camelToHuman:
                      true | ellipsis:28}}</span>
                    <span class="ml-1" matTooltip="Default template enabled" *ngIf="defaultActionSetHash[cred._id]">
                      <i class="fa fa-check-circle text-success" aria-hidden="true"></i>
                    </span>
                    <span class="spacer"></span>
                    <span class="pointer px-1" (click)="delCred(cred)"><mat-icon id="{{cred.name}}"
                        class="mat-24">delete</mat-icon></span>
                  </mat-list-item>
                </mat-list>
              </div>
              <div class="w-75 px-4 mt--20">
                <app-dynamic-form class="col-lg-8" (cancelCallBack)="cancel()" (saveCallBack)="save($event)"
                  [mode]="mode" *ngIf="credData && formElements && formElements.length && !defaultActionSettings"
                  [listOfFormElements]="formElements" [Valuesoutput]="credData"></app-dynamic-form>

                <div>
                  <p class="mb-1 mat-accent fs1 fw3 text-uppercase mt-15" *ngIf="defaultActionSettings">Default Template
                  </p>
                  <app-dynamic-form class="col-lg-8" (cancelCallBack)="closeDefaultAction()"
                    (saveCallBack)="saveDefaultAction($event)"
                    *ngIf="defaultActionIntegration && defaultActionIntegration.actionData
                                   && defaultActionIntegration.formElements && defaultActionIntegration.formElements.length" (valueUpdate)="actionChange($event)"
                    [listOfFormElements]="defaultActionIntegration.formElements"
                    [Valuesoutput]="defaultActionIntegration.actionData">
                  </app-dynamic-form>
                  <p class="fs1 fw3 py-3"
                    *ngIf="defaultActionIntegration && (!defaultActionIntegration.formElements || !defaultActionIntegration.formElements.length)">
                    No default settings available for this integration {{defaultActionIntegration.name}}.</p>
                </div>
              </div>
            </div>
          </ng-template>
        </mat-tab>
        <mat-tab [disabled]="!integrationsData || !integrationsData.length"
          *ngIf="(currentIntegration.companyMappingRequired && currentIntegration.integrationtype === 'psa') || currentIntegration.name === 'Meraki' && (currentIntegration.name !== 'Draytek' && currentIntegration.name !== 'Fortigate' && currentIntegration.name !== 'Watchguard' && currentIntegration.name !== 'Sonicwall' && currentIntegration.name !== 'Sophos')"
          label="Company Mapping">
          <ng-template matTabLabel>
            <mat-icon class="mat-24 mr-2">domain</mat-icon>
            <span id="intCompanyMapping">Company Mapping</span>
          </ng-template>
          <ng-template matTabContent>
            <span>&nbsp;</span>
            <app-company-mapping [integrationsData]="integrationsData" [onboarding]="onboarding"
              (updateCompanyList)="triggerOnboarding()" [currentIntegration]="currentIntegration"></app-company-mapping>
          </ng-template>
        </mat-tab>
        <mat-tab [disabled]="!integrationsData || !integrationsData.length"
          *ngIf="currentIntegration.companyMappingRequired && currentIntegration.integrationtype !== 'psa' && currentIntegration.name !== 'Meraki' && (currentIntegration.name !== 'Draytek' && currentIntegration.name !== 'Fortigate' && currentIntegration.name !== 'Watchguard' && currentIntegration.name !== 'Sonicwall' && currentIntegration.name !== 'Sophos')"
          label="Company Mapping">
          <ng-template matTabLabel>
            <mat-icon class="mat-24 mr-2">domain</mat-icon>
            Company Mapping
          </ng-template>
          <ng-template matTabContent>
            <span>&nbsp;</span>
            <app-nonpsa-company-mapping [integrationsData]="integrationsData" [mode]="mode" [currentCompany]="currentCompany" [onboarding]="onboarding"
              (updateCompanyList)="triggerOnboarding()"
              [currentIntegration]="currentIntegration"></app-nonpsa-company-mapping>
          </ng-template>
        </mat-tab>
        <mat-tab *ngIf="currentIntegration.notificationfunction"
          [disabled]="!integrationsData || !integrationsData.length" label="Default Templates">
          <ng-template matTabLabel>
            <mat-icon class="mat-24 mr-2">backup_table</mat-icon>
            <span id="defaultTemplatesId">Notification Rules</span>
          </ng-template>
          <ng-template matTabContent>
            <span>&nbsp;</span>
            <app-default-templates *ngIf="currentIntegration.integrationtype !== 'psa'"
              [integrationsData]="integrationsData" [onboarding]="onboarding"
              [currentIntegration]="currentIntegration"></app-default-templates>
            <app-default-templates *ngIf="currentIntegration.integrationtype === 'psa'"
              [integrationsData]="integrationsData" [onboarding]="onboarding"
              [currentIntegration]="currentIntegration"></app-default-templates>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>
  </mat-card>
</div>
