<div class="remedia-container">
  <mat-sidenav-container class="remedia-sidenav-container h-100vh">
    <mat-sidenav-content>
      <mat-card>
        <mat-card-content>
          <div class="d-flex flex-row">
            <span class="w-25 mt-2">
              <mat-list dense class="bor-1">
                <mat-list-item class="bb-1">
                  <span class="fs1">Scan Type</span>
                  <span class="spacer"></span>
                  <span class="fs1">Count</span>
                </mat-list-item>
                <mat-list-item class="bb-1 pointer" [ngClass]="{'active ': selectedScanType === scan}"
                  *ngFor="let scan of Objectkeys(scanTypeMapping); let last = last;" (click)="selectedScan(scan)">
                  <span>{{scanTypeMapping[scan].name}}</span>
                  <span class="spacer"></span>
                  <span class="badge badge-primary fs--8">{{scanTypeMapping[scan].value}}</span>
                </mat-list-item>
              </mat-list>
            </span>
            <span class="ml-2 w-75 mt-2" >
              <ng-container *ngIf="loading">
                <mat-card>
                  <mat-card-content>
                    <div class="loader-class">
                      <img src="/assets/images/loading.gif" width="30" alt="Loading">
                      <p>Loading... </p>
                    </div>
                  </mat-card-content>
                </mat-card>
              </ng-container>
              <app-s-table aria-label="Scan Results" role="table" *ngIf="resultTableOption && !loading" [sTableOptions]="resultTableOption"
                (refreshCallback)="refreshCall()" (pageCallback)="pageCall($event)" (hyperlinkCallback)="linkCall($event)"
                (sortCallback)="sortCall($event)" (colFilterCallback)="colFilterCall($event)">
              </app-s-table>
            </span>
          </div>
        </mat-card-content>
      </mat-card>
    </mat-sidenav-content>
    <mat-sidenav #result [mode]="'over'" fixedTopGap="56" position="end" class="w-50 z-index-150">
      <mat-card class="w-100">
        <button class="position-absolute r-1p mt--10" aria-label="close modal icon" matTooltip="Close"
          (click)="result.close();" mat-icon-button="">
          <mat-icon>close</mat-icon>
        </button>
        <mat-card-header>
          <mat-card-title>
            Found In
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="d-flex flex-column">
            <span class="over-flow-wrap"><code>{{currentResult.identifier_type}}</code></span><br>
            <span class="fs--8 fw5">Line Numbers<span class="ml-2 badge badge-primary fs--8 br-8 fw5">{{currentResult.line_numbers.length}}</span></span>
            <span class="ml-2">
              <code>{{currentResult.line_numbers}}</code>
            </span>
          </div>
        </mat-card-content>
      </mat-card>
    </mat-sidenav>
  </mat-sidenav-container>
</div>
