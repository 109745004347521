<mat-card>
  <mat-card-header class=" bb-1">
    <span class="small w-50">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Choose {{currentIntegration.title}} Credential:</mat-label>
        <mat-select [multiple]="false" placeholder="Choose credential" [(ngModel)]="currentCredential"
                    name="currentCred" (ngModelChange)="getIntegrationMapping(); getCompanyActionParams()">
          <mat-option *ngFor="let item of integrationsData" [value]="item._id"> {{item.name}} </mat-option>
        </mat-select>
      </mat-form-field>
    </span>
  </mat-card-header>
  <mat-card-content class="mt-2">
    <div *ngIf="!addEditIntegration">
      <app-s-table aria-label="Integration Mapping" role="table" [sTableOptions]="inteTableOptions"
                   (sortCallback)="intesortCall($event)" (filterCallback)="integmappingfilterCall($event)"
                   (actionCallback)="integmappingactionCall($event)" (pageCallback)="integmappingpageCall($event)"
                   (refreshCallback)="getIntegrationMapping()" (addCallback)="integmappingaddTableData()"
                   (timerCallback)="integmappingtimerCallData()"></app-s-table>
    </div>
    <ng-container *ngIf="addEditIntegration">
      <button class="float-right" mat-icon-button aria-label="close modal icon" matTooltip="Close"
              (click)="addEditIntegration = false;">
        <mat-icon>close</mat-icon>
      </button>
      <h3>New Company Mapping</h3>
      <mat-card *ngIf="cmpView === 'Type'" class="mat-elevation-z0 p-0">
        <mat-card-header>
          <mat-card-title class="fw2"> Would you like to</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <mat-radio-group [(ngModel)]="cmpMapping.selectedType" class="d-flex flex-row align-items-center pt-3">
            <mat-radio-button class="pr-4" value="Import Companies">Import Companies from {{currentIntegration.title}}</mat-radio-button>
            <mat-radio-button [disabled]="onboarding" class="pr-4" value="Map Existing Companies">Map Existing Company to a {{currentIntegration.title}} Company
            </mat-radio-button>
          </mat-radio-group>
        </mat-card-content>
        <mat-card-footer class="p-3 d-flex align-items-end mr-1">
          <button class="mr-2" (click)="addEditIntegration = false;" mat-raised-button="">Cancel</button>
          <button (click)="cmpView = cmpMapping.selectedType; getIntegrationCompanies();" [disabled]="!cmpMapping.selectedType"
                  color="primary" mat-raised-button="">Next</button>
        </mat-card-footer>
      </mat-card>
      <mat-card *ngIf="cmpView === 'Map Existing Companies'" class="mat-elevation-z0 p-0">
        <mat-card-header>
          <mat-card-title class="fw2">Map Existing Company to {{currentIntegration.title}} Company</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="infoccns mb-3" *ngIf="currentIntegration.title ==='Azure AD'">
            <br>
            <p>
             <b>Notes:</b> <br>
              <span class="ml-2 fs--8">
                If a company has already integrated Azure AD Legacy with any tenant, then when you integrate the same company with Azure AD, the old integration mapping for this company is removed.
              </span>
            </p>
          </div>
          <div class="d-flex align-items-center w-100">
            <span class="w-30p small mr-3">
              <div class="d-flex align-items-center">
                <span class="w-100">
                  <mat-form-field class="w-100" appearance="fill" *ngIf="showSelect">
                    <mat-label>Existing Company</mat-label>
                    <mat-select #companySelect class="mat-small round-select" placeholder="" (openedChange)="closeCurrentCompany($event)"
                                [formControl]="companyCtrl" (selectionChange)="updateCurrentCompany($event.value)">
                      <mat-option>
                        <ngx-mat-select-search [formControl]="companyFilterCtrl"  [placeholderLabel]="searchTxt"
                          [noEntriesFoundLabel]="'No matching company found'" [searching]="searching"></ngx-mat-select-search>
                      </mat-option>
                      <mat-option [matTooltip]="company.name" *ngFor="let company of filteredCompanies | async" [value]="company._id">
                        {{company.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field class="w-100" appearance="fill" *ngIf="!showSelect">
                    <mat-label>Existing Company</mat-label>
                    <input matInput placeholder="" type="text" class="mat-small round-select" [(ngModel)]="selectedSourceCompany" readonly
                           autocomplete="off" required name="ExistingCompany">
                  </mat-form-field>
                </span>
              </div>
            </span>
            <span class="w-30p small" *ngIf="currentIntegration.importCompanies">
              <div class="d-flex align-items-center">
                  <span class="w-75">
                    <mat-form-field class="w-100" appearance="fill" *ngIf="companyList && companyList.length">
                      <mat-label>{{currentIntegration.title}} Company</mat-label>
                      <mat-select [multiple]="false" placeholder="Select Companies"
                                  [(ngModel)]="cmpMapping.destinationCompany">
                        <mat-option *ngFor="let item of companyList" [value]="item">
                          <span *ngFor="let field of companyActionParams.DisplayFields" class="pr-2">
                            {{item[field]}}
                          </span>
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </span>
                  <span class="w-25">
                    <button [matMenuTriggerFor]="menuRef" #menuRefMenuTrigger="matMenuTrigger"
                            mat-icon-button="" matTooltip="Search Company">
                      <mat-icon class="mat-24">search</mat-icon></button>
                    <mat-menu #menuRef="matMenu" [hasBackdrop]="false">
                      <div class="flex-column align-items-center p-2 w-100"
                           (click)="$event.stopPropagation();" (keydown)="$event.stopPropagation()">
                        <span class="w-100">
                          <app-dynamic-form [isCloseBtn]="false" [isSaveBtn]="false"
                                            *ngIf="companyParams && companyFormElements && companyFormElements.length"
                                            [listOfFormElements]="companyFormElements" [Valuesoutput]="companyParams">
                          </app-dynamic-form>
                        </span>
                        <span>
                          <button (click)="getIntegrationCompanies(); menuRefMenuTrigger.closeMenu()" mat-stroked-button=""
                                  type="button" color="primary">Get Companies</button>
                        </span>
                      </div>
                    </mat-menu>
                  </span>
              </div>
            </span>
            <span class="w-25 mt--10">
              <button (click)="addToMapList()" matTooltip="Add to mapping list"
                      mat-raised-button="">Add</button>
            </span>
          </div>
          <mat-list dense class="bor-1 w-100" *ngIf="cmpMapping.mapCompanyList && cmpMapping.mapCompanyList.length">
            <mat-list-item class="bb-1" *ngFor="let cmp of cmpMapping.mapCompanyList; let i = index">
              <span class="mr-2"> <i class="fa fa-check-circle t-g"></i> </span>
              <span class="mr-2 w-30p fs1 fw2">Source: {{cmp.source.name}}</span>
              <span class="mr-2 w-30p fs1 fw2" *ngIf="currentIntegration.importCompanies">
                Destination: {{cmp.destination[companyActionParams.DisplayFields[0]]}}</span>
              <span><button (click)="cmpMapping.mapCompanyList.splice(i, 1)" mat-icon-button="">
                <mat-icon class="mat-24">delete</mat-icon></button></span>
            </mat-list-item>
          </mat-list>
           <p *ngIf="cmpMapping.mapCompanyList.length"
              class="mt-3 fs1 fw2">Click finish to map all the selected {{currentIntegration.title}} companies</p>
        </mat-card-content>
        <mat-card-footer class="p-3 d-flex align-items-center mr-1">
          <button class="mr-2" *ngIf="currentIntegration.importCompanies"
                  (click)="cmpView = 'Type'; cmpMapping.selectedCompanies = []; cmpMapping.mapCompanyList = []"
                  mat-stroked-button="">Back</button>
          <button [disabled]="!cmpMapping.mapCompanyList || !cmpMapping.mapCompanyList.length"
                  (click)="mapSelectedCompanies()" mat-raised-button="" color="primary">Finish</button>
        </mat-card-footer>
      </mat-card>
      <mat-card *ngIf="cmpView === 'Import Companies'" class="mat-elevation-z0 p-0">
        <mat-card-header>
          <mat-card-title class="fw2">{{currentIntegration.title}} Companies</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="infoccns mb-3" *ngIf="currentIntegration.title ==='Azure AD'">
            <br>
            <p>
             <b>Notes:</b> <br>
              <span class="ml-2 fs--8">
                If a company has already integrated Azure AD Legacy with any tenant, then when you integrate the same company with Azure AD, the old integration mapping for this company is removed.
              </span>
            </p>
          </div>
          <div class="d-flex align-items-top">
            <div class="w-70p p-2 border-right">
              <mat-form-field class="w-100" appearance="outline" *ngIf="companyList && companyList.length">
                <mat-label>Select Companies</mat-label>
                <mat-select [multiple]="true"  (ngModelChange)="updateSelected($event)"
                            placeholder="Select Companies"
                            [(ngModel)]="selectedCompanyList">
                  <mat-option *ngFor="let item of companyList"
                              [value]="item">
                    <span *ngFor="let field of companyActionParams.DisplayFields" class="pr-2">
                      {{item[field]}}
                    </span>
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-list dense class="bor-1 w-100 max-c-h" *ngIf="cmpMapping.selectedCompanies.length">
                <mat-list-item class="bb-1" *ngFor="let cmp of cmpMapping.selectedCompanies; index as i; trackBy:loaderService.trackByFn">
                  <span>{{cmp.name}}</span><span class="spacer">&nbsp;</span>
                  <span><mat-icon class="mat-24 pointer" matTooltip="Remove Company"
                                  (click)="removeSelected(i)">delete</mat-icon></span>
                </mat-list-item>
              </mat-list>
              <span class="fw2 py-1 float-right" *ngIf="cmpMapping.selectedCompanies.length > 5">
                Total selected companies - {{cmpMapping.selectedCompanies.length}}
              </span>
            </div>
            <div class="flex-column align-items-center p-2 w-30p">
              <span class="w-100">
                <app-dynamic-form [isCloseBtn]="false" [isSaveBtn]="false"
                                  *ngIf="companyParams && companyFormElements && companyFormElements.length"
                                  [listOfFormElements]="companyFormElements" [Valuesoutput]="companyParams">
                </app-dynamic-form>
              </span>
              <span>
                <button (click)="getIntegrationCompanies()" mat-stroked-button="" type="button" color="primary">Get Companies</button>
              </span>
            </div>
          </div>
        </mat-card-content>
        <mat-card-footer class="p-3 d-flex align-items-center mr-1">
          <button class="mr-2" (click)="cmpView = 'Type'; cmpMapping.selectedCompanies = []" mat-stroked-button="">Back</button>
          <button [disabled]="!cmpMapping.selectedCompanies || !cmpMapping.selectedCompanies.length"
                  (click)="cmpView = 'Summary'" mat-raised-button="" color="primary">Next</button>
        </mat-card-footer>
      </mat-card>
      <mat-card *ngIf="cmpView === 'Summary'" class="mat-elevation-z0 p-0">
        <mat-card-header>
          <mat-card-title class="fw2">
            Selected {{currentIntegration.title}} companies ({{cmpMapping.selectedCompanies.length}})
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <mat-list dense class="bor-1 w-50 m-h-400">
            <mat-list-item *ngFor="let cmp of cmpMapping.selectedCompanies; let i = index"
                           [ngClass]="{'bb-1': i < cmpMapping.selectedCompanies.length - 1}">
              <span class="mr-2"> <i class="fa fa-check-circle t-g"></i> </span>
              <span class="mr-1">{{cmp[companyActionParams.DisplayFields[0]]}}</span>
              <ng-container *ngIf="companyActionParams.DisplayFields[1]">
                <span class="mr-1">-</span> <span class="mr-1">{{cmp[companyActionParams.DisplayFields[1]]}}</span>
              </ng-container>
            </mat-list-item>
          </mat-list>
          <p class="mt-3 fs1 fw2">Click <span *ngIf="!onboarding">finish</span>
            <span *ngIf="onboarding">create</span> to import all the selected {{currentIntegration.title}} companies</p>
        </mat-card-content>
        <mat-card-footer class="p-3 d-flex align-items-center mr-1">
          <button class="mr-2" (click)="cmpView = 'Import Companies';"
                  mat-stroked-button="">Back</button>
          <button [disabled]="!cmpMapping.selectedCompanies || !cmpMapping.selectedCompanies.length || disableCreate"
                  (click)="importSelectedCompanies()" mat-raised-button="" color="primary">
            <span *ngIf="!onboarding">Finish</span>
            <span *ngIf="onboarding">Create</span>
          </button>
        </mat-card-footer>
      </mat-card>
    </ng-container>
  </mat-card-content>
</mat-card>

