<div class="remedia-container">
    <mat-sidenav-container class="remedia-sidenav-container h-100vh">
        <mat-sidenav-content>
            <app-s-crud-t (addCallback)="addEditAppBaseline()" (actionCallback)="actionCalback($event)"
                *ngIf="scanProfileColOptions && scanProfileColOptions.columns"
                [tOption]="scanProfileColOptions"></app-s-crud-t>
        </mat-sidenav-content>
        <mat-sidenav #snav [mode]="'over'" fixedTopGap="56" position="end" class="w-60 z-index-150">
            <mat-card class="w-100">
                <button class="position-absolute r-1p mt--10" aria-label="close modal icon" matTooltip="Close"
                    (click)="snav.close();" mat-icon-button="">
                    <mat-icon>close</mat-icon>
                </button>
                <mat-card-header>
                    <mat-card-title>
                        <span *ngIf="!currentScanProfile._id">Add</span>
                        <span *ngIf="currentScanProfile._id">Edit</span> Scan Profile
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content *ngIf="currentScanProfile">
                    <form #appBaseForm="ngForm" role="form">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Profile Name</mat-label>
                            <input type="text" matInput placeholder="Rule Title" id="ApplicationRuleName" name="name"
                                [(ngModel)]="currentScanProfile.name" required>
                        </mat-form-field>
                        <!-- <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Profile Type</mat-label>
                            <mat-select name="scanType" id="ApplicationOSType" required
                                [(ngModel)]="currentScanProfile.scanType">
                                <mat-option value="PII Scan" id="AppWindows">PII Scan</mat-option>
                            </mat-select>
                        </mat-form-field> -->
                        <mat-card class="example-card mt-2">
                            <mat-card-header>
                                <mat-card-title>Scan Type</mat-card-title>
                            </mat-card-header>
                            <mat-list dense class="m-h-400 mt-1 overflow-auto">
                                <mat-list-item class="">
                                    <span class=""><mat-checkbox name="selectAll"
                                            (change)="selectAllToggle($event)" class="pt-2"
                                            [checked]="isAllSelected()"
                                            [indeterminate]="hasSelected() && !isAllSelected()" value="" color="primary">
                                            Select All
                                        </mat-checkbox>
                                    </span>
                                </mat-list-item>
                                <mat-list-item class="ml-4" *ngFor="let prod of scanSettingList| sortBy:'asc': 'name'; let i = index;">
                                    <span>
                                        <mat-checkbox [(ngModel)]="prod.selected" name="selected{{i}}" value="" class="pt-2"
                                            color="primary"></mat-checkbox>
                                    </span>
                                    <span class="ml-2">{{prod.name}}</span>
                                </mat-list-item>
                            </mat-list>
                        </mat-card>
                        <mat-form-field appearance="outline" class="w-100 mt-4">
                            <mat-label>Source Type</mat-label>
                            <mat-select name="dataStores" id="dataStores" required (selectionChange)="changeDataStore($event)"
                                [(ngModel)]="currentScanProfile.dataStores" [disabled]="currentScanProfile._id">
                                <mat-option [value]="data.id"
                                    *ngFor="let data of dataStores| sortBy:'asc': 'name'; let i = index;">{{data.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-card *ngIf="currentScanProfile.dataStores && currentScanProfile.dataStores !== 'files'">
                            <ng-container>
                                <div class="w-100">
                                    <app-dynamic-form (valueUpdate)="actionChange()" [isSaveBtn]="false"
                                        [isCloseBtn]="false" *ngIf="scanParams && formElements && formElements.length "
                                        [listOfFormElements]="formElements" [Valuesoutput]="scanParams">
                                    </app-dynamic-form>
                                </div>
                            </ng-container>
                        </mat-card>

                        <ng-container *ngIf="currentScanProfile.dataStores === 'files'">
                            <mat-card class="example-card mt-2">
                                <mat-card-header>
                                    <mat-card-title>Path Settings</mat-card-title>
                                    <mat-card-subtitle>Machine with lightweight agent</mat-card-subtitle>
                                </mat-card-header>
                                <mat-card-content>
                                    <ng-container>
                                        <h4 class="fs1 mt-1 ml-1 fw2 mb-0">Include location(s)</h4>
                                        <mat-form-field class="w-70p mat-small" appearance="outline"
                                            matTooltip="Enter location and click 'Plus' icon to add">
                                            <input autofocus type="text" trim="blur" name="agentIncludeLocation"
                                                placeholder="Enter the path" [(ngModel)]="agentIncludeLocation" matInput
                                                autocomplete="off">
                                        </mat-form-field>
                                        <button mat-button [disabled]="!agentIncludeLocation" matSuffix mat-icon-button
                                            aria-label="Add Location">
                                            <mat-icon class="tprime pointer"
                                                (click)="addLoaction('lightWeightAgent', 'includeLocations', 'agentIncludeLocation')">add</mat-icon>
                                        </button>
                                        <mat-list dense class="w-70p bor-1 p-0" *ngIf="currentScanProfile.pathSettings?.lightWeightAgent?.includeLocations &&
                                        currentScanProfile.pathSettings.lightWeightAgent?.includeLocations.length">
                                            <mat-list-item class="bb-1"
                                                *ngFor="let agentlocation of currentScanProfile.pathSettings?.lightWeightAgent?.includeLocations; index as i; trackBy:loaderService.trackByFn">
                                                <span class="">{{agentlocation}}</span>
                                                <span class="spacer mr-4">&nbsp;</span>
                                                <span class="pointer"><mat-icon id="deletedeniedApp2"
                                                        (click)="currentScanProfile.pathSettings.lightWeightAgent?.includeLocations.splice(i, 1)"
                                                        class="mat-18">delete</mat-icon></span>
                                            </mat-list-item>
                                        </mat-list>
                                        <p class="fs1 fw3" *ngIf="!currentScanProfile.pathSettings?.lightWeightAgent?.includeLocations ||
                                        !currentScanProfile.pathSettings?.lightWeightAgent?.includeLocations.length">No
                                            Include location(s) added!</p>
                                    </ng-container>
                                    <ng-container>
                                        <h4 class="fs1 mt-1 ml-1 fw2 mb-0">Exclude location</h4>
                                        <mat-form-field class="w-70p mat-small" appearance="outline"
                                            matTooltip="Enter location and click 'Plus' icon to add">
                                            <input autofocus type="text" trim="blur" name="agentExcludeLocation"
                                                placeholder="Enter the path" [(ngModel)]="agentExcludeLocation" matInput
                                                autocomplete="off">
                                        </mat-form-field>
                                        <button mat-button [disabled]="!agentExcludeLocation" matSuffix mat-icon-button
                                            aria-label="Add Location">
                                            <mat-icon class="tprime pointer"
                                                (click)="addLoaction('lightWeightAgent', 'excludeLocations', 'agentExcludeLocation')">add</mat-icon>
                                        </button>
                                        <mat-list dense class="w-70p bor-1 p-0" *ngIf="currentScanProfile.pathSettings?.lightWeightAgent?.excludeLocations &&
                                        currentScanProfile.pathSettings.lightWeightAgent?.excludeLocations.length">
                                            <mat-list-item class="bb-1"
                                                *ngFor="let agentlocation of currentScanProfile.pathSettings?.lightWeightAgent?.excludeLocations; index as i; trackBy:loaderService.trackByFn">
                                                <span class="">{{agentlocation}}</span>
                                                <span class="spacer mr-4">&nbsp;</span>
                                                <span class="pointer"><mat-icon id="deletedeniedApp2"
                                                        (click)="currentScanProfile.pathSettings.lightWeightAgent?.excludeLocations.splice(i, 1)"
                                                        class="mat-18">delete</mat-icon></span>
                                            </mat-list-item>
                                        </mat-list>
                                        <p class="fs1 fw3" *ngIf="!currentScanProfile.pathSettings?.lightWeightAgent?.excludeLocations ||
                                        !currentScanProfile.pathSettings?.lightWeightAgent?.excludeLocations.length">No
                                            Exclude location(s) added!</p>
                                    </ng-container>
                                </mat-card-content>
                            </mat-card>
                            <mat-card class="example-card mt-2">
                                <mat-card-header>
                                    <mat-card-subtitle>File Server Shares</mat-card-subtitle>
                                </mat-card-header>
                                <mat-card-content>
                                    <ng-container>
                                        <h4 class="fs1 mt-1 ml-1 fw2 mb-0">Include location</h4>
                                        <mat-form-field class="w-70p mat-small" appearance="outline"
                                            matTooltip="Enter location and click 'Plus' icon to add">
                                            <input autofocus type="text" trim="blur" name="fileIncludeLocation"
                                                placeholder="Enter the path" [(ngModel)]="fileIncludeLocation" matInput
                                                autocomplete="off">
                                        </mat-form-field>
                                        <button mat-button [disabled]="!fileIncludeLocation" matSuffix mat-icon-button
                                            aria-label="Add Location">
                                            <mat-icon class="tprime pointer"
                                                (click)="addLoaction('fileServerShares', 'includeLocations', 'fileIncludeLocation')">add</mat-icon>
                                        </button>
                                        <mat-list dense class="w-70p bor-1 p-0" *ngIf="currentScanProfile.pathSettings?.fileServerShares?.includeLocations &&
                                        currentScanProfile.pathSettings.fileServerShares?.includeLocations.length">
                                            <mat-list-item class="bb-1"
                                                *ngFor="let agentlocation of currentScanProfile.pathSettings?.fileServerShares?.includeLocations; index as i; trackBy:loaderService.trackByFn">
                                                <span class="">{{agentlocation}}</span>
                                                <span class="spacer mr-4">&nbsp;</span>
                                                <span class="pointer"><mat-icon id="deletedeniedApp2"
                                                        (click)="currentScanProfile.pathSettings.fileServerShares?.includeLocations.splice(i, 1)"
                                                        class="mat-18">delete</mat-icon></span>
                                            </mat-list-item>
                                        </mat-list>
                                        <p class="fs1 fw3" *ngIf="!currentScanProfile.pathSettings?.fileServerShares?.includeLocations ||
                                        !currentScanProfile.pathSettings?.fileServerShares?.includeLocations.length">No
                                            Include location(s) added!</p>
                                    </ng-container>
                                    <ng-container>
                                        <h4 class="fs1 mt-1 ml-1 fw2 mb-0">Exclude location</h4>
                                        <mat-form-field class="w-70p mat-small" appearance="outline"
                                            matTooltip="Enter location and click 'Plus' icon to add">
                                            <input autofocus type="text" trim="blur" name="fileExcludeLocation"
                                                placeholder="Enter the path" [(ngModel)]="fileExcludeLocation" matInput
                                                autocomplete="off">
                                        </mat-form-field>
                                        <button mat-button disabled="!fileExcludeLocation" matSuffix mat-icon-button
                                            aria-label="Add Location">
                                            <mat-icon class="tprime pointer"
                                                (click)="addLoaction('fileServerShares', 'excludeLocations', 'fileExcludeLocation')">add</mat-icon>
                                        </button>
                                        <mat-list dense class="w-70p bor-1 p-0" *ngIf="currentScanProfile.pathSettings?.fileServerShares?.excludeLocations &&
                                        currentScanProfile.pathSettings.fileServerShares?.excludeLocations.length">
                                            <mat-list-item class="bb-1"
                                                *ngFor="let agentlocation of currentScanProfile.pathSettings?.fileServerShares?.excludeLocations; index as i; trackBy:loaderService.trackByFn">
                                                <span class="">{{agentlocation}}</span>
                                                <span class="spacer mr-4">&nbsp;</span>
                                                <span class="pointer"><mat-icon id="deletedeniedApp2"
                                                        (click)="currentScanProfile.pathSettings.fileServerShares?.excludeLocations.splice(i, 1)"
                                                        class="mat-18">delete</mat-icon></span>
                                            </mat-list-item>
                                        </mat-list>
                                        <p class="fs1 fw3" *ngIf="!currentScanProfile.pathSettings?.fileServerShares?.excludeLocations ||
                                        !currentScanProfile.pathSettings?.fileServerShares?.excludeLocations.length">No
                                            Exclude location(s) added!</p>
                                    </ng-container>
                                </mat-card-content>
                            </mat-card>
                            <mat-card class="example-card mt-2">
                                <mat-card-header>
                                    <mat-card-subtitle>Linux Server</mat-card-subtitle>
                                </mat-card-header>
                                <mat-card-content>
                                    <ng-container>
                                        <h4 class="fs1 mt-1 ml-1 fw2 mb-0">Include location</h4>
                                        <mat-form-field class="w-70p mat-small" appearance="outline"
                                            matTooltip="Enter location and click 'Plus' icon to add">
                                            <input autofocus type="text" trim="blur" name="linuxIncludeLocation"
                                                placeholder="Enter the path" [(ngModel)]="linuxIncludeLocation" matInput
                                                autocomplete="off">
                                        </mat-form-field>
                                        <button mat-button disabled="!linuxIncludeLocation" matSuffix mat-icon-button
                                            aria-label="Add Location">
                                            <mat-icon class="tprime pointer"
                                                (click)="addLoaction('linuxServer', 'includeLocations', 'linuxIncludeLocation')">add</mat-icon>
                                        </button>
                                        <mat-list dense class="w-70p bor-1 p-0" *ngIf="currentScanProfile.pathSettings?.linuxServer?.includeLocations &&
                                        currentScanProfile.pathSettings.linuxServer?.includeLocations.length">
                                            <mat-list-item class="bb-1"
                                                *ngFor="let agentlocation of currentScanProfile.pathSettings?.linuxServer?.includeLocations; index as i; trackBy:loaderService.trackByFn">
                                                <span class="">{{agentlocation}}</span>
                                                <span class="spacer mr-4">&nbsp;</span>
                                                <span class="pointer"><mat-icon id="deletedeniedApp2"
                                                        (click)="currentScanProfile.pathSettings.linuxServer?.includeLocations.splice(i, 1)"
                                                        class="mat-18">delete</mat-icon></span>
                                            </mat-list-item>
                                        </mat-list>
                                        <p class="fs1 fw3" *ngIf="!currentScanProfile.pathSettings?.linuxServer?.includeLocations ||
                                        !currentScanProfile.pathSettings?.linuxServer?.includeLocations.length">No
                                            Include location(s) added!</p>
                                    </ng-container>
                                    <ng-container>
                                        <h4 class="fs1 mt-1 ml-1 fw2 mb-0">Exclude location</h4>
                                        <mat-form-field class="w-70p mat-small" appearance="outline"
                                            matTooltip="Enter location and click 'Plus' icon to add">
                                            <input autofocus type="text" trim="blur" name="linuxExcludeLocation"
                                                placeholder="Enter the path" [(ngModel)]="linuxExcludeLocation" matInput
                                                autocomplete="off">
                                        </mat-form-field>
                                        <button mat-button disabled="!linuxExcludeLocation" matSuffix mat-icon-button
                                            aria-label="Add Location">
                                            <mat-icon class="tprime pointer"
                                                (click)="addLoaction('linuxServer', 'excludeLocations', 'linuxExcludeLocation')">add</mat-icon>
                                        </button>
                                        <mat-list dense class="w-70p bor-1 p-0" *ngIf="currentScanProfile.pathSettings?.linuxServer?.excludeLocations &&
                                        currentScanProfile.pathSettings.linuxServer?.excludeLocations.length">
                                            <mat-list-item class="bb-1"
                                                *ngFor="let agentlocation of currentScanProfile.pathSettings?.linuxServer?.excludeLocations; index as i; trackBy:loaderService.trackByFn">
                                                <span class="">{{agentlocation}}</span>
                                                <span class="spacer mr-4">&nbsp;</span>
                                                <span class="pointer"><mat-icon id="deletedeniedApp2"
                                                        (click)="currentScanProfile.pathSettings.linuxServer?.excludeLocations.splice(i, 1)"
                                                        class="mat-18">delete</mat-icon></span>
                                            </mat-list-item>
                                        </mat-list>
                                        <p class="fs1 fw3" *ngIf="!currentScanProfile.pathSettings?.linuxServer?.excludeLocations ||
                                        !currentScanProfile.pathSettings?.linuxServer?.excludeLocations.length">No
                                            Exclude location(s) added!</p>
                                    </ng-container>
                                </mat-card-content>
                            </mat-card>
                        </ng-container>
                        <mat-form-field appearance="outline" class="form-element w-100 mt-2" *ngIf="mode === 'global'">
                            <mat-select #companySelect [multiple]="true" required class=""
                                placeholder="Select companies" name="Company"
                                [(ngModel)]="currentScanProfile.companyid" [formControl]="companyCtrl"
                                (selectionChange)="currentScanProfile.companyid = ($event.value?.includes('*')) ? ['*'] : $event.value">
                                <mat-option>
                                    <ngx-mat-select-search [formControl]="companyFilterCtrl"
                                        [placeholderLabel]="'Search Company'"
                                        [noEntriesFoundLabel]="'No matching company found'" [searching]="searching">
                                    </ngx-mat-select-search>
                                </mat-option>
                                <mat-option matTooltip="All Companies" value="*">All Companies</mat-option>
                                <mat-option [matTooltip]="company.name" *ngFor="let company of allComp | search: companyFilterCtrl.value"
                                    [value]="company._id">
                                    {{company.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="form-element w-100 mt-2" *ngIf="mode === 'agent'">
                            <mat-select #agentSelect [multiple]="true" required class=""
                                placeholder="Select agent" name="Agent"
                                [(ngModel)]="currentScanProfile.agentid" [formControl]="agentCtrl"
                                (selectionChange)="currentScanProfile.agentid = ($event.value?.includes('*')) ? ['*'] : $event.value">
                                <mat-option>
                                    <ngx-mat-select-search [formControl]="agentFilterCtrl"
                                        [placeholderLabel]="'Search Agent'"
                                        [noEntriesFoundLabel]="'No matching agent found'" [searching]="searching">
                                    </ngx-mat-select-search>
                                </mat-option>
                                <mat-option matTooltip="All Agents" value="*" *ngIf="agentList.length > 1">All Agents</mat-option>
                                <mat-option [matTooltip]="agent.name" *ngFor="let agent of agentList | search: agentFilterCtrl.value"
                                    [value]="agent._id">
                                    {{agent.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <button type="button" color="primary" class="mr-2" id="saveAppBaseline2"
                            [disabled]="!appBaseForm.form.valid && !disabledSubmit" mat-raised-button="" (click)="saveScanProfile()">
                            Save
                        </button>
                        <button (click)="snav.close()" mat-stroked-button="">Cancel</button>
                    </form>
                </mat-card-content>
            </mat-card>
        </mat-sidenav>
    </mat-sidenav-container>
</div>