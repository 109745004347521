import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import { FormControl, FormGroup} from '@angular/forms';
import { IntegrationActionsService } from 'src/app/_services/integration-actions.service';
import {AuthenticationService} from '../../../_services/authentication.service';
import { MatSelect } from '@angular/material/select';
import { ReplaySubject, Subject } from 'rxjs';

@Component({
  selector: 'app-dynamic-control',
  templateUrl: './dynamic-control.component.html',
  styleUrls: ['./dynamic-control.component.scss']
})
export class DynamicControlComponent{

  constructor(public authService: AuthenticationService, private integration: IntegrationActionsService) {
  }
  @Output() companyChange = new EventEmitter();
  @Output() valChange = new EventEmitter();
  @Input() outputValue: any;
  @Input() formElement: any;
  @Input() form!: FormGroup;



  searching = false;
  /** control for the selected entity */
  public entityCtrl: FormControl = new FormControl();

  /** control for the MatSelect filter keyword */
  public entityFilterCtrl: FormControl = new FormControl();

  /** list of entities filtered by search keyword */
  public filteredEntities: ReplaySubject<any> = new ReplaySubject<any>(1);

  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;

  /** Subject that emits when the component has been destroyed. */
  private _onDestroy = new Subject<void>();

  
  get isValid(): any {
    // @ts-ignore
    return this.form.controls[this.formElement.key].valid;
  }

  ngOnInit(): void {
    this.form.statusChanges
      .pipe()
      .subscribe(() => this.onFormValid(this.form.valid));
  }

  private onFormValid(status: any) {
    this.integration.integrationValidationEVE.next(status);
  }
  // tslint:disable-next-line:use-lifecycle-interface
  /*ngOnChanges(): void {
    if (this.form === undefined) {
      this.form = this.formControlService.toFormControl(this.formElement);
    }
  }*/

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  onKeydownMain(e: any): void {
    let i = 0;
    if (e.target.value.length < 1) {
      if (e.keyCode === 32) {
        return;
      }
    } else {
      if (e.keyCode === 32) {
        if (i !== 0) {
          return;
        }
        i++;
      } else {
        i = 0;
      }
    }
  }

  selectedFile(file: any, key: any): void {
    this.outputValue[key] = file;
  }

  callBack($event: any, callback: boolean, formElement: any): void {
    if (callback) {
      this.outputValue[formElement] = $event;
      this.valChange.emit($event);
    }
  }
}
