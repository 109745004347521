import {Component, Input, OnInit} from '@angular/core';
import {LoaderService} from '../../../_services/loader.service';
import {AgentService} from '../../../api/services/agent.service';
import {SnmpV2CredentialsService} from '../../../api/services/snmp-v-2-credentials.service';
import {ModalService} from '../../../_services/modal.service';
import {CommonService} from '../../../_services/common.services';
import {MyToastrService} from '../../../_services/toastr.service';
import {SnmpV2Credentials} from '../../../api/models/snmp-v-2-credentials';
import {ConfirmDialogService} from '../../../_services/confirmdialog.service';
import {BaseRequestService} from '../../../_services/base.service';
import {Agent} from '../../../api/models/agent';
import {AuthenticationService} from '../../../_services/authentication.service';
import {CompanyService} from '../../../api/services/company.service';

@Component({
  selector: 'app-snmpv2',
  templateUrl: './snmpv2.component.html',
  styleUrls: ['./snmpv2.component.scss']
})
export class Snmpv2Component implements OnInit {
    @Input() currentCompany: any;
    @Input() currentAgent: any;
      addSnmpv2Width = 600;
      currentAsset: any;
      snmpv2: any = {};
      isLoading = false;
      snmpv2TableOptions: any;
      snmpv2currentPage = 0;
      snmpv2filterQuery: any;
      colFilterQuery: any;
      colFilterCols: any = [];
      netmaskList: any = [];
      isEndIp = false;
      isNetMask = true;
      agentHash: any = {};
      agentList: any[] = [];
      currentSnmpv2Cred: any;
      probeList: any = [];
      assetTags: any = [];
      agentRef: any = {};
      snmpVersion: any[] = [
        {value: 'v1', text: 'SNMP v1', id:'SNMPV1'},
        {value: 'v2', text: 'SNMP v2', id:'SNMPV2'}
      ];
      constructor(public baseService: BaseRequestService, public modalService: ModalService,
                  public toast: MyToastrService, private loaderService: LoaderService,
                  public confirmDialog: ConfirmDialogService, public commonService: CommonService,
                  public agentService: AgentService, private aS: AuthenticationService,
                  private companyService: CompanyService,
                  public snmpV2CredentialsService: SnmpV2CredentialsService) {
        for (let index = 1; index <= 128; index++) {
          this.netmaskList.push({
            value: '/' + index,
            text: '/' + index
          });
        }
        this.snmpv2TableOptions = {
          columns: [
            {
            header: 'Name',
            columnDef: 'name',
            filter: '',
            cell: '(element: any) => `${element.name}`',
            order: 0,
            visible: true,
            isToolTip: false,
            isToolTipCol: '',
            hasMultiData: false,
            class: '',
            color: '',
            isProgressCntrl: false,
            isColoredCntrl: false,
            colList: [],
            isfaicon: false,
            isAddingText: false,
            addingText: '',
            img: false,
            imgPath: '',
            isSort: true,
            iscolumnSearch: false
          }, {
            header: 'Community String',
            columnDef: 'community',
            filter: 'maskpassword',
            cell: '(element: any) => `${element.community}`',
            order: 1,
            visible: true,
            isToolTip: false,
            isToolTipCol: '',
            hasMultiData: false,
            class: '',
            color: '',
            isProgressCntrl: false,
            isColoredCntrl: false,
            colList: [],
            isfaicon: false,
            isAddingText: false,
            addingText: '',
            img: false,
            imgPath: '',
            isSort: false,
            iscolumnSearch: false
          },{
            header: 'Created',
            columnDef: 'c',
            filter: 'utcToLocale',
            cell: '(element: any) => `${element.c}`',
            order: 2,
            visible: true,
            isToolTip: false,
            isToolTipCol: '',
            hasMultiData: false,
            class: '',
            color: '',
            isProgressCntrl: false,
            isColoredCntrl: false,
            colList: [],
            isfaicon: false,
            isAddingText: false,
            addingText: '',
            img: false,
            imgPath: '',
            isSort: true,
            iscolumnSearch: false,
          },
          {
            header: 'Updated',
            columnDef: 'u',
            filter: 'utcToLocale',
            cell: '(element: any) => `${element.u}`',
            order: 3,
            visible: false,
            isToolTip: false,
            isToolTipCol: '',
            hasMultiData: false,
            class: '',
            color: '',
            isProgressCntrl: false,
            isColoredCntrl: false,
            colList: [],
            isfaicon: false,
            isAddingText: false,
            addingText: '',
            img: false,
            imgPath: '',
            isSort: true,
            iscolumnSearch: false
          }],
          sortOptions: {active: 'name', direction: 'asc'},
          faClass: 'SnmpV2Credentials',
          _pageData: [],
          tableOptions: {
            id: 'snmpv2TableOptions',
            title: 'SNMP v1/v2',
            isServerSide: true,
            selectText: 'snmpv2(s)',
            loading: true,
            floatingFilter: true,
            rowSelection: true,
            showAction: true,
            actionMenuItems: [
              {text: 'Copy to Probe', icon: 'content_copy', callback: 'deleteFn', isGlobal: true, hidelocal: true},
              {
              text: 'Edit',
              icon: 'edit',
              callback: 'editFn',
              isGlobal: false
            }, {text: 'Delete', icon: 'delete', callback: 'deleteFn', isGlobal: true}],
            pagination: true,
            pageOptions: [5, 10, 25, 100],
            pageSize: 10,
            search: true,
            showhideList: true,
            refreshData: true,
            exportExcel: true,
            filterDownload:false,
            add: (this.aS.hasPermission('snmpv2credentials', 'create')),
            columnSearch: false,
            compareData: false,
            service: 'snmpV2CredentialsService',
            collection: 'SnmpV2Credentials'
          }
        };
      }

      changeAgent(agent: string): void {
        this.snmpv2.agentRef.name = this.agentHash.get(agent);
      }

      updateSnmpv2(snmpv2: any): void {
        this.isLoading = true;
        this.loaderService.Modeldisplay(true);
        this.snmpV2CredentialsService.updateApiSnmpv2CredentialsPut({body: snmpv2}).subscribe((result: any) => {
          this.loaderService.Modeldisplay(false);
          this.toast.sToast('success', 'Updated successfully');
          this.isLoading = false;
          setTimeout(() => { this.getSnmpv2(); }, 2000);
          this.modalService.close('addSnmpv2');
        });
      }

      saveSnmpv2(): void {
        this.isLoading = true;
        this.loaderService.Modeldisplay(true);
        this.snmpv2.companyRef = {id: this.currentCompany._id, name: this.currentCompany.name};
        this.snmpV2CredentialsService.createApiSnmpv2CredentialsPost({body: this.snmpv2})
          .subscribe((result: SnmpV2Credentials) => {
            this.loaderService.Modeldisplay(false);
            this.isLoading = true;
            if (result._id) {
              this.modalService.close('addSnmpv2');
              this.toast.sToast('success', `SNMP ${this.snmpv2.snmpVersion} added successfully!`);
              setTimeout(() => { this.getSnmpv2(); }, 2000);
              this.isLoading = false;
            } else {
              this.toast.sToast('error', JSON.stringify(result));
              this.isLoading = false;
            }
          });
      }
      
      deleteRange(snmpv2: any): void {
        const titleName = 'Confirmation';
        const message = `Are you sure you want to delete this SNMP ${snmpv2.snmpVersion} ${snmpv2.name}?`;
        const cancelText = 'No';
        const acceptText = 'Yes';
        this.confirmDialog.confirmDialog(titleName, message, cancelText, acceptText);
        this.confirmDialog.dialogResult.subscribe(res => {
          if (res) {
            this.snmpV2CredentialsService.deleteApiSnmpv2CredentialsIdDelete({id: snmpv2._id}).subscribe((result: any) => {
              this.toast.sToast('success', 'Removed successfully');
              setTimeout(() => {
                this.getSnmpv2();
              }, 2000);
            });
          }
        });
      }

      ngOnInit(): void {
        this.snmpv2TableOptions.pageData = [];
        this.getSnmpv2();
        this.getAgents();
      }

      snmpv2showHideLoading(status: any): void {
        const data = Object.assign({}, this.snmpv2TableOptions);
        this.snmpv2TableOptions = {};
        this.snmpv2TableOptions = data;
        this.snmpv2TableOptions.tableOptions.loading = status;
      }

      snmpv2globalActionCall(idata: any): void {
        console.log(idata);
        if (idata.action.text === 'Delete') {
          this.deleteIPRanges(idata);
        }
        else if (idata.action.text === 'Copy to Probe') {
          this.currentSnmpv2Cred = idata.row;
          this.openCopyToProbe();
        }
        // this.snmpv2showHideLoading(true);
        // setTimeout(() => {
        //   this.snmpv2showHideLoading(false);
        // }, 1000);
      }

      openCopyToProbe(): void {
        this.agentRef = {id: '', name: '', agents: []};
        this.probeList = this.probeList.filter((x: any) => x && x._id);
        this.agentRef.agents = this.probeList.filter((x: any) => x._id !== this.currentAgent._id);
        if (!this.agentRef.agents || !this.agentRef.agents.length) {
          this.toast.sToast('error', 'No other probes to copy!');
        } else {
          this.modalService.open('copySnmpv2ToProbe');
        }
      }
    
      copyToSelectProbe(agentRef: any): void {
        this.isLoading = true;
        const param: any = {
          src_agent_id: this.currentAgent._id, dest_agent_id: agentRef.id, clone_records: []
        };
        this.currentSnmpv2Cred.forEach((obj: any) => {
          param.clone_records.push(obj._id);
        });
        this.companyService.migrateAgentSettingsApiCompanyIdCloneAgentSettingsPost({id: 'imaws', body: param})
        .subscribe((result: any) => {
          this.loaderService.Modeldisplay(false);
          this.toast.sToast('success', 'Copied successfully!');
          this.isLoading = false;
          this.toast.sToast('success', 'Copied successfully!');
          this.closeCopyProbe();
        });
      }
    
      closeCopyProbe(): void {
        this.currentSnmpv2Cred = []; this.agentRef = {id: '', name: '', agents: []};
        this.modalService.close('copySnmpv2ToProbe');
      }

      deleteIPRanges(idata: any): void {
        const titleName = 'Confirmation';
        const message = 'Are you sure you want to remove the selected SNMPv1/v2 entries ?';
        const cancelText = 'No';
        const acceptText = 'Yes';
        this.confirmDialog.confirmDialog(titleName, message, cancelText, acceptText);
        this.confirmDialog.dialogResult.subscribe(res => {
          if (res) {
            this.loaderService.display(true, 'Deletion in progress...');
            idata.row.forEach((obj: any, index: number) => {
              this.snmpV2CredentialsService.deleteApiSnmpv2CredentialsIdDelete({id: obj._id}).subscribe((result: any) => {
                if (index === idata.row.length - 1) {
                  this.loaderService.display(false);
                  this.toast.sToast('success', 'Removed successfully');
                  setTimeout(() => { this.getSnmpv2(); }, 2000);
                }
              });
            });
          }
        });
      }
    

      snmpv2sortCall(idata: any): void {
        this.snmpv2TableOptions.sortOptions = idata;
        this.getSnmpv2();
      }

      snmpv2editFn(idata: any): void {
        console.log(idata);
        this.snmpv2 = idata;
        this.modalService.open('addSnmpv2');
      }

      snmpv2deleteFn(idata: any): void {
        this.deleteRange(idata);
      }

      snmpv2actionCall(idata: any): void {
        if (idata.action.text === 'Edit') {
          const dataRow = idata.row;
          this.snmpv2editFn(dataRow);
        }
        if (idata.action.text === 'Copy to Probe') {
          this.isLoading = false;
          this.currentSnmpv2Cred = [idata.row];
          this.openCopyToProbe();
        }
        if (idata.action.text === 'Delete') {
          const dataRow = idata.row;
          this.snmpv2deleteFn(dataRow);
        }
      }

      snmpv2filterCall(idata: any): void {
        const fields: any[] = [];
        this.snmpv2TableOptions.columns.forEach((obj: { columnDef: any; }) => {
          if (obj.columnDef !== 'c' && obj.columnDef !== 'u')
          fields.push(obj.columnDef);
        });
        this.snmpv2filterQuery = (idata && idata.length > 0) ? {
          multi_match: {
            query: idata,
            type: 'phrase_prefix',
            fields
          }
        } : undefined;
        this.getSnmpv2();
      }

      snmpv2pageCall(event: any): void {
        this.snmpv2TableOptions.tableOptions.pageSize = event.pageSize;
        this.snmpv2currentPage = event.pageIndex;
        this.getSnmpv2();
      }

      snmpv2addTableData(): void {
        this.snmpv2 = { name: '', community: '', snmpVersion: 'v1', agentRef: {id: '', name: ''} };
        this.snmpv2.agentRef.id = (this.currentAgent) ? this.currentAgent._id : this.agentList[0]._id;
        this.snmpv2.agentRef.name = (this.currentAgent) ? this.currentAgent.name : this.agentList[0].name;
        this.modalService.open('addSnmpv2');
      }

      snmpv2timerCallData(idata: any): void {
        this.getSnmpv2();
      }

  getAgents(): void {
    if (this.currentCompany && this.currentCompany._id) {
      this.commonService.getAgents(this.currentCompany._id).then((v2: any) => {
        this.commonService.sortFn(v2.data, 'name').then((val: any) => {
          this.agentList = val.filter((x: any) => x._id);
          this.probeList = val.filter((x: any) => x?._id);
          this.agentHash = new Map(this.agentList.map((i: any) => [i._id, i.name]));
        });
      });
    } else {
      setTimeout(() => {
        this.getAgents();
      }, 1000);
    }
  }

  getSnmpv2(): void {
    this.loaderService.display(true, 'Getting SNMP details...');
    this.snmpv2showHideLoading(true);
    this.snmpv2TableOptions.serverSide = {
      service: 'snmpV2CredentialsService', fn: 'getAllApiSnmpv2CredentialsGet', q: {
        query: {
          bool: {
            must: [
              {match: {'companyRef.id.keyword': this.currentCompany._id + ''}},
                    {exists: {field: 'community'}}]
          }
        }
      }
    };
    let params;
    params = {
      query: {
        bool: {
          must: [{match: {'companyRef.id.keyword': this.currentCompany._id + ''}},
            {exists: {field: 'community'}}]
        }
      }
    };
    if (this.currentAgent) { // @ts-ignore
      params.query.bool.must.push({match: {'agentRef.id.keyword': this.currentAgent._id + ''}});
    }
    if (this.snmpv2filterQuery && this.snmpv2filterQuery.multi_match) {
      // @ts-ignore
      params.query.bool.must.push(this.snmpv2filterQuery);
    }
    let sort: any = [{}];
    if (this.snmpv2TableOptions.sortOptions) {
      const orderArr = ['name'];
      if (orderArr.indexOf(this.snmpv2TableOptions.sortOptions.active) !== -1) {
        sort[0][this.snmpv2TableOptions.sortOptions.active + '.keyword'] = {order: this.snmpv2TableOptions.sortOptions.direction};
      } else { 
       sort[0][this.snmpv2TableOptions.sortOptions.active] = {order: this.snmpv2TableOptions.sortOptions.direction};
    }
  }
    const q = JSON.stringify(params);
    const skip = this.snmpv2currentPage;
    sort = JSON.stringify(sort);
    const limit = this.snmpv2TableOptions.tableOptions.pageSize;
    this.snmpV2CredentialsService.getAllApiSnmpv2CredentialsGet({q, skip, limit,sort})
    .subscribe((result: any) => {
      this.loaderService.display(false);
      if (result.data.length) {
        this.snmpv2TableOptions.pageData = result.data;
        this.snmpv2TableOptions.tableOptions.pageTotal = result.total;
        this.snmpv2showHideLoading(false);
      } else {
        this.snmpv2TableOptions.pageData = [];
        this.snmpv2TableOptions.tableOptions.pageTotal = 0;
        this.snmpv2showHideLoading(false);
      }
    });
  }
}
