import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IntegrationsService } from '../../../api/services/integrations.service';
import { MyToastrService } from '../../../_services/toastr.service';
import { ConfirmDialogService } from '../../../_services/confirmdialog.service';
import { LoaderService } from '../../../_services/loader.service';
import { IntegrationMappingService } from '../../../api/services/integration-mapping.service';
import { CompanyService } from '../../../api/services/company.service';
import { BaseRequestService } from '../../../_services/base.service';
import { MatSelect } from '@angular/material/select';
import { FormControl } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { MatMenuTrigger } from '@angular/material/menu';
import { AuthenticationService } from '../../../_services/authentication.service';
import { MatStepper } from '@angular/material/stepper';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { NotificationRulesService } from 'src/app/api/services';
@Component({
  selector: 'app-default-templates',
  templateUrl: './default-templates.component.html',
  styleUrls: ['./default-templates.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true },
    },
  ],
})

export class DefaultTemplatesComponent implements OnInit {
  @Input() currentIntegration: any;
  @Input() integrationsData: any;
  @Input() onboarding: any;
  @ViewChild('stepper') stepper: MatStepper; alertList: any;
  @ViewChild('companySelect', { static: true }) companySelect!: MatSelect;
  public companyCtrl: FormControl = new FormControl();
  public companyFilterCtrl: FormControl = new FormControl();
  public filteredCompanies: ReplaySubject<any> = new ReplaySubject<any>(1);
  public searching = false;
  protected onDestroySearch = new Subject<void>();
  notificationRule: any;
  defaultTemplatesData: any = [];
  currentTemplate: any;
  inputActionData: any = {};
  panelOpenState = false;
  currentCredential: any = null;
  Objectkeys = Object.keys;
  cView = 'allTemplates';
  defaultTemplates: any = [];
  isLoading = false;
  companies: any = [];
  allComp: any;
  notiTableOptions: any = {};
  actionData: any;
  currentPage = 0;
  constructor(private integrationsService: IntegrationsService, private toast: MyToastrService,
    private companyService: CompanyService, private baseService: BaseRequestService,
    private aS: AuthenticationService, private notificationRulesService: NotificationRulesService,
    private confirmDialog: ConfirmDialogService,
    public loaderService: LoaderService) {

    this.notiTableOptions = {
      columns: [{
        header: 'Name',
        columnDef: 'name',
        filter: '',
        cell: '(element: any) => `${element.name}`',
        order: 0,
        visible: true,
        isToolTip: false,
        isToolTipCol: '',
        hasMultiData: false,
        class: '',
        color: '',
        isProgressCntrl: false,
        isColoredCntrl: false,
        colList: [],
        isfaicon: false,
        isAddingText: false,
        addingText: '',
        img: false,
        imgPath: '',
        isSort: true,
        iscolumnSearch: false
      }, {
        header: `Company`,
        columnDef: 'companyRef.name',
        filter: '',
        cell: '(element: any) => `${companyRef.name}`',
        order: 3,
        visible: true,
        isToolTip: false,
        isToolTipCol: '',
        hasMultiData: false,
        class: '',
        color: '',
        isProgressCntrl: false,
        isColoredCntrl: false,
        colList: [],
        isfaicon: false,
        isAddingText: false,
        addingText: '',
        img: false,
        imgPath: '',
        isSort: true,
        iscolumnSearch: false
      }],
      sortOptions: { active: 'name', direction: 'asc' },
      faClass: 'Company',
      _pageData: [],
      tableOptions: {
        title: 'Notification Rules',
        id: 'notiTableOptions',
        isServerSide: true,
        selectText: 'integrationMapping',
        loading: true,
        floatingFilter: true,
        rowSelection: false,
        showAction: true,
        actionMenuItems: [
          {
            text: 'Edit',
            icon: 'edit',
            callback: 'editFn',
            isGlobal: false
          },
          { text: 'Delete', icon: 'delete', callback: 'deleteFn', isGlobal: false }],
        pagination: true,
        pageOptions: [5, 10, 20, 25, 100],
        pageSize: 20,
        showhideList: true,
        refreshData: true,
        add: true,
        search: false,
        hideDownload: true,
        exportExcel: false,
        saveData: false
      }
    };
  }

  ngOnInit(): void {
    this.currentCredential = this.integrationsData[0]._id;
    this.currentIntegration.credentialId = this.integrationsData[0]._id;
    this.actionData = Object.assign({}, this.currentIntegration.actionData);
    this.getDefaultTemplates();
    this.getAlertNotification();
    this.companyFilterCtrl.valueChanges.pipe(debounceTime(300),
      takeUntil(this.onDestroySearch)).subscribe(() => {
        this.filterCompanies();
      });
    this.getCompanies();
  }
  sortCall(event: any): void {
    this.notiTableOptions.sortOptions = event;
    this.getDefaultTemplates();
  }

  notipageCall(event: any): void {
    this.notiTableOptions.tableOptions.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.getDefaultTemplates();
  }
  ngOnDestroy(): void {
    this.onDestroySearch.next();
    this.onDestroySearch.complete();
  }

  private filterCompanies(): void {
    if (!this.allComp) {
      return;
    }
    // get the search keyword
    let search = this.companyFilterCtrl.value;
    if (!search) {
      this.filteredCompanies.next(this.allComp.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.getCompanies(search);
  }

  closeCurrentCompany(event: any): void {
    if (this.allComp) {
      this.filteredCompanies.next(this.allComp.slice());
    }
    if (!event) {
      this.getCompanies();
    }
  }

  notiactionCall(idata: any): void {
    if (idata.action.text === 'Edit') {
      const dataRow = idata.row;
      this.editTemplate(dataRow);
    }
    if (idata.action.text === 'Delete') {
      const dataRow = idata.row;
      this.deleteNotificationRule(dataRow);
    }
  }
  getCompanies(search?: string): void {
    if (!this.aS || !this.aS.isAuthenticated) {
      setTimeout(() => {
        this.getCompanies();
      }, 2000);
      return;
    }
    let cq: any;
    const cmpq = { query: { bool: { must: [{ exists: { field: 'description' } }], must_not: [{ match: { isAssessment: true } }, { exists: { field: 'companyRef' } }] } } };
    const asmq = { query: { bool: { must: [{ match: { isAssessment: true } }, { exists: { field: 'description' } }], must_not: [{ exists: { field: 'companyRef' } }] } } };
    cq = (this.baseService.showAssessment) ? asmq : cmpq;
    if (search && search !== '') {
      cq.query.bool.must.push({ match_bool_prefix: { name: search.toLowerCase() } });
    }
    const q = JSON.stringify(cq);
    const skip = 0;
    const limit = 1000;
    const sort = JSON.stringify([{ 'name.keyword': { order: 'asc' } }]);
    const fields = JSON.stringify(['name', 'c']);
    this.searching = true;
    this.companyService.getAllApiCompanyGet({ q, skip, limit, sort, fields }).subscribe((result: any) => {
      if (result.data.length) {
        result.data.sort((a: any, b: any) => {
          const c = (a.name) ? a.name.toLowerCase() : '';
          const d = (b.name) ? b.name.toLowerCase() : '';
          if (c < d) {
            return -1;
          } else if (c > d) {
            return 1;
          } else {
            return 0;
          }
        });
        this.companies = result.data;
        if (!search) {
          this.allComp = result.data;
        }
        this.filteredCompanies.next(result.data.slice());

        this.searching = false;
      } else {
        this.filteredCompanies.next([]);
        this.searching = false;
      }
    }, error => {
      this.searching = false;
    });
  }
  notihowHideLoading(status: any): void {
    const data = Object.assign({}, this.notiTableOptions);
    this.notiTableOptions = {};
    this.notiTableOptions = data;
    this.notiTableOptions.tableOptions.loading = status;
  }
  getDefaultTemplates(): void {
    this.loaderService.display(true, 'Getting default templates...');
    let sort: any = [{}];
    if (this.notiTableOptions.sortOptions) {
      const orderArr = [''];
      if (orderArr.indexOf(this.notiTableOptions.sortOptions.active) === -1) {
        sort[0][this.notiTableOptions.sortOptions.active + '.keyword'] = {order: this.notiTableOptions.sortOptions.direction};
      } else {
        sort[0][this.notiTableOptions.sortOptions.active] = {order: this.notiTableOptions.sortOptions.direction};
      }
    }
    const reqData = {
      integrationName: this.currentIntegration.name,
      integrationId: this.currentCredential,
      skip: (this.currentPage * this.notiTableOptions.tableOptions.pageSize),
      limit: this.notiTableOptions.tableOptions.pageSize,
      sort: sort
    };
    this.baseService.doRequest(`/api/ticketingrulesconfig/getintegrationrule`, 'post', reqData).subscribe((result: any) => {
      this.loaderService.display(false);
      if (result.data && result.data.length) {
        this.notihowHideLoading(false);
        result.data.map((res: any) => {
          res.companyRef = (res.companyRef && res.companyRef?.name) ? res.companyRef : res.companyRef = { id: '*', name: 'All Companies' };
        })
        this.notiTableOptions.pageData = result.data;
        this.notiTableOptions.tableOptions.pageTotal = result.total;
        this.defaultTemplates = result.data;
      } else {
        this.notihowHideLoading(false);
        this.notiTableOptions.pageData = [];
      }
    });
  }
  async stepperNext(index: any): Promise<void> {
    switch (index) {
      case 1:
        if (!this.currentTemplate.companyRef) {
          this.toast.sToast('error', `Please choose company`);
          return;
        }
        this.stepper.selectedIndex = index;
        setTimeout(() => {
          this.stepper.linear = true;
        });
        break;
      case 2:
        let selected = false;
        let companySelected = false; let assetSelected = false; let prodSelected = false;
        this.notificationRule.forEach((sec: any) => {
          sec.alerts.forEach((rule: any) => {
            rule.events.forEach((eve: any) => {
              if (eve.selected) { selected = true; }
              if (!companySelected) {
                companySelected = (eve.name === 'Remediation By Company' && eve.selected);
              }
              if (!assetSelected) {
                assetSelected = (eve.name === 'Remediation By Asset' && eve.selected);
              }
              if (!prodSelected) {
                prodSelected = (eve.name === 'Remediation By Product' && eve.selected);
              }
            });
          });
        });
        if (!selected) {
          this.toast.sToast('error', `Please choose atleast one event`);
          return;
        }
        const remSelected = [companySelected, assetSelected, prodSelected];
        const len = remSelected.filter((x: any) => !x).length;
        if (len < 2) {
          this.toast.sToast('error', `Please choose either 'Remediation By Company' or 'Remediation By Asset' or 'Remediation By Product'`);
          return;
        }
        this.stepper.linear = false;
        this.stepper.selectedIndex = index;
        setTimeout(() => {
          this.stepper.linear = true;
        });
        break;
      default:
        this.saveDefaultTemplate();
    }
  }
  getDefaultTemplatesOld(): void {
    this.loaderService.display(true, 'Getting default templates...');
    this.baseService.doRequest(`/api/integrations/getDefaultTemplates?integrationName=${this.currentIntegration.name}`,
      'get').subscribe((result: any) => {
        this.loaderService.display(false);
        if (result.data && result.data.length) {
          this.defaultTemplatesData = result.data;
        } else {
          this.defaultTemplatesData = [];
          this.addDefaultTemplate();
        }
      });
  }

  getActionParams(): void {
    this.loaderService.display(true, `Getting ${this.currentIntegration.name}`);
    this.baseService.doRequest(
      `/api/integrations/action_params?integrationName=${this.currentIntegration.name}&actionName=${this.currentIntegration.notificationfunction}`,
      'get').subscribe((result: any) => {
        this.loaderService.display(false);
        result.parameters = result.parameters.filter((x: any) => x.notification);
        this.currentIntegration.actionResult = result;
        this.processFields(this.currentIntegration.actionResult);
      });
  }

  processFields(result: any): void {
    if (!result || !result.parameters) { return; }
    this.currentIntegration.actionData =
      (this.currentIntegration.actionData) ? this.currentIntegration.actionData : [];
    const tmpAP: { label: any; key: any; required: any; example?: any; type: any; }[] = [];
    result.parameters.forEach(async (obj: any) => {
      const tmpFormField = (this.currentIntegration.formElements)
        ? this.currentIntegration.formElements.filter((x: any) => x.key === obj.name)
        : [];
      const tFF: any = { label: obj.description, key: obj.name, required: obj.required, example: obj.example, type: obj.schema.type };
      tFF.callback = (obj.callback);
      if (obj.source && !obj.depends) {
        if (tmpFormField && tmpFormField.length && tmpFormField[0].options && tmpFormField[0].options.length) {
          tFF.options = tmpFormField[0].options;
        } else {
          this.getSubActionParams(obj.source.name).then(async (subObj: any) => {
            const requestParams: any = {};
            obj.subParams = result;
            if (subObj.parameters) {
              subObj.parameters.forEach((pObj: any) => {
                requestParams[pObj.name] = pObj.example;
              });
            }
            this.loaderService.display(true, `Executing ${subObj.name}`);
            this.executeAction(subObj, requestParams).then((subOut: any) => {
              if (subOut.status === 'failed') {
                this.toast.sToast('error', subOut.data);
              } else {
                this.processParams(subOut.data).then((edata: any) => {
                  this.loaderService.display(false);
                  obj.subOut = edata;
                  tFF.options = [];
                  edata.forEach((val: any) => {
                    tFF.options.push({ key: val[subObj.DisplayFields[0]], value: val[obj.source.mappedValue] + '' });
                  });
                });
              }
            });
          });
        }
      } else if (obj.source && obj.depends && this.currentIntegration.actionData[obj.depends[0]]) {
        this.getSubActionParams(obj.source.name).then(async (subObj: any) => {
          const requestParams: any = {};
          obj.subParams = result;
          const sources: any = this.currentIntegration.actionParams.filter((x: any) => x.source && x.source.mappedValue);
          if (subObj.parameters) {
            subObj.parameters.forEach((pObj: any) => {
              requestParams[pObj.name] = pObj.example;
              const mValue = sources.filter((x: any) => x.source.mappedValue === pObj.name);
              if (mValue.length) {
                requestParams[pObj.name] = this.currentIntegration.actionData[mValue[0].name];
              }
            });
          }
          // requestParams[obj.source.parameter] = this.currentIntegration.actionData[obj.depends[0]];
          this.loaderService.display(true, `Executing ${subObj.name}`);
          this.executeAction(subObj, requestParams).then((subOut: any) => {
            if (subOut.status === 'failed') {
              this.toast.sToast('error', subOut.data);
            } else {
              this.processParams(subOut.data).then((edata: any) => {
                this.loaderService.display(false);
                obj.subOut = edata;
                tFF.options = [];
                edata.forEach((val: any) => {
                  tFF.options.push({ key: val[subObj.DisplayFields[0]], value: val[obj.source.mappedValue] + '' });
                });
              });
            }
          });
        });
      }
      tmpAP.push(tFF);
      if (this.inputActionData[obj.name] && !this.currentIntegration.actionData[obj.name]) {
        this.currentIntegration.actionData[obj.name] = this.inputActionData[obj.name];
      } else if (!this.inputActionData[obj.name] && this.currentIntegration.actionData[obj.name]) {
        this.currentIntegration.actionData[obj.name] = this.currentIntegration.actionData[obj.name];
      } else {
        this.currentIntegration.actionData[obj.name] = '';
      }
    });
    this.currentIntegration.formElements = tmpAP.slice();
    this.currentIntegration.actionParams = result.parameters;
  }
  actionChange($event: any): void {
    if ($event && $event !== '') {
      setTimeout(() => {
        this.processFields(this.currentIntegration.actionResult);
      }, 2000);
    }
  }
  getSubActionParams(actionName: string): Promise<any> {
    return this.baseService.doRequest(
      // tslint:disable-next-line:max-line-length
      `/api/integrations/action_params?integrationName=${this.currentIntegration.name}&actionName=${actionName}`, 'get').toPromise();
  }
  executeAction(param: any, requestparams: any): Promise<any> {
    const params: any = {
      integrationName: this.currentIntegration.name,
      integrationId: this.currentIntegration.credentialId,
      params: { action: { name: param.name, destination: param.destination, verb: param.verb }, params: { requestparams } }
    };
    return this.baseService.doRequest(`/api/integrations/executeAction`, 'post', params).toPromise();
  }
  async processParams(data: any): Promise<any> {
    const value: any = [];
    const drow = await data.row_values;
    if (!drow) { return value; }
    drow.forEach((obj: any) => {
      const obj2: any = {};
      data.column_definitions.forEach((obj1: any, index: number) => {
        const key = this.Objectkeys(obj1)[0];
        obj2[key] = obj[index];
      });
      value.push(obj2);
    });
    return value;
  }

  getAlertNotification(): void {
    this.loaderService.display(true, 'Getting notification rules');
    this.notificationRulesService.notificationRulesgetAlertingRulesApiNotificationrulesIdGetAlertingRulesPost(
      { id: 'dummy', body: {} }).subscribe((result: any) => {
        this.loaderService.display(false);

        result.forEach((obj: any) => {
          obj.alerts.forEach((alert: any) => {
            alert.eventGroups = [];
            const groups: any = [...new Set(alert.events.map((x: any) => x.group))];
            groups.forEach((grp: any) => {
              if (grp) {
                alert.eventGroups.push({name: grp, events: []})
              }
            })
            alert.events.forEach((event: any) => {
              if (event.group) {
                alert.eventGroups.forEach((grp: any) => {
                  if (grp.name === event.group) {
                    grp.events.push(event);
                  }
                });
              }
            });
            alert.events = alert.events.filter((eve: any) => !eve.group);
          });
      });
      this.alertList = result;
      this.notificationRule = Object.assign([], result);
      });
  }

  editTemplate(item: any): void {
    this.currentTemplate = item;
    let selectedEvents: any = [];
    this.currentTemplate.companyRef = (item.companyRef && item.companyRef !== undefined) ? this.currentTemplate.companyRef.id : '*';
    const filter1 = this.currentTemplate.alertRules.map((x: any) => x.rules).map((x: any) => x.map((y: any) => y.eventId));
    if (filter1.length) {
      const filter2 = [].concat.apply([], filter1.filter((x: any) => x));
      selectedEvents = [].concat.apply([], filter2.filter((x: any) => x));
    } else {
      selectedEvents = [].concat.apply([], filter1.filter((x: any) => x));
    }
    this.notificationRule.forEach((sec: any) => {
      sec.alerts.forEach((rule: any) => {
        rule.events.forEach((eve: any) => {
          eve.selected = selectedEvents.includes(eve.id);
        });
        rule.eventGroups.forEach((r: any) => {
          const ids = r.events.map((x: any) => x.id);
          ids.forEach((id: any) => {
            if (selectedEvents.includes(id)) {
              r.value = id;
            }
          });
        });
      });
    });
    this.currentTemplate.integrationRule.forEach((integ: any) => {
      if (integ.integrationName === (this.currentIntegration.integrationName || this.currentIntegration.name)) {
        this.currentIntegration.actionData = integ; delete this.currentIntegration.actionData.integrationName; delete this.currentIntegration.actionData.integrationId;
      }
    });
    this.cView = 'addEditTemplate';
    this.getActionParams();
  }

  closeTemplate(): void {
    this.cView = 'allTemplates';
    this.currentTemplate = undefined;
  }

  addDefaultTemplate(): void {
    this.notificationRule = Object.assign([], this.alertList);
    this.notificationRule.forEach((obj: any) => {
      obj.alerts.forEach((al: any) => {
        al.events.forEach((ev: any) => {
          ev.selected = false;
        });
      });
    });
    (this.actionData) ? this.currentIntegration.actionData = Object.assign({}, this.actionData) : null;
    this.currentTemplate = {};
    this.cView = 'addEditTemplate';
    this.getActionParams();
  }

  saveDefaultTemplate(): void {
    this.loaderService.display(true);
    let notificationRules: any = Object.assign({}, this.currentTemplate);
    const integrationObj = Object.assign({}, this.currentIntegration.actionData);
    integrationObj.integrationName = (this.currentIntegration.integrationName) ? this.currentIntegration.integrationName : this.currentIntegration.name;
    integrationObj.integrationId = (this.currentIntegration.credentialId) ? this.currentIntegration.credentialId : '';
    notificationRules.integrationRule = [];
    notificationRules.alertRules = [];
    delete notificationRules.companyRef;
    if (this.currentTemplate?.companyRef !== '*') {
      notificationRules.companyRef = '';
      this.allComp.map((com: any) => {
        if (this.currentTemplate.companyRef === com._id) {
          notificationRules.companyRef = { id: com._id, name: com.name };
        }
      })
    }
    notificationRules.integrationRule.push(integrationObj);
    this.notificationRule.forEach((sec: any) => {
      let alertObj: any = {}; alertObj = { sectionId: sec.sectionId, rules: [] };
      sec.alerts.forEach((rule: any) => {
        let ruleObj: any = {}; ruleObj = { ruleId: rule.ruleId, eventId: [] };
        rule.events.forEach((eve: any) => {
          if (eve.selected) { ruleObj.eventId.push(eve.id); }
        });
        rule.eventGroups.forEach((eve: any) => {
          if (eve.value) { ruleObj.eventId.push(eve.value); }
        })
        alertObj.rules.push(ruleObj);
      });
      notificationRules.alertRules.push(alertObj);
    });
    const url = (notificationRules._id) ? 'updateintegrationrule' : 'createintegrationrule';
    const msg = (notificationRules._id) ? 'Updated' : 'Saved';
    this.baseService.doRequest(`/api/ticketingrulesconfig/${url}`, 'post', notificationRules).subscribe((result: any) => {
      this.loaderService.display(false);
      if (result && result !== null) {
        this.currentIntegration.actionData = Object.assign({}, this.actionData);
        this.toast.sToast('success', `${msg} successfully`);
        this.cView = 'allTemplates';
        setTimeout(() => { this.getDefaultTemplates(); }, 3000);
      } else {
        this.toast.sToast('error', `Not ${msg}.`);
      }
    });
  }
  deleteNotificationRule(notificationRules: any): void {
    const titleName = 'Confirmation';
    const message = 'Are you sure you want to delete this notification rule ?';
    const cancelText = 'No';
    const acceptText = 'Yes';
    this.confirmDialog.confirmDialog(titleName, message, cancelText, acceptText);
    this.confirmDialog.dialogResult.subscribe(res => {
      if (res) {
        const data = {
          id: notificationRules._id,
          integrationName: (this.currentIntegration.integrationName) ? this.currentIntegration.integrationName : this.currentIntegration.name
        };
        this.baseService.doRequest(`/api/ticketingrulesconfig/deleteintegrationrule`, 'post', data).subscribe((result: any) => {
          if (result) {
            this.toast.sToast('success', 'Removed successfully');
            setTimeout(() => { this.getDefaultTemplates(); }, 3000);
          } else {
            this.toast.sToast('error', result);
          }
        });
      }
    });
  }
}
